import { Column, CreateDateColumn, Entity, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';

import { Design } from './design';
import { Team } from './team';

@Entity('panels')
export class Panel {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
		// length: 255
	})
	public name: string | undefined;

	@Column('decimal', {
		name: 'wattage',
		nullable: false,
		default: 300,
		precision: 7,
		scale: 3
		// length: 255
	})
	public wattage: number | undefined;

	@Column('boolean', {
		name: 'shown',
		nullable: false,
		default: true
	})
	public shown: boolean | undefined;

	@OneToMany(type => Design, (design: Design) => design.panel, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	designs: Design[] | undefined;

	@OneToMany(type => Team, (team: Team) => team.defaultPanel, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	teams: Team[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<Panel> = {}) {
		this.id = args.id;
		this.wattage = args.wattage;
		this.name = args.name;
		this.shown = args.shown;
		this.designs = args.designs ? args.designs.map(d => new Design(d)) : undefined;
		this.teams = args.teams ? args.teams.map(t => new Team(t)) : undefined;
	}
}
