import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, PrimaryColumn, OneToMany, JoinColumn, Column } from 'typeorm';
import { FileMeta } from './file';

@Entity('fileTypes')
export class FileType {
	@PrimaryColumn('varchar', {
		name: 'name',
		length: 55
	})
	public name: string | undefined;

	@OneToMany(type => FileMeta, (file: FileMeta) => file.fileType, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	files: FileMeta[] | undefined;

	@Column('tinyint', {
		name: 'public',
		nullable: false,
		default: 0
	})
	public public: boolean | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<FileType> | string = {}) {
		if (typeof args === 'string') return new FileType({name: args});
		this.name = args.name;
		this.public = args.public != null ? !!args.public : undefined;
		this.files = args.files ? args.files.map(f => new FileMeta(f)) : undefined;
	}
}
