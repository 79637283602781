import {MigrationInterface, QueryRunner} from "typeorm";

export class addCompletedDateToQuickSaleStatus1550575074689 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `quickSaleStatusChanges` ADD `completedDate` datetime NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `quickSaleStatusChanges` DROP COLUMN `completedDate`");
    }

}
