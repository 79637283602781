import {Entity, PrimaryColumn, Column, OneToMany} from 'typeorm';
import {Lead} from './lead';
import {RoleNotification} from './role-notification';
import { LeadStatuses } from './lead-statuses.enum';
import { LeadStatusChange } from './lead-status-changes';

@Entity('leadStatuses')
export class LeadStatus {

	@PrimaryColumn('varchar', {
		length: 255,
		name: 'name',
		unique: true
	})
	name: string | undefined;

	@Column('int', {
		name: 'order',
		nullable: false,
		unique: true
	})
	public order: number | undefined;

	@Column('tinyint', {
		nullable: false,
		default: 1
	})
	activeLead: boolean | undefined;

	@OneToMany(
		type => LeadStatusChange,
		(leadStatusChange: LeadStatusChange) => leadStatusChange.lead, { onDelete: 'SET NULL', onUpdate: 'CASCADE' }
	)
	leadChanges: LeadStatusChange[] | undefined;

	@OneToMany(type => Lead, (lead: Lead) => lead.status, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	leads: Lead[] | undefined;

	@OneToMany(
		type => RoleNotification,
		(roleNotification: RoleNotification) => roleNotification.status, { onDelete: 'SET NULL', onUpdate: 'CASCADE' }
	)
	roleNotifications: RoleNotification[] | undefined;

	constructor(args: {[T in keyof LeadStatus]?: LeadStatus[T]} = {}) {
		this.name = args.name || undefined;
		this.order = args.order;
		this.leads = args.leads ? args.leads.map(l => new Lead(l)) : undefined;
		this.leadChanges = args.leadChanges ? args.leadChanges.map(lc => new LeadStatusChange(lc)) : undefined;
		this.roleNotifications = args.roleNotifications ? args.roleNotifications.map(rn => new RoleNotification(rn)) : undefined;
	}

	public static async updateEnum(lsPromise: Promise<LeadStatus[]>) {
		const statuses = await lsPromise;
		for (const key of Object.keys(LeadStatuses)) {
			/// @ts-ignore
			delete LeadStatuses[key];
		}
		for (const status of statuses) {
			/// @ts-ignore
			LeadStatuses[LeadStatuses![status.name] = status.order] = status.name;
		}
		/// @ts-ignore
		[LeadStatuses.indexes, LeadStatuses.keys] = statuses.reduce((t, s) => {t[0].push(s.order); t[1].push(s.name); return t; }, [[], []]);
	}

}
