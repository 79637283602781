import { AuthService, UserService } from 'app/_services';
import { User } from 'pecms-shared';
import { tap } from 'rxjs/operators';

import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
	selector: 'img[userPhoto]',
})
export class UserPhotoDirective implements AfterViewInit {

	@HostListener('attr.userPhoto')
	@Input('userPhoto')
	get userOrUserId(): number | User { return this._user; }
	set userOrUserId(value: number | User) {
		if (value instanceof User) {
			this._user = value;
			this._userId = value.id;
		} else if (value !== this._userId) {
			this._src = this.userSvc.getPhotoUrl();
			this._user = undefined;
			this._userId = coerceNumberProperty(value);
		}
		this._updateSrc();
	}

	private _size = '100%';
	private _user: User;
	private _userId: number;

	@Input()
	get size() { return this._size; }
	set size(val: string) {
		this._size = val;
		this._imageWidth = val;
		this._imageHeight = val;
	}

	@HostBinding('style.width') private _imageWidth = this._size;
	@HostBinding('style.height') private _imageHeight = this._size;

	@HostBinding('class.round')
	@HostBinding('attr.round')
	@Input()
	get round(): any { return !!this._round; }
	set round(val: any) { this._round = coerceBooleanProperty(val); }

	private get _bgColor() { return this.el.nativeElement.style.backgroundColor; }

	private _round: boolean;

	@HostBinding('src')
	private _src: string = this.userSvc.getPhotoUrl(this._user);

	constructor(
		protected userSvc: UserService,
		protected authSvc: AuthService,
		private el: ElementRef
	) { }

	ngAfterViewInit() {}

	private get _updateUser() {
		return (this._userId ? this.userSvc.getPublicUser(this._userId) : this.authSvc.currentUser).pipe(tap(u => {
			this._user = u;
		}));
	}

	private _updateSrc() {
		if (this._user === undefined) {
			this._updateUser.subscribe(u => this._updateSrc(), console.error);
			return;
		}
		this._src = this.userSvc.getPhotoUrl(this._user);
	}
}
