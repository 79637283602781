import { Component, Input, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'pecms-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
	@Input() progress = true;
	text: string;

	constructor(@Inject(MAT_DIALOG_DATA) @Optional() data: {text: string, progress: boolean}) {
		if (data) {
			if (data.progress != null && this.progress == null) this.progress = data.progress;
			if (data.text != null && this.text == null) this.text = data.text;
		}
	}
}
