import { Component, Input, Inject, Optional, AfterViewInit, OnDestroy, ElementRef, ViewChildren, QueryList, HostBinding, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Cropper from 'cropperjs';
import { DomSanitizer } from '@angular/platform-browser';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
	selector: 'pecms-image-cropper',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnDestroy, AfterViewInit {

	private _destroy = new Subject();

	@ViewChildren('image')
	private imageEl: QueryList<ElementRef<HTMLImageElement>>;

	private _cropper: Cropper;

	@Input('src')
	private set _src(val: string | File) { this.handleSrcChange(val); }

	@Input()
	public aspectRatio: number;

	@HostBinding('class.round-cropper')
	public round: boolean;

	@Input('round')
	private set _round(val) { this.round = coerceBooleanProperty(val); }

	@Input()
	public resize: {width: number, height: number};

	@Input()
	public fileName: string;

	public srcImage = {
		asFile: new BehaviorSubject<File>(undefined),
		asDataUrl: new BehaviorSubject<string>(undefined)
	};
	public croppedImage = {
		asFile: new BehaviorSubject<File>(undefined),
		asDataUrl: new BehaviorSubject<string>(undefined)
	};


	constructor(
		@Inject(MAT_DIALOG_DATA) @Optional() data: CropperData,
		@Optional() private _dialogRef: MatDialogRef<ImageCropperComponent>,
		private _sanitizer: DomSanitizer
	) {
		if (data) {
			this.round = !!data.round;
			if (data.src) this._src = data.src;
			if (data.resize) this.resize = data.resize;
			if (data.aspectRatio) this.aspectRatio = data.aspectRatio;
		}
	}

	handleSrcChange(val: string | File) {
		this._normalizeSrc(val).subscribe(([file, dataUrl]) => {
			this.srcImage.asDataUrl.next(<string>this._sanitizer.bypassSecurityTrustResourceUrl(dataUrl));
			this.srcImage.asFile.next(file);
		});
	}

	handleCrop(val: string | File) {
		this._normalizeSrc(val).subscribe(([file, dataUrl]) => {
			this.croppedImage.asDataUrl.next(<string>this._sanitizer.bypassSecurityTrustResourceUrl(dataUrl));
			this.croppedImage.asFile.next(file);
		});
	}

	private _normalizeSrc(val: string | File) {
		const retVal = new Subject<[File, string]>();
		if (typeof val === 'string') {
			const arr = val.split(',');
			const mime = arr[0].match(/:(.*?);/)[1];
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);
			while ( n--) u8arr[n] = bstr.charCodeAt(n);
			this._preResize(val).subscribe(resized => {
				retVal.next([new File([u8arr], this.fileName || 'file', {type: mime}), resized]);
				retVal.complete();
			});
		} else {
			if (!this.fileName) this.fileName = val.name;
			const reader: FileReader = new FileReader();
			reader.onloadend = (loadEvent: any) => {
				this._preResize(loadEvent.target.result).subscribe(resized => {
					retVal.next([val, resized]);
					retVal.complete();
				});
			};
			reader.readAsDataURL(val);
		}
		return retVal;
	}

	public ngOnDestroy() {
		this._destroy.next();
		this._destroy.complete();
		this.srcImage.asDataUrl.complete();
		this.croppedImage.asDataUrl.complete();
		this.srcImage.asFile.complete();
		this.croppedImage.asFile.complete();
	}

	public finish() {
		combineLatest([this.croppedImage.asDataUrl, this.croppedImage.asFile]).pipe(takeUntil(this._destroy)).subscribe(v => {
			v && this._dialogRef.close(v);
		});
	}

	public ngAfterViewInit() {
		const qlSub = this.imageEl.changes.pipe(takeUntil(this._destroy)).subscribe((iql: QueryList<ElementRef>) => {
			if (iql.length) {
				qlSub.unsubscribe();
				const img = this.imageEl.first.nativeElement;
				if (img.complete) {
					this._setCropper();
				} else {
					img.addEventListener('load', this._setCropper.bind(this));
				}
			}
		});
	}

	private _setCropper() {
		this._cropper = new Cropper(this.imageEl.first.nativeElement, {
			scalable: false,
			autoCropArea: 1,
			aspectRatio: this.aspectRatio,
			crop: () => {
				const canvas = this._cropper.getCroppedCanvas({height: this.resize && this.resize.height, width: this.resize && this.resize.width});
				if (canvas) this.handleCrop(canvas.toDataURL());
			}
		});
	}

	private _preResize(dataUrl: string) {
		const retVal = new Subject<string>();

		const img: HTMLImageElement = new Image();
		const max = 1024;
		const biggerWidth = img.naturalWidth > img.naturalHeight;

		img.addEventListener('load', () => {
			let [dim1, dim2] = biggerWidth ? [img.naturalWidth, img.naturalHeight] : [img.naturalHeight, img.naturalWidth];
			if (dim1 > max) {
				dim2 *= max / dim1;
				dim1 = max;

				const canvas = document.createElement('canvas');
				canvas.getContext('2d').drawImage(img, 0, 0);
				canvas.width = biggerWidth ? dim1 : dim2;
				canvas.height = biggerWidth ? dim2 : dim1;
				canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);

				retVal.next(canvas.toDataURL('image/png'));
				canvas.remove();
			} else {
				retVal.next(dataUrl);
			}
			img.remove();
			retVal.complete();
		});
		img.src = dataUrl;
		return retVal;
	}

}

export interface CropperData {
	src: string | File;
	aspectRatio?: number;
	resize?: {width: number, height: number};
	round?: boolean;
}

