import {MigrationInterface, QueryRunner} from "typeorm";

export class reorderLeadAndDesign1553849909498 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
            await queryRunner.query("CREATE TABLE `adders` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(50) NOT NULL, `type` varchar(255) NOT NULL, `amount` decimal(8,3) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`id`)) ENGINE=InnoDB");
            await queryRunner.query("CREATE TABLE `leadAdders` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(50) NOT NULL, `type` varchar(255) NOT NULL, `amount` decimal(8,3) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `leadId` int NULL, UNIQUE INDEX `REL_ddc0159c3c10361e2e0d361ebc` (`leadId`), PRIMARY KEY (`id`)) ENGINE=InnoDB");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `systemCost`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `rebate`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `panelType`");
            await queryRunner.query("ALTER TABLE `designs` ADD `maxPanelCount` int NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `baseCostPerWatt` decimal(5,3) NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `rebatePercentage` decimal(5,4) NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `panelId` int NULL");
            await queryRunner.query("ALTER TABLE `teams` ADD `discount` decimal(7,2) NULL DEFAULT 3000");
            await queryRunner.query("ALTER TABLE `teams` ADD `defaultPanelId` int NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD CONSTRAINT `FK_d47732774f7097a18d865860062` FOREIGN KEY (`panelId`) REFERENCES `panels`(`id`) ON DELETE RESTRICT");
            await queryRunner.query("ALTER TABLE `leadAdders` ADD CONSTRAINT `FK_ddc0159c3c10361e2e0d361ebca` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE RESTRICT");
            await queryRunner.query("ALTER TABLE `teams` ADD CONSTRAINT `FK_c5ddf02b65d76f9b616118ee0a5` FOREIGN KEY (`defaultPanelId`) REFERENCES `panels`(`id`) ON DELETE RESTRICT");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
            await queryRunner.query("ALTER TABLE `teams` DROP FOREIGN KEY `FK_c5ddf02b65d76f9b616118ee0a5`");
            await queryRunner.query("ALTER TABLE `leadAdders` DROP FOREIGN KEY `FK_ddc0159c3c10361e2e0d361ebca`");
            await queryRunner.query("ALTER TABLE `designs` DROP FOREIGN KEY `FK_d47732774f7097a18d865860062`");
            await queryRunner.query("ALTER TABLE `teams` DROP COLUMN `defaultPanelId`");
            await queryRunner.query("ALTER TABLE `teams` DROP COLUMN `discount`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `panelId`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `rebatePercentage`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `baseCostPerWatt`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `maxPanelCount`");
            await queryRunner.query("ALTER TABLE `designs` ADD `panelType` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `rebate` decimal(10,2) NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `systemCost` decimal(10,2) NULL");
            await queryRunner.query("DROP INDEX `REL_ddc0159c3c10361e2e0d361ebc` ON `leadAdders`");
            await queryRunner.query("DROP TABLE `leadAdders`");
            await queryRunner.query("DROP TABLE `adders`");
    }

}
