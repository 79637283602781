import {MigrationInterface, QueryRunner} from "typeorm";

export class addPageNumberToField1552989959496 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `documentFields` ADD `pageNumber` int NOT NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `documentFields` DROP COLUMN `pageNumber`");
    }

}
