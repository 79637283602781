import { Directive, Output, Input, ElementRef, OnDestroy, EventEmitter, Injectable } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({ selector: '[resize]' })
@Injectable()
export class ResizeDirective implements OnDestroy {

	constructor( _el: ElementRef) {
		this._sensor = new ResizeSensor(this.parent ? _el.nativeElement.parentElement : _el.nativeElement, ({width}) => {
			if (!this.breakpoints) {
				this.resize.emit(width);
			} else if (
				!this.currentBreakpoint ||
				width <= this.breakpoints[this.currentBreakpoint - 1] ||
				width > this.breakpoints[this.currentBreakpoint + 1]
			) {
				this.currentBreakpoint = 0;
				while (width < this.breakpoints[this.currentBreakpoint] && this.breakpoints[++this.currentBreakpoint] != null) continue;
				this.resize.emit(--this.currentBreakpoint);
			}
		});
	}

	@Output()
	resize = new EventEmitter<number>();

	@Input()
	breakpoints: number[];

	public parent: boolean;
	@Input('senseParent')
	private get _parent() { return this.parent; }
	private set _parent(val) { this.parent = coerceBooleanProperty(val); }

	private _sensor: ResizeSensor;

	private currentBreakpoint: number;

	ngOnDestroy(): void {
		this._sensor && this._sensor.detach();
	}

}
