import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryColumn, UpdateDateColumn } from 'typeorm';

import { User } from './user';

@Entity('apiKeys')
export class ApiKey {
	@PrimaryColumn('varchar', {length: 64, nullable: false, name: 'key'})
	public key: string | undefined;

	@Column('varchar', {
		name: 'prefix',
		nullable: false,
		length: 10
	})
	public prefix: any | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
		length: 55
	})
	public name: any | undefined;

	@ManyToOne(type => User, (user: User) => user.apiKeys, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'userId'})
	user: User | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<ApiKey> = {}) {
		this.key = args.key;
		this.user = args.user ? new User(args.user) : undefined;
		this.prefix = args.prefix;
	}
}
