import {Index, Entity, PrimaryGeneratedColumn, Column, ManyToOne, JoinColumn, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import { Design } from './design';
import { User } from './user';

@Entity('signatureAudits')
@Index('signatureAudits_designId_foreign_idx', ['design'])
@Index('signatureAudits_userId_foreign_idx', ['user'])
export class SignatureAudit {
	@PrimaryGeneratedColumn()
	id: number | undefined;

	@ManyToOne(type => User, (user: User) => user.signatureAudits, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'loginId'})
	user: User | undefined;

	@ManyToOne(type => Design, (design: Design) => design.signatureAudits, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'designId'})
	design: Design | undefined;

	@Column('varchar', {
		nullable: false,
		length: 255,
		name: 'referrer'
	})
	referrer: string | undefined;

	@Column('varchar', {
		nullable: false,
		length: 255,
		name: 'actionType'
	})
	actionType: string | undefined;

	@Column('varchar', {
		nullable: false,
		length: 15,
		name: 'ipAddress'
	})
	ipAddress: string | undefined;

		@CreateDateColumn()
	createdAt: Date | undefined;

		@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<SignatureAudit> = {}) {
				this.id = args.id || undefined;
				this.user = new User(args.user || {});
		this.design = new Design(args.design) || undefined;
		this.referrer = args.referrer || undefined;
		this.actionType = args.actionType || undefined;
		this.ipAddress = args.ipAddress || undefined;
				this.createdAt = args.createdAt || undefined;
				this.updatedAt = args.updatedAt || undefined;
	}

}
