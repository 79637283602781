import {MigrationInterface, QueryRunner} from "typeorm";

export class utilityRebate1554234400877 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `designs` ADD `utilityRebate` decimal(10,2) NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `utilityRebate`");
    }

}
