import { Injectable } from '@angular/core';
import { Design, Lead } from 'pecms-shared';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ExpandType } from 'app/_services/abstract-service.service';
import { QueryStringBuilder } from 'app/_helpers/query-string-builder';

@Injectable({
	providedIn: 'root'
})
export class DesignService {

	constructor(
		private http: HttpClient,
		private alertSvc: AlertService
	) { }

	public getDesign = (leadId: number) => this.http.get<Lead>(`/leads/${leadId}/design`).pipe(
		map(d => {
			return new Design(d.design);
		})
	)

	public saveDesign = (desingObj: any, leadId: number, expand?: ExpandType<Design>) => {
		return this.http.post<Design>(`/leads/${leadId}/design${new QueryStringBuilder({expand})}`, desingObj).pipe(
			map(d => {
				this.alertSvc.success({title: 'Design successfully added'});
				return new Design(d);
			})
		);
	}

	public updateDesign = (desingObj: Partial<Design>, leadId: number, expand?: ExpandType<Design>) => {
		return this.http.patch(`/leads/${leadId}/design${new QueryStringBuilder({expand})}`, desingObj).pipe(
			map(d => {
				this.alertSvc.success({title: 'Design successfully updated'});
				return new Design(d);
			})
		);
	}

	public approve(leadId: number) {
		return this.http.post<Design>(`/leads/${leadId}/design/approve`, {}).pipe(map(d => new Design(d)));
	}

	public sign(leadId: number, type: string, userId: number, signerType: 'user' | 'signer' = 'user', expand?: ExpandType<Design>): Observable<Design> {
		return this.http.post<Design>(`/leads/${leadId}/design/sign${new QueryStringBuilder({expand})}`, {type, userId, signerType}).pipe(map(d => new Design(d)));
	}
}


/*

	public getAdder(adderName: string, leadId: number) {
		return this.http.get(`/leads/${leadId}/adders/${adderName}`).pipe(map(a => new LeadAdder(a)));
	}

	public getAllAdders(leadId: number) {
		return this.http.get<any[]>(`/leads/${leadId}/adders`).pipe(map(as => as.map(a => new LeadAdder(a))));
	}

	public addAdder(adder: LeadAdder, leadId: number) {
		return this.http.post(`/leads/${leadId}/adders`, adder).pipe(map(a => new LeadAdder(a)));
	}

	public addAdders(adders: LeadAdder[], leadId: number) {
		return this.http.post<any>(`/leads/${leadId}/adders`, adders).pipe(map(as => as.map(a => new LeadAdder(a))));
	}

	public updateAdder(patch: Partial<LeadAdder>, adderName: string, leadId: number) {
		return this.http.patch(`/leads/${leadId}/adders/${adderName}`, patch).pipe(map(a => new LeadAdder(a)));
	}

	public updateAdders(patch: Partial<LeadAdder>[], leadId: number) {
		return this.http.patch<LeadAdder[]>(`/leads/${leadId}/adders`, patch).pipe(map(as => as.map(a => new LeadAdder(a))));
	}

	public deleteAdder(adderName: string, leadId: number) {
		return this.http.delete(`/leads/${leadId}/adders/${adderName}`);
	}
*/
