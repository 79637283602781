import {MigrationInterface, QueryRunner} from "typeorm";

export class cleaningOldColumns1553694373824 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
            await queryRunner.query("ALTER TABLE `designs` DROP FOREIGN KEY `FK_92ea095304f026ac8e2da7f6df6`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `panelQuality`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `utilityAccount`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `loaner`");
            await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `utilityName`");
            await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `hoa`");
            await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `hoaName`");
            await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `hoaPhone`");
            await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `hoaEmail`");
            await queryRunner.query("ALTER TABLE `leads` ADD `utilityAccount` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `leads` ADD `hoaId` int NULL");
            await queryRunner.query("ALTER TABLE `leads` ADD `utilityName` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_fc7b68d3d8676e4f8541d44b2c8` FOREIGN KEY (`hoaId`) REFERENCES `hoas`(`id`) ON DELETE RESTRICT");
            await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_f9414746eb96486cd8d98e77806` FOREIGN KEY (`utilityName`) REFERENCES `utilities`(`name`) ON DELETE RESTRICT");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
            await queryRunner.query("ALTER TABLE `leads` DROP FOREIGN KEY `FK_f9414746eb96486cd8d98e77806`");
            await queryRunner.query("ALTER TABLE `leads` DROP FOREIGN KEY `FK_fc7b68d3d8676e4f8541d44b2c8`");
            await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `utilityName`");
            await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `hoaId`");
            await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `utilityAccount`");
            await queryRunner.query("ALTER TABLE `leads` ADD `hoaEmail` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `leads` ADD `hoaPhone` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `leads` ADD `hoaName` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `leads` ADD `hoa` tinyint NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `utilityName` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `loaner` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `utilityAccount` varchar(255) NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD `panelQuality` int NULL");
            await queryRunner.query("ALTER TABLE `designs` ADD CONSTRAINT `FK_92ea095304f026ac8e2da7f6df6` FOREIGN KEY (`utilityName`) REFERENCES `utilities`(`name`) ON DELETE RESTRICT ON UPDATE RESTRICT");
    }

}
