import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultUrlSerializer } from '@angular/router';
import { QueryStringBuilder } from 'app/_helpers/query-string-builder';
import { AbstractServiceService, GetConfig } from 'app/_services/abstract-service.service';
import { isUploadStatus, UploadStatus } from 'app/_services/file-upload.service';
import { FileMeta, FileType } from 'pecms-shared';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class FileService extends AbstractServiceService<FileMeta> {
	protected entityClass: new (...args: any[]) => FileMeta = FileMeta;
	protected entityClassName = 'File';

	uploadFile(file: File, metadata: FileMeta): Observable<UploadStatus | FileMeta>;
	uploadFile(file: File, type: FileType): Observable<UploadStatus | FileMeta>;
	uploadFile(dataURI: string, metadata: FileMeta): Observable<UploadStatus | FileMeta>;
	uploadFile(dataURI: string, type: FileType, filename: string): Observable<UploadStatus | FileMeta>;
	uploadFile(fileOrDataURI: File | string, metadataOrType: FileMeta | FileType, filename?: string): Observable<UploadStatus | FileMeta> {
		let fileMetadata: FileMeta;
		let file: File;

		file = (typeof fileOrDataURI === 'string') ? this._dataURItoFile(fileOrDataURI, filename) : fileOrDataURI;
		if (!(metadataOrType instanceof FileMeta)) {
			fileMetadata = new FileMeta({
				name: file.name,
				fileType: metadataOrType,
				mimeType: file.type,
				size: file.size || 0
			});
		} else {
			fileMetadata = metadataOrType;
		}
		const saveMetaIfNeeded: Observable<FileMeta> = fileMetadata.id == null ? this.create(fileMetadata, {fileType: true}) : of(fileMetadata);

		return saveMetaIfNeeded.pipe(
			concatMap(fm => concat(
				this._uploadToS3(file, fm).pipe(filter(e => !(e instanceof HttpResponse))),
				this.update({complete: true}, <number>fm.id).pipe(map(udf => new FileMeta(udf)))
			))
		);
	}

	getFileData(file: FileMeta): Observable<Blob> {
		return this.http.get(`/files/${file.id}/download`, { observe: <const>'response', responseType: <const>'blob' }).pipe(map(r => r.body!));
	}

	handleFileInput(files: FileList | File[], type: FileType): Observable<UploadStatus | FileMeta | FileMeta[]> {
		let total = 0;
		const fileMetaList = Array.from(files, f => {
			total += f.size;
			return FileMeta.fromFile(f, type);
		});
		if (fileMetaList.length === 1) {
			return this.uploadFile(files[0], fileMetaList[0]);
		} else {
			return this.createMany(fileMetaList, {fileType: true}).pipe(
				filter((v, i) => {
					return !v.some(fm => fm === undefined);
				}),
				concatMap(fms => {
					return combineLatest(fms.map((fm, i) => this.uploadFile(files[i], fm))).pipe(map(uss => {
						if (uss.some(us => isUploadStatus(us))) {
							const loaded = uss.reduce((pv, cv, i) => {
								return pv + ((cv instanceof FileMeta) ? cv.size : cv.loaded);
							}, 0);
							return <UploadStatus>{
								loaded,
								total,
								type: <1>1
							};
						} else {
							return <FileMeta[] > uss;
						}
					}));
				}),
			);
		}
	}

	public addFileToLead(files: FileMeta[], leadId: number): Observable<FileMeta[]>;
	public addFileToLead(files: FileMeta, leadId: number): Observable<FileMeta>;
	public addFileToLead(files: FileMeta | FileMeta[], leadId: number): Observable<FileMeta | FileMeta[]> {
		return this.http.patch<FileMeta>(`/leads/${leadId}/files`, files).pipe(map(fm => new FileMeta(fm)));
	}

	public getLeadFiles(leadId: number, config?: GetConfig<FileMeta>) {
		const configQS = new QueryStringBuilder(config);
		return this.http.get<FileMeta[]>(`/leads/${leadId}/files${configQS}`).pipe(map(fms => fms.map(fm => new FileMeta(fm))));
	}

	private _dataURItoFile(dataURI, filename = 'fname') {
		const fileContent = dataURI.split(',')[1];
		const type = dataURI.slice(5).split(';')[0];
		const binary = atob(fileContent);
		const array: number[] = [];
		for (let i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i));
		}
		return new File([new Uint8Array(array)], filename, {type});
	}

	private _uploadToS3(file: File, metadata: FileMeta): Observable < UploadStatus > {
		const urlSerializer = new DefaultUrlSerializer();
		const headers: any = {'Content-Type': ''};
		const url = urlSerializer.parse(metadata.signedUrl!.split('//')[1]);
		const expires = url.queryParamMap.get('Expires');
		const cType = url.queryParamMap.get('Content-Type');
		const acl = url.queryParamMap.get('x-amz-acl');
		if (expires) headers.Expires = expires;
		if (cType) headers['Content-Type'] = cType;
		if (acl) headers['x-amz-acl'] = acl;
		// @ts-ignore
		return this.http.put<FileMeta>(metadata.signedUrl, file, {headers, reportProgress: true, observe: 'events'});
	}
}
