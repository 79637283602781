import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn, Unique, UpdateDateColumn } from 'typeorm';
import { Document } from './document';

export class FieldConfig {
	required = false;
	choices?: string[];
	render = true;
	prompt = true;
	font?: string;
	fontSize?: number;
	fontColor?: string;
	default?: string;
	template?: string;
	suffix?: string;
	prefix?: string;
	format?: string;
	sigDateX?: number;
	sigDateY?: number;
	sigNameX?: number;
	sigNameY?: number;
	sigType?: 'signature' | 'initials';
	sigId?: string;
	shapeType?: 'circle' | 'square';
	width?: number;
	height?: number;
	position?: Position | Gravity | 'cover';
	scale?: Strategy;
	posX: number | undefined;
	posY: number | undefined;

	constructor(args: string |  Partial<FieldConfig>) {
		if (typeof args === 'string') args = <Partial<FieldConfig>>JSON.parse(args);
		if (args.required !== undefined) this.required = args.required;
		if (args.choices !== undefined) this.choices = args.choices;
		if (args.render !== undefined) this.render = args.render;
		if (args.prompt !== undefined) this.prompt = args.prompt;
		if (args.font !== undefined) this.font = args.font;
		if (args.fontSize !== undefined) this.fontSize = args.fontSize;
		if (args.fontColor !== undefined) this.fontColor = args.fontColor;
		if (args.default !== undefined) this.default = args.default;
		if (args.template !== undefined) this.template = args.template;
		if (args.suffix !== undefined) this.suffix = args.suffix;
		if (args.prefix !== undefined) this.prefix = args.prefix;
		if (args.format !== undefined) this.format = args.format;
		if (args.sigDateX !== undefined) this.sigDateX = args.sigDateX;
		if (args.sigDateY !== undefined) this.sigDateY = args.sigDateY;
		if (args.sigNameX !== undefined) this.sigNameX = args.sigNameX;
		if (args.sigNameY !== undefined) this.sigNameY = args.sigNameY;
		if (args.sigType !== undefined) this.sigType = args.sigType;
		if (args.sigId !== undefined) this.sigId = args.sigId;
		if (args.shapeType !== undefined) this.shapeType = args.shapeType;
		if (args.width !== undefined) this.width = args.width;
		if (args.height !== undefined) this.height = args.height;
		if (args.position !== undefined) this.position = args.position;
		if (args.scale !== undefined) this.scale = args.scale;
		if (args.posX !== undefined) this.posX = args.posX;
		if (args.posY !== undefined) this.posY = args.posY;
	}
}

@Entity('documentFields')
@Unique('UQ_NAME', ['name', 'document'])
export class DocumentField {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@ManyToOne(type => Document, (document: Document) => document.fields, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'documentId'})
	document: Document | undefined;

	@Column('int', {
		name: 'pageNumber',
		nullable: false,
	})
	public pageNumber: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: true,
	})
	public name: string | undefined;

	@Column('varchar', {
		name: 'description',
		nullable: true,
		length: 1000
	})
	public description: string | undefined;

	@Column('varchar', {
		name: 'type',
		nullable: false,
	})
	public type: 'signature' | 'text' | 'textarea' | 'select' | 'shape' | undefined;

	@Column('varchar', {
		name: 'config',
		nullable: false,
		length: 2000
	})
	private set _config(val: string | undefined) { this.config = val ? new FieldConfig(val) : undefined; }
	private get _config(): string | undefined { return this.config ? JSON.stringify(this.config) : undefined; }

	public config: FieldConfig | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<DocumentField> = {}) {
		this.id = args.id;
		this.document = new Document(args.document);
		this.name = args.name;
		this.description = args.description;
		this.type = args.type;
		this.config = args.config;
	}
}

export type Position = 'top' | 'right top' | 'right' | 'right bottom' | 'bottom' | 'left bottom' | 'left' | 'left top';
export type Gravity = 'north' | 'northeast' | 'east' | 'southeast' | 'south' | 'southwest' | 'west' | 'northwest' | 'center' | 'centre';
export type Strategy = 'cover' | 'contain' | 'fill' | 'inside' | 'outside';
