import {MigrationInterface, QueryRunner} from "typeorm";

export class shownLoan1563190354498 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `loanProducts` ADD `shown` tinyint NOT NULL");
        await queryRunner.query("UPDATE `loanProducts` SET `shown` = 1");
        await queryRunner.query("ALTER TABLE `loanProviders` ADD `active` tinyint NOT NULL");
        await queryRunner.query("UPDATE `loanProviders` SET `active` = 1");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `loanProducts` DROP COLUMN `shown`");
        await queryRunner.query("ALTER TABLE `loanProviders` DROP COLUMN `active`");
    }

}
