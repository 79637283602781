import { AlertConfig, AlertType } from 'app/_models';
import { AuthService } from 'app/_services/auth.service';
import { environment as env } from 'environments/environment';
import { Lead, LeadStatus } from 'pecms-shared';
import { Observable } from 'rxjs/internal/Observable';
import { AnonymousSubject, Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import io from 'socket.io-client';

import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class WebsocketService implements OnDestroy {

	private rooms: {[key: string]: {socket: SocketIOClient.Socket, events: {[key: string]: AnonymousSubject<any>}}} = {};

	constructor() {}

	protected unsubscribe = new Subject();

	private _connectionStatus = new Subject<boolean>();

	private _authStatus = new Subject<boolean>();

	public connectionStatus = this._connectionStatus.pipe(takeUntil(this.unsubscribe), distinctUntilChanged());
	public authStatus = this._authStatus.pipe(takeUntil(this.unsubscribe), startWith(true), distinctUntilChanged());


	private _socketObservable<T>(
		channel: string,
		{onConnect: connectionCB, room, query}: {onConnect?: Callback, room?: string, query?: {[key: string]: any}} = {}
	): Observable<T> {
		if (!room) room = '/';
		if (!room.startsWith('/')) room = `/${room}`;
		if (!this.rooms[room]) this.connect(room, query);
		const {socket, events} = this.rooms[room];

		if (!events[channel]) {
			events[channel] = new AnonymousSubject(
				{
					next: (data: Object) => {
						socket.emit(channel, JSON.stringify(data));
					},
					error: (e: any) => {
						console.error(e);
						if (e === 'Unauthorized') this._authStatus.next(false);
					},
					complete: () => {}
				}, new Observable(obs => {
					socket.on(channel, (data) => {
						obs.next(data);
					});
					return () => {
						socket.disconnect();
					};
				})
			);
		}
		connectionCB && socket.on('connect', connectionCB);

		return events[channel];
	}

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.closeAll();
	}

	private connect(room: string, query?: {[key: string]: any}) {
		const url = `${window.location.protocol}//${window.location.hostname}:${env.apiPort}${room}`;
		if (!query) query = {};
		query.token = localStorage.getItem('currentUser');
		this.rooms[room] = {socket: io(url, {path: `/ws`, query: Object.entries(query).map(e => e.join('=')).join('&')}), events: {}};
		this.rooms[room].socket.on('error', (e) => Object.values(this.rooms[room].events).forEach(s => s.error(e)));
		if (room === '/') {
			this.rooms[room].socket.on('connect', () => this._connectionStatus.next(true));
			this.rooms[room].socket.on('disconnect', () => this._connectionStatus.next(false));
		}
	}

	private closeAll() {
		Object.values(this.rooms).forEach(r => r.socket.close());
	}

	public apiVersion(onConnect?: (...args: any[]) => any): Observable<any> {
		return this._socketObservable('version', {onConnect});
	}

	public messages(onConnect?: (...args: any[]) => any): Observable<any> {
		return this._socketObservable('message', {onConnect});
	}

	public alerts(onConnect?: (...args: any[]) => any): Observable<{type: AlertType, config: AlertConfig}> {
		return this._socketObservable('alert', {onConnect});
	}

	public gainedStatuses(onConnect?: (...args: any[]) => any): Observable<{leadId: number, statusId: LeadStatus, message: string}> {
		return this._socketObservable('newStatus', {onConnect});
	}

	public lostStatuses(onConnect?: (...args: any[]) => any): Observable<{leadId: number, statusId: LeadStatus}> {
		return this._socketObservable('oldStatus', {onConnect});
	}

	public leadChange(leadId: number, onConnect?: (...args: any[]) => any): Observable<Lead> {
		return this._socketObservable('update', {room: 'leads', onConnect, query: {id: leadId} });
	}

}

type Callback<T = any> = (...args: any[]) => T;
