import {MigrationInterface, QueryRunner} from 'typeorm';

export class leadLatLong1585686586212 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `leads` ADD `lat` decimal(10,7) NULL', undefined);
		await queryRunner.query('ALTER TABLE `leads` ADD `lng` decimal(10,7) NULL', undefined);
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `lng`', undefined);
		await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `lat`', undefined);
	}

}
