import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive, Attribute } from '@angular/core';

@Directive({
	selector: '[match]',
	providers: [{provide: NG_VALIDATORS, useExisting: MatchValidator, multi: true}]
})
export class MatchValidator implements Validator {
	public static validationMessage = 'Does not match';

	@Attribute('match')
	match: string;

	validate = MatchValidator.validateMatch(this.match);

	static validateMatch = (matchKey: string) => (c: AbstractControl) => {
		const targetVal = c.parent ? c.parent.get(matchKey).value : null;
		let vErr: ValidationErrors = null;

		if (targetVal !== c.value) {
			vErr = {match: MatchValidator.validationMessage};
		}
		return vErr;
	}

}
