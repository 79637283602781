import { CustomDirectives } from 'app/_directives/custom-directives.module';
import { FileUploaderComponent } from 'app/_helpers/form-fields/file-uploader/file-uploader.component';
import { ImageUploaderComponent } from 'app/_helpers/form-fields/image-uploader/image-uploader.component';
import { StateSelectorComponent } from 'app/_helpers/form-fields/state-selector/state-selector.component';
import { CeilingPipe } from 'app/_helpers/pipes/ceiling.pipe';
import { IdPipe } from 'app/_helpers/pipes/id.pipe';
import { PhonePipe } from 'app/_helpers/pipes/phone.pipe';
import { RolePipe } from 'app/_helpers/pipes/role.pipe';
import { MatchValidator, RequiredIfValidator } from 'app/_helpers/validators';
import { LoadingComponent } from 'app/authenticated/_extras/loading/loading.component';
import { AlertComponent } from 'app/shared/alert/alert.component';
import { NotFoundComponent } from 'app/shared/not-found/not-found.component';
import { UserPhotoDirective } from 'app/shared/user-photo/user-photo.directive';
import { NgxMaskModule } from 'ngx-mask';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatButtonModule,
	MatDialogModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRippleModule,
	MatSelectModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { GoogleLoginButtonComponent } from './google-login-button/google-login-button.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { StatusButtonComponent } from './status-button/status-button.component';
import { SvgDirective } from './svg.directive';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/locale/');
}

@NgModule({
	declarations: [
		AlertComponent,
		NotFoundComponent,
		RolePipe,
		IdPipe,
		PhonePipe,
		CeilingPipe,
		FileUploaderComponent,
		UserPhotoDirective,
		MatchValidator,
		RequiredIfValidator,
		ImageUploaderComponent,
		GoogleLoginButtonComponent,
		StatusButtonComponent,
		ImageCropperComponent,
		LoadingComponent,
		StateSelectorComponent,
		SvgDirective
	],
	imports: [
		CommonModule,
		FormsModule,
		MatRippleModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatFormFieldModule,
		MatButtonModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatIconModule,
		NgxMaskModule.forRoot(),
		MatInputModule,
		RouterModule,
		CustomDirectives,
		FlexModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	exports: [
		NgxMaskModule,
		CommonModule,
		FormsModule,
		MatRippleModule,
		ReactiveFormsModule,
		FileUploaderComponent,
		ImageUploaderComponent,
		UserPhotoDirective,
		MatDialogModule,
		MatFormFieldModule,
		MatButtonModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatIconModule,
		MatInputModule,
		RouterModule,
		TranslateModule,
		CustomDirectives,
		FlexModule,
		RolePipe,
		IdPipe,
		PhonePipe,
		CeilingPipe,
		GoogleLoginButtonComponent,
		StatusButtonComponent,
		LoadingComponent,
		StateSelectorComponent,
		SvgDirective
	],
	entryComponents: [
		GoogleLoginButtonComponent,
		AlertComponent,
		LoadingComponent,
		ImageCropperComponent
	],
	providers: [
		MatDialogModule,
		MatRippleModule
	]
})
export class SharedModule { }
