import {MigrationInterface, QueryRunner} from 'typeorm';

export class leadUsersTable1565985563643 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query('CREATE TABLE `leadPositions` (`name` varchar(255) NOT NULL, `allowMultiple` tinyint NOT NULL DEFAULT 0, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`name`)) ENGINE=InnoDB');
        await queryRunner.query('INSERT INTO `leadPositions` (`name`, `allowMultiple`) VALUES (\'Sales Rep\', 0), (\'Closer\', 0), (\'Customer\', 0)');
        await queryRunner.query('CREATE TABLE `leadUsers` (`id` int NOT NULL AUTO_INCREMENT, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `leadId` int NULL, `userId` int NULL, `positionName` varchar(255) NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB');
        await queryRunner.query('INSERT INTO `leadUsers` (`leadId`, `userId`, `positionName`) SELECT * FROM (SELECT `id` as `leadId`, `salesRepId` as `userId`, \'Sales Rep\' as `positionName` FROM leads WHERE `salesRepId` IS NOT NULL UNION SELECT `id` as `leadId`, `closerId` as `userId`, \'Closer\' as `positionName` FROM leads WHERE `closerId` IS NOT NULL UNION SELECT `id` as `leadId`, `loginId` as `userId`, \'Customer\' as `positionName` FROM leads WHERE `loginId` IS NOT NULL) LU');
        await queryRunner.query('ALTER TABLE `leads` DROP FOREIGN KEY `FK_117a609ddd2747a42278e3f598a`');
        await queryRunner.query('ALTER TABLE `leads` DROP FOREIGN KEY `FK_15c90181db7c1414910c5db44b0`');
        await queryRunner.query('ALTER TABLE `leads` DROP FOREIGN KEY `FK_43df2f9c5b545f12bf66626046a`');
        await queryRunner.query('DROP INDEX `userId` ON `leads`');
        await queryRunner.query('DROP INDEX `REL_117a609ddd2747a42278e3f598` ON `leads`');
        await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `loginId`');
        await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `salesRepId`');
        await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `closerId`');
        await queryRunner.query('ALTER TABLE `leadUsers` ADD CONSTRAINT `FK_f4c20fecd90eb8962099a59f40f` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE NO ACTION ON UPDATE NO ACTION');
        await queryRunner.query('ALTER TABLE `leadUsers` ADD CONSTRAINT `FK_fd975f14adc37e5d77b90fdab18` FOREIGN KEY (`userId`) REFERENCES `users`(`id`) ON DELETE NO ACTION ON UPDATE NO ACTION');
        await queryRunner.query('ALTER TABLE `leadUsers` ADD CONSTRAINT `FK_77f97953be47d37e862aadd4c73` FOREIGN KEY (`positionName`) REFERENCES `leadPositions`(`name`) ON DELETE NO ACTION ON UPDATE NO ACTION');
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query('ALTER TABLE `leads` ADD `closerId` int NULL');
        await queryRunner.query('ALTER TABLE `leads` ADD `salesRepId` int NULL');
        await queryRunner.query('ALTER TABLE `leads` ADD `loginId` int NOT NULL');
        await queryRunner.query('UPDATE `leads` l INNER JOIN leadUsers lu ON lu.leadId = l.id AND lu.positionName = \'Customer\' SET l.loginId = lu.userId;');
        await queryRunner.query('UPDATE `leads` l INNER JOIN leadUsers lu ON lu.leadId = l.id AND lu.positionName = \'Sales Rep\' SET l.salesRepId = lu.userId;');
        await queryRunner.query('UPDATE `leads` l INNER JOIN leadUsers lu ON lu.leadId = l.id AND lu.positionName = \'Closer\' SET l.closerId = lu.userId;');
        await queryRunner.query('ALTER TABLE `leadUsers` DROP FOREIGN KEY `FK_77f97953be47d37e862aadd4c73`');
        await queryRunner.query('ALTER TABLE `leadUsers` DROP FOREIGN KEY `FK_fd975f14adc37e5d77b90fdab18`');
        await queryRunner.query('ALTER TABLE `leadUsers` DROP FOREIGN KEY `FK_f4c20fecd90eb8962099a59f40f`');
        await queryRunner.query('DROP TABLE `leadUsers`');
        await queryRunner.query('DROP TABLE `leadPositions`');
        await queryRunner.query('CREATE UNIQUE INDEX `REL_117a609ddd2747a42278e3f598` ON `leads` (`loginId`)');
        await queryRunner.query('CREATE UNIQUE INDEX `userId` ON `leads` (`loginId`)');
        await queryRunner.query('ALTER TABLE `leads` ADD CONSTRAINT `FK_15c90181db7c1414910c5db44b0` FOREIGN KEY (`closerId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE');
        await queryRunner.query('ALTER TABLE `leads` ADD CONSTRAINT `FK_117a609ddd2747a42278e3f598a` FOREIGN KEY (`loginId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE');
        await queryRunner.query('ALTER TABLE `leads` ADD CONSTRAINT `FK_43df2f9c5b545f12bf66626046a` FOREIGN KEY (`salesRepId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE');
    }

}
