import {MigrationInterface, QueryRunner} from "typeorm";

export class documentEntriesAdjustments1552995266556 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `utilities` DROP COLUMN `docName`");
        await queryRunner.query("ALTER TABLE `documentEntries` ADD `leadId` int NULL");
        await queryRunner.query("ALTER TABLE `documentEntries` ADD CONSTRAINT `FK_83eff0c6052bf534f29287930ad` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE RESTRICT");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `documentEntries` DROP FOREIGN KEY `FK_83eff0c6052bf534f29287930ad`");
        await queryRunner.query("ALTER TABLE `documentEntries` DROP COLUMN `leadId`");
        await queryRunner.query("ALTER TABLE `utilities` ADD `docName` varchar(255) NULL");
    }

}
