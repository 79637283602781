import {MigrationInterface, QueryRunner} from "typeorm";

export class utilityCPWAndPanelTable1553223492277 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `panels` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NOT NULL, `wattage` decimal NOT NULL DEFAULT 300, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `utilities` ADD `baseCostPerWatt` decimal NOT NULL DEFAULT 4");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `utilities` DROP COLUMN `baseCostPerWatt`");
        await queryRunner.query("DROP TABLE `panels`");
    }

}
