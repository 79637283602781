import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ceiling'
})
export class CeilingPipe implements PipeTransform {

	transform(value: any, ...args: any[]): any {
		return Math.ceil(value);
	}

}
