import { CreateDateColumn, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';

import { Lead } from './lead';
import { LoanConfigColumn } from './loan-config-column';
import { LoanConfigSet } from './loan-config-set';
import { User } from './user';

@Entity('loanConfigs')
@Index(['lead', 'user'], { unique: true })
export class LoanConfig {

	public static get Empty() {
		return new LoanConfig({
			sets: [
				LoanConfigSet.Empty('Primary'),
				LoanConfigSet.Empty('Alternate')
			]
		});
	}

	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@ManyToOne(type => Lead, (lead: Lead) => lead.loanConfigs, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	lead: Lead | undefined;

	@ManyToOne(type => User, (user: User) => user.loanConfigs, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'userId'})
	user: User | undefined;

	@OneToMany(type => LoanConfigSet, (loanConfigSet: LoanConfigSet) => loanConfigSet.loanConfig, { onDelete: 'CASCADE', onUpdate: 'CASCADE', cascade: true })
	sets: LoanConfigSet[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LoanConfig> = {}) {
		this.id = args.id;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.user = args.user ? new User(args.user) : undefined;
		this.sets = args.sets ? args.sets.map(s => new LoanConfigSet(s)) : undefined;
	}
}
