import { Entity, ManyToOne, PrimaryGeneratedColumn, RelationId, CreateDateColumn, UpdateDateColumn, JoinColumn } from 'typeorm';
import { Lead } from './lead';
import { User } from './user';
import { LeadPosition } from './lead-positions';

@Entity({name: 'leadUsers'})
export class LeadUser {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@ManyToOne(type => Lead, lead => lead.leadUsers)
	@JoinColumn({ name: 'leadId' })
	public lead: Lead | undefined;

	@ManyToOne(type => User)
	@JoinColumn({ name: 'userId' })
	public user: User | undefined;

	@ManyToOne(type => LeadPosition)
	@JoinColumn({ name: 'positionName' })
	public position: LeadPosition | undefined;

	public get positionName() { return this.position && this.position.name; }

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LeadUser> = {}) {
		this.id = args.id;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.user = args.user ? new User(args.user) : undefined;
		this.position = args.position ? new LeadPosition(args.position) : undefined;
	}

	toJSON() {
		const retObj = Object.assign({}, this);
		if (retObj.user) retObj.user = retObj.user.toJSON();
		return retObj;
	}
}
