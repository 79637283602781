import { SelectQueryBuilder } from 'typeorm';
import { Scopes } from '../rbac-scope.enum';

export function Protects<T extends new(...args: any[]) => any>(typeFunc: (...args: any[]) => T) {
	return (target: (new(...args: any[]) => ProtectedMap) | any, propertyKey: string) => {
		const protectedType: ProtectedMap = <any>target.constructor;
		if (!protectedType.__protectedTypesLazyMap__) initProtectedMap(protectedType);
		protectedType.__protectedTypesLazyMap__[propertyKey] = typeFunc;
	};
}

export interface ProtectedMap {
	__protectedTypesLazyMap__: {[key: string]: () => new(...args: any[]) => any};
	__protectedTypesMap__: [string, new(...args: any[]) => any][];
	__protectedKeyFor<T = any>(targetType: object): keyof T | undefined;
	__protectedKeyFor<T = any>(targetType: new(...args: any[]) => any): keyof T | undefined;
	__protectedTypeByKey(key: string): (new(...args: any[]) => any) | undefined;
}

export interface PropertyOwnerConfig {
	scope?: Scopes;
	path?: string[];
}
export interface CustomOwnerConfig<T = any> {
	scope?: Scopes;
	alias: string;
	query: (qb: SelectQueryBuilder<T>) => void;
}

export function isPropertyOwnerConfig(obj: any): obj is PropertyOwnerConfig {
	return (obj === undefined || (typeof obj === 'object') && !('query' in obj) && ('scope' in obj || 'path' in obj));
}

export function isCustomOwnerConfig<T = any>(obj: any): obj is CustomOwnerConfig<T> {
	return ((typeof obj === 'object') && ('alias' in obj && 'query' in obj));
}

export const isOwnerConfig = <T = any>(obj: any): obj is OwnerConfig<T> => isPropertyOwnerConfig(obj) || isCustomOwnerConfig<T>(obj);

export type OwnerConfig<T = any> = PropertyOwnerConfig | CustomOwnerConfig<T>;

const defaultPropertyConfig: PropertyOwnerConfig = {
	scope: Scopes.OWN,
	path: [],
};

export interface Type<T = any> {
	prototype: T;
	constructor: (...args: any) => T;
}
export interface Instance<T = any> {
	constructor: (...args: any) => T;
}

function isInstance(target: any): target is Instance {
	return !target.prototype;
}

function initProtectedMap(target: Partial<ProtectedMap>) {
	const finalizeMap = () => {
		target.__protectedTypesMap__ = [];
		for (const [key, func] of Object.entries((<ProtectedMap>target).__protectedTypesLazyMap__)) {
			target.__protectedTypesMap__.push([key, func()]);
		}
	};
	target.__protectedTypesLazyMap__ = {};
	target.__protectedKeyFor = function(targetType: (new(...args: any[]) => any) | Instance) {
		if (!target.__protectedTypesMap__) finalizeMap();
		const possibleTypes: (new(...args: any[]) => any)[] = [];
		if (!isInstance(targetType)) {
			possibleTypes.push(targetType);
		} else {
			let t: new(...args: any[]) => any = <any>targetType.constructor;
			do { possibleTypes.push(t); } while (t = t.prototype);
		}
		const targetPair = (<ProtectedMap>target).__protectedTypesMap__.find(([key, protectedType]) => {
			return possibleTypes.some(t => {
				return t === protectedType || t.prototype instanceof protectedType;
			});
		});
		return targetPair ? targetPair[0] : undefined;
	};
	target.__protectedTypeByKey = function(targetKey: string) {
		if (!target.__protectedTypesMap__) finalizeMap();
		const targetPair = (<ProtectedMap>target).__protectedTypesMap__.find(([key, protectedType]) => key === targetKey);
		return targetPair ? targetPair[1] : undefined;
	};
}

export interface Protected { __ownerKeys__: OwnerConfig[]; }

export function Owner(): any;
export function Owner(passedConfig: CustomOwnerConfig): any;
export function Owner(passedConfig: PropertyOwnerConfig): any;
export function Owner(passedConfig?: OwnerConfig): any {
	return (target: any, propertyKey?: any) => {
		let config: OwnerConfig;
		if (isPropertyOwnerConfig(passedConfig)) {
			config = Object.assign({}, defaultPropertyConfig,  (passedConfig || {})  );
			config.path = [...config.path!];
			propertyKey && config.path.unshift(propertyKey);
			if (typeof propertyKey !== 'string') {
				propertyKey = null;
				target = target.prototype;
			}
		} else {
			target = target.prototype;
			config = Object.assign({}, {scope: Scopes.OWN}, passedConfig );
		}

		if (!target.__ownerKeys__) initOwnerMeta(target);
		target.__ownerKeys__.push(config);
	};
}

function initOwnerMeta(target: any) {
	Object.defineProperty(target, '__ownerKeys__', {
		writable: false,
		value: []
	});
}

function keysToObject (target: any, scope: Scopes) {
	if (!target.__ownerKeys__) return [];
	const keys = target.__ownerKeys__[scope];
	if (!keys) return undefined;
	const combinedKeys = keys.reduce((a: string[][], s: string[] | string[][]) => {
		return a.concat(<string[][]>((s[0] && s[0] instanceof Array) ? s : [s])), <string[][]>[];
	});
	// @ts-ignore
	const objArr = combinedKeys.map((s: any[]) => s.filter((k: string) => !!k).reduce((obj, k) => obj && obj[k], this));
	return objArr.filter((obj: any) => !!obj);
}

