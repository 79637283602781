import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lead, Comment, Design, LeadAdder, HOA, Utility, Levels, CommentTag, Employee, Team, Signature, User, LeadUser, LeadPosition } from 'pecms-shared';

import { AuthService } from 'app/_services/auth.service';
import { FormHelperService, FormChangeTracker } from 'app/_services/form-helper.service';
import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'app/_services/user.service';
import { LeadUserService } from 'app/_services/lead-user.service';
import { AbstractServiceService, ExpandType, GetConfig } from 'app/_services/abstract-service.service';
import { LeadAdderService } from 'app/_services/adder.service';
import { DesignService } from 'app/_services/design.service';
import { Filterable } from 'app/_models/filterable';
import { QueryStringBuilder } from 'app/_helpers/query-string-builder';

@Injectable({
	providedIn: 'root'
})
export class LeadService extends AbstractServiceService<Lead> {
	protected entityClass = Lead;
	protected entityClassName = 'Lead';

	constructor(
		http: HttpClient,
		private _authSvc: AuthService,
		private _userSvc: UserService,
		private _fhSvc: FormHelperService,
		private _luSvc: LeadUserService,
		private _adderSvc: LeadAdderService,
		private _designSvc: DesignService
	) { super(http); }

	public watchedLeads: Observable<Lead[]> = this._authSvc.userSnapshot.can(Levels.ANY, Lead) ?
		this.http.get<any>(`/leads/important`).pipe(map((rs) => rs.map(r => new Lead(r)))) : of([]);

	public createLead(lead: Lead) {
		return this.http.post<Lead>(`/leads/invite`, lead.toPost()).pipe(map(r => new Lead(r)));
	}

	public prequalify = (leadId: number, annualIncome: number, dob: Date, provider = 'sunlight') => {
		return this.http.post<{message: string, status: string}>(`/leads/${leadId}/prequalify?provider=${provider}`, {annualIncome, dob});
	}

	public approveLead(identifier: number): Observable<Lead> {
		return this.http.post<Lead>(`/leads/${identifier}/approve`, null).pipe(map(r => new Lead(r)));
	}

	public getFilterableLeads(config: GetConfig<Lead>): Filterable<Lead> {
		return new Filterable(this, config);
	}

	public getFlatLeads(fields: string[], where: {[key: string]: any}) {
		const fieldQuery = fields.map(f => `fields[]=${f}`);
		const whereQuery = new QueryStringBuilder({where});
		return this.http.get<{[key: string]: any}[]>(`/leads/flat${whereQuery}&${fieldQuery.join('&')}`);
	}

	public generateForm(lead: Lead, existingForm?: FormGroup, primitiveKeys = [
		'design.hiddenLoans',
		'loanProvider',
		'loanProduct',
		'design.panel',
		'design.inverter',
		'status',
		'statusChanges',
	], primitiveTypes = [
		User,
		Employee,
		Team,
		// Comment,
		Signature
	]) {
		if (lead.design && lead.design.lead) delete lead.design.lead;
		if (!lead.hoa) lead.hoa = new HOA();
		if (!lead.utility) lead.utility = new Utility();
		if (!lead.adders) lead.adders = [];
		if (!lead.additionalCharges) lead.additionalCharges = [];
		if (!lead.leadUsers) lead.leadUsers = [];
		if (!lead.adders.includesByProp('name', 'salesmanAdjustment')) {
			lead.adders.push(new LeadAdder({name: 'salesmanAdjustment', amount: 0, type: 'per-watt'}));
		}
		if (!lead.leadUsers.includesByProp('positionName', 'Sales Rep')) {
			lead.leadUsers.push(new LeadUser({position: new LeadPosition('Sales Rep')}));
		}
		if (!lead.leadUsers.includesByProp('positionName', 'Customer')) {
			lead.leadUsers.push(new LeadUser({position: new LeadPosition('Customer')}));
		}
		if (!lead.leadUsers.includesByProp('positionName', 'Closer')) {
			lead.leadUsers.push(new LeadUser({position: new LeadPosition('Closer')}));
		}
		if (!lead.comments) lead.comments = [];
		if (!(<any[]>lead.comments).includesByProp('tags', c => c.name === 'CustomerQuestions')) {
			lead.comments.push(new Comment({tags: [new CommentTag({name: 'CustomerQuestions'})]}));
		}
		const form = this._fhSvc.createOrUpdateForm( lead, existingForm, { primitiveKeys, primitiveTypes } );
		form.addControl('user', new FormGroup({
			email: new FormControl(lead.user && lead.user.email),
			firstName: new FormControl(lead.user && lead.user.firstName),
			lastName: new FormControl(lead.user && lead.user.lastName),
		}));
		form.addControl('salesmanAdjustment', new FormControl(lead.salesmanAdjustment));
		return form;
	}

	public saveChanges(lead: Lead, changeTracker: FormChangeTracker, expand?: ExpandType<Lead>): Observable<PossibleChanges> {
		const obsList = [];
		const additionalChargesChanges = changeTracker.changes && changeTracker.changes.additionalCharges;
		const adderChanges = changeTracker.pluck(['adders']);
		const salesmanAdjChange = changeTracker.pluck(['salesmanAdjustment']);
		const designChanges = changeTracker.pluck(['design']);
		const leadUserChanges = changeTracker.pluck(['leadUsers']);
		const customerChanges = changeTracker.pluck(['user']);
		const patch = changeTracker.changes;

		if (additionalChargesChanges && Object.keys(additionalChargesChanges).length) patch.additionalCharges = lead.additionalCharges;

		const adderExpands: ExpandType<LeadAdder> | undefined = expand && typeof expand.adders !== 'boolean' ? expand.adders : undefined;
		const designExpands: ExpandType<Design> | undefined = expand && typeof expand.design !== 'boolean' ? expand.design : undefined;
		const leadUserExpands: ExpandType<User> | undefined = expand && typeof expand.leadUsers !== 'boolean' ? expand.leadUsers : undefined;
		const userExpands: ExpandType<User> | undefined = leadUserExpands && typeof (<ExpandType<LeadUser>>expand!.leadUsers).user !== 'boolean' ? <ExpandType<User>>(<ExpandType<LeadUser>>expand.leadUsers).user : undefined;

		if (patch && Object.keys(patch).length) {
			obsList.push(this.update(patch, lead.id, expand).pipe(map(l => ['lead', l])));
		}
		if ((adderChanges && Object.keys(adderChanges).length) || (salesmanAdjChange !== undefined)) {
			const [adderPatch, adderPost] = Object.entries((adderChanges || {})).reduce((a, [index, changes]) => {
				if (lead.adders[index] && lead.adders[index].id) {
					a[0].push(Object.assign(changes, {id: lead.adders[+index].id}));
				} else {
					a[1].push(lead.adders[index]);
				}
				return a;
			}, [[], []]);
			if (salesmanAdjChange !== undefined) {
				const existingSA = lead.getAdder('salesmanAdjustment');
				if (existingSA && existingSA.id) {
					adderPatch.push({amount: salesmanAdjChange, id: existingSA.id});
				} else {
					adderPost.push(new LeadAdder({lead, name: 'salesmanAdjustment', amount: salesmanAdjChange, type: 'per-watt'}));
				}
			}
			if (adderPatch.length) obsList.push(this._adderSvc.updateLeadAdders(lead.id, Object.values(adderPatch), adderExpands).pipe(map(a => ['adders', a])));
			if (adderPost.length) obsList.push(this._adderSvc.addAddersToLead(lead.id, adderPost, adderExpands).pipe(map(a => ['adders', a])));
		}
		if (customerChanges && Object.keys(customerChanges).length) {
			obsList.push(this._userSvc.patchUser(customerChanges, lead.user.id, userExpands).pipe(map(u => ['customer', u])));
		}
		if (designChanges && Object.keys(designChanges).length) {
			obsList.push(this._designSvc.updateDesign(designChanges, lead.id, designExpands).pipe(map(d => ['design', d])));
		}
		if (leadUserChanges && Object.keys(leadUserChanges).length) {
			const [userPatch, userPost] = Object.entries((leadUserChanges || {})).reduce((a, [index, changes]) => {
				if (lead.leadUsers[index] && lead.leadUsers[index].id) {
					a[0].push(Object.assign(changes, {id: lead.leadUsers[+index].id}));
				} else {
					a[1].push(lead.leadUsers[index]);
				}
				return a;
			}, [[], []]);
			if (userPatch.length) obsList.push(this._luSvc.updateMany(Object.values(userPatch), userExpands).pipe(map(u => ['users', u])));
			if (userPost.length) obsList.push(this._luSvc.createOnLead(lead.id, userPost, userExpands).pipe(map(u => ['users', u])));
		}

		return merge(...obsList);
	}
}

export type PossibleChanges = ['lead', Lead] | ['adders', LeadAdder[]] | ['users', LeadUser[]] | ['customer', User] | ['design', Design];
