import {MigrationInterface, QueryRunner} from "typeorm";

export class googleId1559507225899 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `users` ADD `googleId` varchar(255) NULL");
        await queryRunner.query("ALTER TABLE `users` ADD UNIQUE INDEX `IDX_f382af58ab36057334fb262efd` (`googleId`)");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `users` DROP INDEX `IDX_f382af58ab36057334fb262efd`");
        await queryRunner.query("ALTER TABLE `users` DROP COLUMN `googleId`");
    }

}
