import {MigrationInterface, QueryRunner} from 'typeorm';

export class signerFix1568654965422 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		// Add 'signature' and 'initials' type to FileTypes
		await queryRunner.query('INSERT INTO `fileTypes` (`name`, `public`) VALUES (\'Signature\', 0), (\'Initials\', 0);');
		// Create file entries for all signatures
		await queryRunner.query('INSERT INTO `files` (`name`, `signature`, `groupId`, `size`, `mimeType`, `complete`, `fileTypeName`, `bucketName`, `createdById`, `savedName`) SELECT * FROM (SELECT `signatureFile` as `name`, \'\' as `signature`, 0 as `groupId`, 0 as `size`, \'image/png\' as `mimeType`, 1 as `complete`, \'Signature\' as `fileTypeName`, \'pecms-userfiles\' as `bucketName`, `id` as `createdById`, `signatureFile` as `savedName` FROM users WHERE `signatureFile` IS NOT NULL UNION SELECT `initialFile` as `name`, \'\' as `signature`, 0 as `groupId`, 0 as `size`, \'image/png\' as `mimeType`, 1 as `complete`, \'Initials\' as `fileTypeName`, \'pecms-userfiles\' as `bucketName`, `id` as `createdById`, `initialFile` as `savedName` FROM users WHERE `initialFile` IS NOT NULL) f');
		await queryRunner.query('INSERT INTO `files` (`name`, `signature`, `groupId`, `size`, `mimeType`, `complete`, `fileTypeName`, `bucketName`, `createdById`, `savedName`) SELECT * FROM (SELECT `signatureFile` as `name`, \'\' as `signature`, 0 as `groupId`, 0 as `size`, \'image/png\' as `mimeType`, 1 as `complete`, \'Signature\' as `fileTypeName`, \'pecms-userfiles\' as `bucketName`, NULL as `createdById`, `signatureFile` as `savedName` FROM signers WHERE `signatureFile` IS NOT NULL UNION SELECT `initialFile` as `name`, \'\' as `signature`, 0 as `groupId`, 0 as `size`, \'image/png\' as `mimeType`, 1 as `complete`, \'Initials\' as `fileTypeName`, \'pecms-userfiles\' as `bucketName`, NULL as `createdById`, `initialFile` as `savedName` FROM signers WHERE `initialFile` IS NOT NULL) f');
		// Update signatures to link to file entries
		await queryRunner.query('UPDATE `users` JOIN `files` f ON f.savedName = signatureFile SET `signatureFile` = `f`.`id`');
		await queryRunner.query('UPDATE `users` JOIN `files` f ON f.savedName = initialFile SET `initialFile` = `f`.`id`');
		await queryRunner.query('UPDATE `signers` JOIN `files` f ON f.savedName = signatureFile SET `signatureFile` = `f`.`id`');
		await queryRunner.query('UPDATE `signers` JOIN `files` f ON f.savedName = initialFile SET `initialFile` = `f`.`id`');
		// Change signature columns to relations
		await queryRunner.query('ALTER TABLE `users` MODIFY COLUMN `signatureFile` int NULL');
		await queryRunner.query('ALTER TABLE `users` MODIFY COLUMN `initialFile` int NULL');
		await queryRunner.query('ALTER TABLE `signers` MODIFY COLUMN `signatureFile` int NULL');
		await queryRunner.query('ALTER TABLE `signers` MODIFY COLUMN `initialFile` int NULL');
		await queryRunner.query('ALTER TABLE `users` ADD CONSTRAINT fk_user_signature_files FOREIGN KEY (signatureFile) REFERENCES files(id);');
		await queryRunner.query('ALTER TABLE `users` ADD CONSTRAINT fk_user_intials_files FOREIGN KEY (initialFile) REFERENCES files(id);');
		await queryRunner.query('ALTER TABLE `signers` ADD CONSTRAINT fk_signer_signature_files FOREIGN KEY (signatureFile) REFERENCES files(id);');
		await queryRunner.query('ALTER TABLE `signers` ADD CONSTRAINT fk_signer_intials_files FOREIGN KEY (initialFile) REFERENCES files(id);');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		// Change signature columns to strings
		await queryRunner.query('ALTER TABLE `users` DROP FOREIGN KEY fk_user_signature_files');
		await queryRunner.query('ALTER TABLE `users` DROP FOREIGN KEY fk_user_intials_files');
		await queryRunner.query('ALTER TABLE `users` MODIFY COLUMN `signatureFile` varchar(255) NULL');
		await queryRunner.query('ALTER TABLE `users` MODIFY COLUMN `initialFile` varchar(255) NULL');
		await queryRunner.query('ALTER TABLE `signers` DROP FOREIGN KEY fk_signer_signature_files');
		await queryRunner.query('ALTER TABLE `signers` DROP FOREIGN KEY fk_signer_intials_files');
		await queryRunner.query('ALTER TABLE `signers` MODIFY COLUMN `signatureFile` varchar(255) NULL');
		await queryRunner.query('ALTER TABLE `signers` MODIFY COLUMN `initialFile` varchar(255) NULL');
		// Update signatures to be filenames
		await queryRunner.query('UPDATE `users` JOIN `files` f ON f.id = signatureFile SET `signatureFile` = `f`.`savedName`');
		await queryRunner.query('UPDATE `users` JOIN `files` f ON f.id = initialFile SET `initialFile` = `f`.`savedName`');
		await queryRunner.query('UPDATE `signers` JOIN `files` f ON f.id = signatureFile SET `signatureFile` = `f`.`savedName`');
		await queryRunner.query('UPDATE `signers` JOIN `files` f ON f.id = initialFile SET `initialFile` = `f`.`savedName`');
		// Remove related file entries
		await queryRunner.query('DELETE FROM `files` WHERE `fileTypeName` = \'Signature\' OR `fileTypeName` = \'Initials\';');
		// Remove 'signature' and 'intials' types
		await queryRunner.query('DELETE FROM `fileTypes` WHERE `name` = \'Signature\' OR `name` = \'Initials\'');
	}

}
