import { Injectable } from '@angular/core';
import { LeadUser, LeadPosition } from 'pecms-shared';
import { AbstractServiceService, ExpandType } from 'app/_services/abstract-service.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryStringBuilder } from 'app/_helpers/query-string-builder';

@Injectable({
	providedIn: 'root'
})
export class LeadUserService extends AbstractServiceService<LeadUser>  {
	protected entityClass: new (...args: any[]) => LeadUser = LeadUser;
	protected entityClassName = 'leadUser';

	public getByLead(leadId: number, position: LeadPosition): Observable<LeadUser[]>;
	public getByLead(leadId: number, positionName: string): Observable<LeadUser[]>;
	public getByLead(leadId: number, position: string | LeadPosition): Observable<LeadUser[]>  {
		let url = `/leads/${leadId}/leadUsers`;
		if (position instanceof LeadPosition) position = position.name;
		if (position) url += `?position=${position}`;

		return this.http.get<LeadUser[]>(url).pipe(map(lus => lus.map(lu => new LeadUser(lu))));
	}

	public createOnLead(leadId: number, leadUser: LeadUser, expand?: ExpandType<LeadUser>): Observable<LeadUser>;
	public createOnLead(leadId: number, leadUsers: LeadUser[], expand?: ExpandType<LeadUser>): Observable<LeadUser[]>;
	public createOnLead<T extends LeadUser | LeadUser[]>(leadId: number, leadUsers: T, expand?: ExpandType<LeadUser>): Observable<LeadUser | LeadUser[]> {
		return this.http.post<T>(`/leads/${leadId}/leadUsers${new QueryStringBuilder({expand})}`, leadUsers).pipe(map(lus => {
			if (Array.isArray(leadUsers)) return (<LeadUser[]>lus).map(lu => new LeadUser(lu));
			return new LeadUser(<LeadUser>lus);
		}));
	}

	public updateOnLead(leadId: number, position: LeadPosition, patchVal: Partial<LeadUser>): Observable<LeadUser>;
	public updateOnLead(leadId: number, positionName: string, patchVal: Partial<LeadUser>): Observable<LeadUser>;
	public updateOnLead(leadId: number, position: string | LeadPosition, patchVal: Partial<LeadUser>): Observable<LeadUser> {
		let url = `/leads/${leadId}/leadUsers`;
		if (position instanceof LeadPosition) position = position.name;
		if (position) url += `?position=${position}`;

		return this.http.patch<LeadUser>(url, patchVal).pipe(map(lu => new LeadUser(lu)));
	}

	// public updateAllOnLead(leadId: number, patchVal: Partial<LeadUser[]>, expand?: ExpandType<LeadUser>): Observable<LeadUser[]> {
	// 	return this.http.patch<LeadUser[]>(`/leads/${leadId}/leadUsers${new QueryStringBuilder({expand})}`, patchVal).pipe(map(lus => {
	// 		return lus.map(lu => new LeadUser(lu));
	// 	}));
	// }
}
