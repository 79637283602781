import {MigrationInterface, QueryRunner} from "typeorm";

export class providferRefs1555105055432 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` ADD `providerProjectRef` varchar(255) NULL");
        await queryRunner.query("ALTER TABLE `leads` ADD `providerUserRef` varchar(255) NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `providerUserRef`");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `providerProjectRef`");
    }

}
