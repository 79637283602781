import { Column, CreateDateColumn, Entity, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';

@Entity('adders')
export class Adder {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
		length: 50
	})
	public name: string | undefined;

	@Column('varchar', {
		name: 'type',
		nullable: false,
	})
	public type: 'per-watt' | 'total' | undefined;

	@Column('decimal', {
		name: 'amount',
		nullable: false,
		precision: 8,
		scale: 3
	})
	public amount: number | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<Adder> = {}) {
		this.id = args.id;
		this.name = args.name;
		this.type = args.type;
		this.amount = args.amount;
	}
}
