import {MigrationInterface, QueryRunner} from 'typeorm';

export class loanDownPaymenty1568671433428 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `loanProducts` ADD `downPayment` decimal(6,5) NULL');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `loanProducts` DROP COLUMN `downPayment`');
	}

}
