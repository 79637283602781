import {environment as env} from 'environments/environment';

import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[ifEnv]'
})
export class IfEnvDirective {

	constructor(
		private element: ElementRef,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
	}

	@Input()
	set ifEnv(val: string | string[]) {
		if (!Array.isArray(val)) val = [val];
		if (val.some(e => env.name === e)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
