import { Component, Input, HostBinding, OnDestroy, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractValueAccessor } from 'app/_helpers/form-fields/abstract-value-accessor';
import { FileMeta, User, FileType } from 'pecms-shared';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { startWith, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { AppService } from 'app/app.service';
import { MatDialog, MatProgressBar, MatProgressSpinner } from '@angular/material';
import { ImageCropperComponent, CropperData } from 'app/shared/image-cropper/image-cropper.component';
import { environment } from 'environments/environment';
import { isUploadStatus, UploadStatus } from 'app/_services/file-upload.service';
import { FileService } from 'app/_services/file.service';

@Component({
	selector: 'pecms-image-uploader',
	templateUrl: './image-uploader.component.html',
	styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent extends AbstractValueAccessor<FileMeta> implements OnDestroy, OnInit {

	public round: boolean;
	@HostBinding('class.round-uploader')
	@Input('round')
	private set _round(val) {
		this.round = coerceBooleanProperty(val);
	}
	private get _round() {
		return this.round;
	}

	public resize: {width: number, height: number} = {width: 150, height: 150};
	@Input('resize')
	private set _resize(val) {
		this.resize = val;
	}
	private get _resize() {
		return this.resize;
	}

	public placeholder = new BehaviorSubject<string>('/assets/images/placeholder.jpg');
	@Input('placeholder')
	private set _placeholder(val: string) {
		this.placeholder.next(val);
	}
	private get _placeholder() {
		return this.placeholder.value;
	}

	public overlayColor: BehaviorSubject<string>;
	@Input('overlayColor')
	private set _overlayColor(val: string) {
		this.overlayColor ? this.overlayColor.next(val) : this.overlayColor = new BehaviorSubject<string>(val);
	}
	private get _overlayColor() {
		return this.overlayColor && this.overlayColor.value;
	}

	@Input()
	public type = 'Miscellaneous';

	@ViewChild('progress', {static: false})
	progress: MatProgressBar | MatProgressSpinner;

	@ViewChild('zoomElement', {static: false})
	zoomElement: TemplateRef<any>;

	public uploading = false;

	private _destroy = new Subject();

	private _imageSubj = new BehaviorSubject<string | undefined>(undefined);
	public imageDataObs = this._imageSubj.pipe(
		takeUntil(this._destroy),
		startWith(<any>undefined),
		switchMap(v => v ? this._imageSubj : this.placeholder)
	);

	constructor(
		private _appSvc: AppService,
		private _dialog: MatDialog,
		private _fileSvc: FileService,
		control: NgControl
	) {
		super(control);
		if (!this._overlayColor) {
			const bgColor = this._appSvc.theme.getColor('secondary');
			bgColor!.a = .5;
			this.overlayColor ? this.overlayColor.next(bgColor!.rgb) : this.overlayColor = new BehaviorSubject<string>(bgColor!.rgb);
		}
	}

	public onFileSelected(file: File) {
		if (file) {
			const cropperDlg = this._dialog.open<ImageCropperComponent, CropperData>(ImageCropperComponent, {
				width: '750px',
				maxHeight: '80vh',
				panelClass: 'flex-dialog-container',
				data: {
					src: file,
					round: this.round,
					resize: this.resize,
					aspectRatio: this.resize.width / this.resize.height
				}
			});
			let lastResponse: FileMeta | UploadStatus | FileMeta[];
			cropperDlg.afterClosed().subscribe(cropperRes => {
				if (!cropperRes) return;
				const [croppedDatatUrl, croppedFile] = cropperRes;
				this._imageSubj.next(croppedDatatUrl);
				this.uploading = true;
				this._fileSvc.handleFileInput([croppedFile], new FileType(this.type)).pipe(finalize(() => {
					this.uploading = false;
					this.updateModel(<FileMeta>lastResponse);
					setTimeout(() => {
						this.progress.value = 0;
					}, 1);
				})).subscribe(f => {
					lastResponse = f;
					if (isUploadStatus(f)) {
						if (this.progress.mode !== 'determinate') this.progress.mode = 'determinate';
						this.progress.value = f.type ? (f.loaded / f.total) * 100 : 0;
					}
				});
			});
		}
	}

	public ngOnDestroy() {
		this._destroy.next();
		this._destroy.complete();
	}

	public zoom() {
		this._dialog.open(this.zoomElement);
	}

	public updateView(val: string | FileMeta) {
		if (val && typeof val !== 'string') val = val.buildUrl(environment);
		this._imageSubj.next(<string>val);
	}
}
