import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import {SurveyResponse} from './survey-response';

@Entity('surveyQuestions')
export class SurveyQuestion {

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@Column('varchar', {
		nullable: false,
		length: 255,
		name: 'key'
	})
	key: string | undefined;

	@Column('longtext', {
		nullable: true,
		name: 'question'
	})
	question: string | undefined;

	@Column('longtext', {
		nullable: true,
		name: 'info'
	})
	info: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'type'
	})
	type: string | undefined;

	@Column('longtext', {
		nullable: true,
		name: 'options'
	})
	options: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	@OneToOne(
		type => SurveyResponse,
		(surveyResponse: SurveyResponse) => surveyResponse.question,
		{ onDelete: 'RESTRICT', onUpdate: 'CASCADE' }
	)
	surveyResponses: SurveyResponse | undefined;

	constructor(args: {[T in keyof SurveyQuestion]?: SurveyQuestion[T]} = {}) {
		this.id = args.id || undefined;
		this.key = args.key || undefined;
		this.question = args.question || undefined;
		this.info = args.info || undefined;
		this.type = args.type || undefined;
		this.options = args.options || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.surveyResponses = args.surveyResponses || undefined;
	}

}
