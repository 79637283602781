import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, PrimaryColumn, Column } from 'typeorm';

@Entity('leadPositions')
export class LeadPosition {
	@PrimaryColumn()
	public name!: string;

	@Column('tinyint', {
		name: 'allowMultiple',
		nullable: false,
		default: false
		// length: 255
	})
	public allowMultiple: boolean | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LeadPosition> | string = {}) {
		if (typeof args === 'string') {
			this.name = args;
		} else {
			this.name = args.name || '';
			this.allowMultiple = !!args.allowMultiple;
		}
	}
}
