import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone'
})
export class PhonePipe implements PipeTransform {

	transform(value: string, args?: any): any {
		value = value.replace(/[^0-9]/, '');
		const segments = value.match(/.*?([0-9]{3})([0-9]{3})([0-9]{4})/);
		if (!segments || segments.length !== 4) return value;
		return segments.slice(1).join('-');
	}

}
