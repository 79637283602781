import { Component, OnInit, ViewChild, TemplateRef, Inject, ElementRef, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { AlertConfig, AlertType } from 'app/_models';

@Component({
	selector: 'pecms-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
	@ViewChild('alert', {static: false}) alertTemplate;
	public config: AlertConfig;

	private _runningTime = 0;
	private _type: AlertType;
	private _defaultIcons = {
		[AlertType.Danger]: 'error',
		[AlertType.Success]: 'check_circle',
		[AlertType.Warn]: 'warning',
		[AlertType.Info]: 'info',
	};

	public get timeLeftPercentage() {
		return this.config.time >= 0 ? (this._runningTime / this.config.time) * 100 : 100;
	}
	public get palette() {
		return this._type !== undefined ? AlertType[this._type].toLowerCase() : 'primary';
	}
	private get _isComplete() {
		return this.timeLeftPercentage >= 100; // 105 to allow bar to close completeley
	}
	public get height() {
		if (!this.el || !this.el.nativeElement) return 0;
		let totalHeight = this.el.nativeElement.offsetTop;
		this.el.nativeElement.childNodes.forEach(n => totalHeight += n.offsetHeight);
		return totalHeight;
	}
	public setTop(px: number) {
		this.el.nativeElement.offsetTop = px;
	}

	constructor(
		private el: ElementRef,
		public dialogRef: MatDialogRef<AlertComponent>,
		@Inject(MAT_DIALOG_DATA) {type, config}: {type: AlertType, config: AlertConfig},
		private _ngZone: NgZone
	) {
		this._type = type;
		this.config = {...{time: 5, closeable: true, icon: this._defaultIcons[type] || 'info', mode: 'determinate'}, ...config};
		if (!this.config.closeable) this.dialogRef.disableClose = true;
	}

	ngOnInit() {
		this.dialogRef.afterOpened().subscribe(d => {
			if (this.config.time >= 0) this.updateTime();
		});
	}

	private updateTime = () => {
		this._runningTime += 0.01;
		if (!this._isComplete) {
			this._animate();
		} else {
			this.dialogRef.close();
		}
	}
	private _animate() {
		this._ngZone.runOutsideAngular(() => { setTimeout(this.updateTime, 10); });
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
