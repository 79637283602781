export enum SaleStatusEnum {
	'Pending Install' = 1,
	'Installed'
}

export namespace SaleStatusEnum {
		export const keys: Array<string> = (() => {
			const names = Object.keys(SaleStatusEnum);
			return names.slice(names.length / 2);
		})();
		export const indexes: Array<number> = (() => {
			const names = Object.keys(SaleStatusEnum);
			return names.slice(0, (names.length  - 1) / 2).map(i => +i);
		})();
}
