import { Component, Input, TemplateRef, ViewChild, ElementRef, HostBinding, ChangeDetectorRef, Self, Optional} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Validator, ValidationErrors, AbstractControl, NgControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { FileUploadService, isUploadStatus } from 'app/_services/file-upload.service';
import { FileService } from 'app/_services/file.service';
import { finalize } from 'rxjs/operators';
import { StatusButtonComponent } from 'app/shared/status-button/status-button.component';
import { FileType, FileMeta } from 'pecms-shared';
import { AbstractValueAccessor } from 'app/_helpers/form-fields/abstract-value-accessor';


@Component({
	selector: 'pecms-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent extends AbstractValueAccessor<FileMeta | FileMeta[], string> implements Validator {

	constructor(
		protected _fileUploadSvc: FileUploadService,
		protected _fileSvc: FileService,
		protected _http: HttpClient,
		protected _cdRef: ChangeDetectorRef,
		@Optional() control: NgControl
	) {
		super(control);
	}

	@Input('icon')
	set icon(value: string) { this._icon = value; }

	@HostBinding()
	@Input('multiple')
	public set multiple(val: boolean) {
		this._multiple = coerceBooleanProperty(val);
	}
	public get multiple(): boolean {
		return this._multiple;
	}

	@ViewChild('fileInput', {static: false}) fileInput: ElementRef<any>;
	@ViewChild('statusButton', {static: false}) statusButton: StatusButtonComponent;

	protected _icon = 'cloud_upload';

	@Input()
	public template: TemplateRef<any> = null;

	@Input()
	public accept: string;

	@Input()
	public fontSize: number = null;

	@Input()
	public inputAppearance: 'standard' | 'fill' | 'outline' = null;

	@Input()
	public color: 'primary' | 'accent' | 'warn' = 'primary';

	@HostBinding()
	@Input()
	public type = 'Miscellaneous';

	private _multiple = false;

	public displayName = '';

	@HostBinding()
	@Input('buttonStyle')
	public buttonStyle: 'regular' | 'raised' | 'flat' | 'stroked' | 'fab' | 'mini-fab' | 'icon' = 'icon';

	public validate(control: AbstractControl): ValidationErrors {
		let validRes = null;
		if (this.required && !control.value) validRes = {required: true};
		if (this.statusButton && this.statusButton.mode !== 'ready') validRes = Object.assign(validRes || {}, {fileUploading: true});

		return validRes;
	}

	public changeImage(e: Event) {
		e.preventDefault();
		e.stopPropagation();
		this.fileInput.nativeElement.dispatchEvent(new MouseEvent('click', {cancelable: true}));
	}

	public uploadFile(files: FileList) {
		this.statusButton.mode = 'indeterminate';
		this.statusButton.percentage = 0;
		let lastResponse;
		this._fileSvc.handleFileInput(files, <FileType>{name: this.type}).pipe(finalize(() => {
			this.statusButton.mode = 'ready';
			this.updateModel(lastResponse);
			this.updateView(lastResponse);
			setTimeout(() => {
				this.statusButton.percentage = 0;
			}, 1);
		})).subscribe(f => {
			lastResponse = f;
			if (isUploadStatus(f)) {
				if (this.statusButton.mode !== 'determinate') this.statusButton.mode = 'determinate';
				this.statusButton.percentage = f.type ? (f.loaded / f.total) * 100 : 0;
			}
		});

	}

	public updateView(value: FileMeta | FileMeta[] | string) {
		if (!value) {
			this.displayName = '';
			return;
		}

		if (typeof value !== 'string') value = Array.isArray(value) ? value.length + ' Files' : value.name;
		this.displayName = value;
	}

}
