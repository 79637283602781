import {MigrationInterface, QueryRunner} from "typeorm";

export class activeLeadStatus1563552787801 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leadStatuses` ADD `activeLead` tinyint NOT NULL DEFAULT 1");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leadStatuses` DROP COLUMN `activeLead`");
    }

}
