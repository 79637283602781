import { GetConfig, WhereExpression } from 'app/_services/abstract-service.service';
import moment from 'moment';

export class QueryStringBuilder<T = any> {

	private _whereComponents() {
		if (!this._config.where) return '';
		const whereComponents: string[] = [];
		for (const [path, value] of this._objectLeaves<WhereExpression>(this._config.where)) {
			whereComponents.push(`where[]=${encodeURIComponent(path + value[0] + value.slice(1).map(v => {
				if (v instanceof Date) return moment(v).format('MM/DD/YYYY');
				if (moment.isMoment(v)) return v.format('MM/DD/YYYY');
				return v;
			}).join('|'))}`);
		}
		return whereComponents.join('&');
	}
	private _expandComponents() {
		if (!this._config.expand) return '';
		const expandComponents: string[] = [];
		for (const path of this._nodePaths<WhereExpression>(this._config.expand)) {
			expandComponents.push(`expand[]=${encodeURIComponent(path)}`);
		}
		return expandComponents.join('&');
	}
	private _orderByComponents() {
		if (!this._config.orderBy) return '';
		const orderByComponents:  string[] = [];
		const targetOrderBy = this._config.orderBy[this._config.orderBy.length - 1];
		orderByComponents.push(`orderBy=${targetOrderBy[0]}`);
		if (targetOrderBy[1]) orderByComponents.push(`orderDir=${encodeURIComponent(targetOrderBy[1])}`);
		return orderByComponents.join('&');
	}

	private *_objectLeaves<L = any>(obj: any, keys: string[] = []): IterableIterator<[string, L]> {
		for (const [key, val] of Object.entries(obj)) {
			const nodeKeys = [...keys, key];
			if (typeof val === 'object' && !Array.isArray(val) && !(val instanceof Date)) {
				yield* this._objectLeaves(val, nodeKeys);
			} else {
				yield [nodeKeys.join('.'), <L>val];
			}
		}
	}

	private *_nodePaths<L = any>(obj: any, keys: string[] = []): IterableIterator<string> {
		for (const [key, val] of Object.entries(obj)) {
			const nodeKeys = [...keys, key];
			yield nodeKeys.join('.');
			if (typeof val === 'object' && !Array.isArray(val) && !(val instanceof Date)) {
				yield* this._nodePaths(val, nodeKeys);
			}
		}
	}

	constructor(private _config: GetConfig<T> = {}) {}

	toString() {
		if (!this._config || !Object.keys(this._config)) return '';
		const queryComponents: string[] = [];
		if (this._config.where) queryComponents.push(this._whereComponents());
		if (this._config.expand) queryComponents.push(this._expandComponents());
		if (this._config.orderBy) queryComponents.push(this._orderByComponents());
		if (this._config.page) queryComponents.push(`page=${encodeURIComponent(this._config.page)}`);
		if (this._config.size) queryComponents.push(`size=${encodeURIComponent(this._config.size)}`);
		return `?${queryComponents.join('&')}`;
	}
}
