import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive, Attribute } from '@angular/core';

@Directive({
	selector: '[requiredIf]',
	providers: [{provide: NG_VALIDATORS, useExisting: RequiredIfValidator, multi: true}]
})
export class RequiredIfValidator implements Validator {
	public static validationMessage = 'Required';

	@Attribute('requiredIf')
	requiredIf: boolean;

	validate = RequiredIfValidator.requireIf(this.requiredIf);

	static requireIf = (val: boolean) => (c: AbstractControl) => {
		let vErr: ValidationErrors = null;

		if (!val) {
			vErr = {requiredIf: RequiredIfValidator.validationMessage};
		}
		return vErr;
	}

}
