import {MigrationInterface, QueryRunner} from "typeorm";

export class leadUpdates1552474304629 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `hoas` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NULL, `phone` varchar(255) NULL, `email` varchar(255) NULL, `docName` varchar(255) NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("CREATE TABLE `utilities` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NULL, `docName` varchar(255) NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `leads` ADD `maxOutRoof` tinyint NOT NULL DEFAULT 0");
        await queryRunner.query("ALTER TABLE `leads` ADD `squareFootage` int NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `squareFootage`");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `maxOutRoof`");
        await queryRunner.query("DROP TABLE `utilities`");
        await queryRunner.query("DROP TABLE `hoas`");
    }
}
