import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';

import { LoanConfig } from './loan-config';
import { LoanConfigColumn } from './loan-config-column';

@Entity('loanConfigSets')
export class LoanConfigSet {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@ManyToOne(type => LoanConfig, (loanConfig: LoanConfig) => loanConfig.sets, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'loanConfigId'})
	loanConfig: LoanConfig | undefined;

	@OneToMany(type => LoanConfigColumn, (loanConfigColumn: LoanConfigColumn) => loanConfigColumn.set, { onDelete: 'CASCADE', onUpdate: 'CASCADE', cascade: true })
	@JoinColumn({name: 'loanConfigColumnId'})
	columns: LoanConfigColumn[] | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
	})
	public name: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LoanConfigSet> = {}) {
		this.id = args.id;
		this.loanConfig = args.loanConfig ? new LoanConfig(args.loanConfig) : undefined;
		this.name = args.name;
		this.columns = args.columns ? args.columns.map(lcc => new LoanConfigColumn(lcc)) : undefined;
		if (this.columns && this.columns.length) {
			this.columns.forEach((col, i) => col.order = i);
		}
	}

	public static Empty(name: string) {
		return new LoanConfigSet({
			name,
			columns: [
				LoanConfigColumn.Empty,
				LoanConfigColumn.Empty,
				LoanConfigColumn.Empty
			]
		});
	}
}
