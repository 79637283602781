import { Component, Input, HostBinding, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'pecms-fullscreen-photo',
	templateUrl: './fullscreen-photo.component.html',
	styleUrls: ['./fullscreen-photo.component.scss']
})
export class FullscreenPhotoComponent {
	@Input() url: string;

	resizeModal(e: Event) {
		let updatedWidth = e.target['width'] + 48;
		let updatedHeight = e.target['height'] + 48;
		const imageAR = e.target['naturalWidth'] / e.target['naturalHeight'];
		const dlgAR = e.target['width'] / e.target['height'];

		if (imageAR > dlgAR) updatedHeight = (e.target['width'] / imageAR) + 48;
		if (imageAR < dlgAR) updatedWidth = (e.target['height'] * imageAR) + 48;

		this._dlgRef.updateSize(updatedWidth, updatedHeight);
	}

	constructor(
		private _dlgRef: MatDialogRef<FullscreenPhotoComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) data: any
	) {
		if (data && data.url) this.url = data.url;
	}
}
