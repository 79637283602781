import {MigrationInterface, QueryRunner} from 'typeorm';

export class apiKeyTable1582967055468 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('CREATE TABLE `apiKeys` (`key` varchar(64) NOT NULL, `prefix` varchar(10) NOT NULL, `name` varchar(55) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `userId` int NULL, PRIMARY KEY (`key`)) ENGINE=InnoDB', undefined);
		await queryRunner.query('ALTER TABLE `apiKeys` ADD CONSTRAINT `FK_f9afbca84154973716c623ac9f4` FOREIGN KEY (`userId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE', undefined);
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `apiKeys` DROP FOREIGN KEY `FK_f9afbca84154973716c623ac9f4`', undefined);
		await queryRunner.query('DROP TABLE `apiKeys`', undefined);
	}

}
