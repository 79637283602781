import {MigrationInterface, QueryRunner} from 'typeorm';

export class addLenderToLoanProvider1568742150993 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `loanProviders` ADD `lender` varchar(255) NOT NULL');
		await queryRunner.query('UPDATE loanProviders SET `lender` = \'LoanPal\' WHERE name != \'Cash\'');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `loanProviders` DROP COLUMN `lender`');
	}

}
