import { MatchFontService } from 'app/_services/match-font.service';
import { Unsubscribable } from 'app/shared/unsubscribable';
import { ResizeSensor } from 'css-element-queries';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import {
	AfterContentChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewRef
} from '@angular/core';

@Directive({
	selector: '[autoFont]'
})
export class MatchFontDirective extends Unsubscribable implements OnDestroy, OnInit, OnChanges, AfterViewInit, AfterContentChecked {
	public static validNodes = ['a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'b', 'i', 'span'];

	@Input('autoFont')
	@HostBinding('attr.autoFont')
	groupName: string;

	@Input('autoFontConfig')
	config: MatchFontConfig = {};

	@Input()
	maxSize = Infinity;

	@Input()
	minSize = 0;

	private _usingInnerText = false;

	private _sensor: ResizeSensor;

	// This uses HostBinding, but must start listening afterView has initialized.  See ngAfterViewInit for implementation.
	public set innerText(val) {
		this._innerText = val;
	}
	public get innerText() {
		return this._innerText;
	}

	private _innerText: string;

	private _ctx: CanvasRenderingContext2D;

	private _widthRatio: number;

	public fontSize: BehaviorSubject<number>;

	@HostBinding('style.fontSize') groupFontSize: string;
	@HostBinding('style.transition') transition = 'font-size 40ms';

	constructor(private _element: ElementRef, private _mfSvc: MatchFontService, private _cdRef: ChangeDetectorRef) {
		super();
		const cnv: HTMLCanvasElement = document.createElement('canvas');
		this._ctx = cnv.getContext('2d');
	}

	ngOnDestroy() {
		this._sensor && this._sensor.detach();
	}

	ngOnInit() {
		if (!(this.config.delay instanceof Observable)) this.config.delay = timer(this.config.delay || 0);
		this.config.delay.pipe(first()).subscribe(_ => {
			if (this.config.value === undefined || this.config.value === null) {
				this.config.value = this._element.nativeElement.innerText;
				this._usingInnerText = true;
			}
			this.innerText = this.config.value;
			this.calculateWidth();
			this._sensor = new ResizeSensor(this._element.nativeElement.parentElement, __ => this.calculateWidth() );
			this._mfSvc.register(this).pipe(takeUntil(this.unsubscribe)).subscribe(s => {
				if (!(this._cdRef as ViewRef).destroyed) {
					this.groupFontSize = s + 'px';
					this._cdRef.detectChanges();
				}
			});
		});
	}

	ngAfterViewInit() {
		this.innerText = this._element.nativeElement.innerText;
		HostBinding()(this, 'innerText', Object.getOwnPropertyDescriptor(this, 'innerText'));
		this._cdRef.detectChanges();
	}

	public calculateWidth(compStyle?: CSSStyleDeclaration ) {
		if (!compStyle) compStyle = window.getComputedStyle(this._element.nativeElement);
		this._calculateRatio(compStyle);
		const padding = parseFloat(compStyle.paddingLeft.slice(0, -2) || '0') + parseFloat(compStyle.paddingRight.slice(0, -2) || '0');
		// subtract .1 for rare cases of exact widths wrapping
		const potentialFontSize = ((this._element.nativeElement.offsetWidth - padding) * this._widthRatio) - 1;
		const fontSize = Math.min(Math.max(this.minSize, potentialFontSize), this.maxSize);
		if (!this.fontSize) {
			this.fontSize = new BehaviorSubject(fontSize);
		} else {
			this.fontSize.next(fontSize);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.config && !changes.config.isFirstChange && changes.config.currentValue && changes.config.currentValue.value) {
			this.calculateWidth();
		}
	}

	ngAfterContentChecked() {
		if (
			!(this._cdRef as ViewRef).destroyed &&
			this.config.value !== undefined &&
			this.config.value !== 'undefined' &&
			this.config.value !== this._element.nativeElement.innerText
		) {
			this.config.value = this._element.nativeElement.innerText;
			this._cdRef.detectChanges();
			this.calculateWidth();
		}
	}

	private _calculateRatio(compStyle?: CSSStyleDeclaration) {
		if (!compStyle) compStyle = window.getComputedStyle(this._element.nativeElement);
		this._ctx.font = compStyle.font;
		this._widthRatio = parseFloat(compStyle.fontSize.slice(0, -2)) / this._ctx.measureText(this.config.value).width;
	}
}

export interface MatchFontConfig {
	value?: string;
	delay?: number | Observable<any>;
}
