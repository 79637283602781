import {MigrationInterface, QueryRunner} from "typeorm";

export class userNotificationFix1563801799327 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER VIEW `user_notifications` AS SELECT `ur`.`loginId` AS `loginId`, `rn`.`statusName` AS `statusName`, `rn`.`message` AS `message`, `rn`.`createdAt` AS `createdAt`, `rn`.`updatedAt` AS `updatedAt` FROM (`user_roles` `ur` left join `roleNotifications` `rn` on(`ur`.`roleId` = `rn`.`roleId`))")
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER VIEW `user_notifications` AS SELECT `ur`.`loginId` AS `loginId`, `rn`.`statusId` AS `statusId`, `rn`.`message` AS `message`, `rn`.`createdAt` AS `createdAt`, `rn`.`updatedAt` AS `updatedAt` FROM (`user_roles` `ur` left join `roleNotifications` `rn` on(`ur`.`roleId` = `rn`.`roleId`))")
    }

}
