import 'reflect-metadata';
import { SelectQueryBuilder } from 'typeorm';
import { QueryState, SelectorCallback, SelectorQueryConfig } from './models';

export class SelectorQueryBuilder<T> {
	public state: QueryState | undefined;

	private _config: SelectorQueryConfig = {};

	private _qb: SelectQueryBuilder<T> | undefined;

	constructor(selectorCB: SelectorCallback, targetClass: new(...args: any[]) => T);
	constructor(selector: string, targetClass: new(...args: any[]) => T);
	constructor(selectors: SelectorQueryConfig, targetClass: new(...args: any[]) => T);
	constructor(config: string | SelectorCallback | SelectorQueryConfig, private _targetClass: new(...args: any[]) => T) {
		if (typeof config === 'object') {
			this._config = config;
		} else {
			this._config = {
				where: config,
				order: config,
				select: config
			};
		}
	}

	public whereQuery(qb: SelectQueryBuilder<T>, tableAlias?: string) {
		this._qb = qb;
		return this._evalQuery('where', tableAlias);
	}
	public selectQuery(qb: SelectQueryBuilder<T>, tableAlias?: string) {
		this._qb = qb;
		return this._evalQuery('select', tableAlias);
	}
	public orderQuery(qb: SelectQueryBuilder<T>, tableAlias?: string) {
		this._qb = qb;
		return this._evalQuery('order', tableAlias);
	}

	private _evalQuery(q: keyof SelectorQueryConfig, tableAlias?: string) {
		const target = this._config[q];
		if (!target) return '';
		if (typeof target === 'function') return target(this._evalState(tableAlias));
		return target;
	}

	private _evalState(tableAlias?: string) {
		const state: Partial<QueryState> = {};
		const targetAlias = this._qb!.expressionMap.aliases.find(a => {
			return a.target === this._targetClass || (<any>a.target).prototype instanceof this._targetClass;
		});
		state.primaryColumn = targetAlias!.metadata.primaryColumns[0].databasePath;
		state.tableAlias = tableAlias || (targetAlias && targetAlias.name);
		this.state = <QueryState>state;
		return this.state;
	}

}

export function SelectorQuery(config: string | SelectorCallback | SelectorQueryConfig) {
	return (target: any, name: string, descriptor: PropertyDescriptor) => {
		const existingMeta = Reflect.getMetadata('query_selector', target.constructor) || {};
		// @ts-ignore
		existingMeta[name] = [config, target.constructor];
		Reflect.defineMetadata('query_selector', existingMeta, target.constructor);
	};
}
