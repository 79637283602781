import { AbstractServiceService, GetConfig, isPaginatedResult } from 'app/_services/abstract-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, map } from 'rxjs/operators';

export class Filterable<E> {

	constructor(service: AbstractServiceService<E>, startingFilter?: GetConfig<E>)
	constructor(private _svc: AbstractServiceService<E>, public startingFilter?: GetConfig<E>) {
		this.startingFilter = Object.assign({page: 0, size: 20}, startingFilter);
	}

	public currentFilterObs = new BehaviorSubject<GetConfig<E>>({});
	public get currentFilter() { return this.currentFilterObs.value; }

	public filteredEntities: Observable<E[]> = this.currentFilterObs.pipe(concatMap(f => {
		return this._svc.getMany(Object.assign({}, this.startingFilter, f)).pipe(map(res => isPaginatedResult<E>(res) ? res.records : res));
	}));

	public filter<K extends keyof GetConfig<E>>(key: K, value: GetConfig<E>[K]) {
		this.currentFilterObs.next(Object.assign(this.currentFilter, {key, value}));
	}

	public onFilter(): Observable<GetConfig<E>>;
	public onFilter<K extends keyof GetConfig<E>>(key: K): Observable<GetConfig<E>[K]>;
	public onFilter<K extends (keyof GetConfig<E>)[]>(key: K): Observable<Pick<GetConfig<E>, K[number]>>;
	public onFilter(key?: string | string[]): any {
		const retObs = this.currentFilterObs;
		if (Array.isArray(key)) retObs.pipe(map((c: GetConfig<E>) => key.reduce((changedKeys, k) => Object.assign(changedKeys, {k: c[k]}), {})));
		if (typeof key === 'string') retObs.pipe(map((c: GetConfig<E>) => c[key]));
		return retObs.pipe(distinctUntilChanged());
	}

	public refreshEntities(resetFilter = false) {
		if (resetFilter) this.currentFilterObs.next({});
	}

}
