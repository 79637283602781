import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '../../_services';
import { Levels } from 'pecms-shared';

@Pipe({
	name: 'role'
})
export class RolePipe implements PipeTransform {
	constructor(private authSvc: AuthService) {}

	transform(value: new(...args: any[]) => any, args?: Levels[]): any {
		if (typeof args === 'string') args = [args];
		args = args || [Levels.ANY];
		return args.every(l => this.authSvc.userSnapshot && this.authSvc.userSnapshot.can(l, value));
	}

}
