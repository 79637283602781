import { Column, CreateDateColumn, Entity, Index, JoinColumn, OneToOne, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';
import { User } from './user';

@Entity('invites')
@Index('userId', ['user', ], {unique: true})
export class Invite {

	@PrimaryGeneratedColumn({
		name: 'id'
	})
	id: number | undefined;

	@OneToOne(type => User, (user: User) => user.invite, { nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'loginId' })
	user: User | undefined;

	@Column({name: 'loginId'})
	get userId() { return this.user && this.user.id ? this.user.id : 0; }
	set userId(val) {
		if (!this.user) this.user = new User();
		this.user.id = val;
	}

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'guid'
	})
	guid: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	public generateGuid() {
		this.guid = (Math.random().toString(36) + '00000000000000000').slice(2, 12);
	}

	constructor(args: {[T in keyof Invite]?: Invite[T]} = {}) {
		this.id = args.id || undefined;
		if (args.userId) this.userId = args.userId;
		this.user = args.user || undefined;
		this.guid = args.guid || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
	}
}
