import { Adder, LeadAdder } from 'pecms-shared';
import { AbstractServiceService, GetConfig, ExpandType } from 'app/_services/abstract-service.service';
import { map } from 'rxjs/operators';
import { QueryStringBuilder } from 'app/_helpers/query-string-builder';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AdderService extends AbstractServiceService<Adder> {
	protected entityClass = Adder;
	protected entityClassName = 'Adder';
}

@Injectable({
	providedIn: 'root'
})
export class LeadAdderService extends AbstractServiceService<LeadAdder> {
	protected entityClass = LeadAdder;
	protected entityClassName = 'LeadAdder';

	public getLeadAdders(leadId: number, config: GetConfig<LeadAdder>) {
		return this.http.get<LeadAdder[]>(`/leads/${leadId}/adders${new QueryStringBuilder(config)}`)
			.pipe(map(as => as.map(a => new LeadAdder(a))));
	}

	public getLeadAdder(leadId: number, adderName: string) {
		return this.http.get(`/leads/${leadId}/adders/${adderName}`).pipe(map(a => new LeadAdder(a)));
	}

	public addAdderToLead(leadId: number, adder: LeadAdder, expand?: ExpandType<LeadAdder>) {
		return this.http.post(`/leads/${leadId}/adders${new QueryStringBuilder({expand})}`, adder).pipe(map(a => new LeadAdder(a)));
	}

	public addAddersToLead(leadId: number, adders: LeadAdder[], expand?: ExpandType<LeadAdder>) {
		return this.http.post<any>(`/leads/${leadId}/adders${new QueryStringBuilder({expand})}`, adders).pipe(map(as => as.map(a => new LeadAdder(a))));
	}

	public updateLeadAdder(leadId: number, adderName: string, updates: Partial<LeadAdder>, expand?: ExpandType<LeadAdder>) {
		return this.http.patch(`/leads/${leadId}/adders/${adderName}${new QueryStringBuilder({expand})}`, updates).pipe(map(a => new LeadAdder(a)));
	}

	public updateLeadAdders(leadId: number, updates: Partial<LeadAdder>[], expand?: ExpandType<LeadAdder>) {
		return this.http.patch<any>(`/leads/${leadId}/adders${new QueryStringBuilder({expand})}`, updates).pipe(map(as => as.map(a => new LeadAdder(a))));
	}

	public deleteLeadAdder(leadId: number, adderName: string) {
		return this.http.delete(`/leads/${leadId}/adders/${adderName}`);
	}

}
