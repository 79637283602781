import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Team } from 'pecms-shared';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class TeamService {

	constructor(
		private http: HttpClient
	) { }

	public all(expand?: string | string[]): Observable<Team[]> {
		const queryParams: {[key: string]: string | string[]} = {};
		if (expand) queryParams.expand = expand;
		return this.http.get<any[]>('/teams', {params: queryParams}).pipe(map((rs) => rs.map(r => new Team(r))));
	}

	public save: (team: Team) => Observable<Team> = (team) => {
		if (team.id) return this.http.patch<Team>(`/teams/${team.id}`, team).pipe(map(r => new Team(r)));
		return this.http.post<Team>(`/teams`, team).pipe(map(r => new Team(r)));
	}

	public delete(id: number): Observable<number>;
	public delete(name: string): Observable<number>;
	public delete(identifier: string | number): Observable<number> {
		return this.http.delete<number>(`/teams/${identifier}`);
	}
}
