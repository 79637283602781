import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import { Design } from './design';
import { User } from './user';
import { Signer } from './signer';

@Entity('signatures')
@Index('signatures_designId_foreign_idx', ['design'])
@Index('signatures_userId_foreign_idx', ['user'])
export class Signature {
	@PrimaryGeneratedColumn()
	id: number | undefined;

	@ManyToOne(type => User, (user: User) => user.signatures, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'loginId'})
	user: User | undefined;

	@ManyToOne(type => Design, (design: Design) => design.signatures, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'designId'})
	design: Design | undefined;

	@ManyToOne(type => Signer, (signature: Signature) => signature.signer, { onDelete: 'RESTRICT', onUpdate: 'CASCADE', cascade: true })
	@JoinColumn({name: 'signerId'})
	signer: Signer | undefined;

	@Column('varchar', {
		nullable: false,
		length: 255,
		name: 'signatureType'
	})
	signatureType: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<Signature> = {}) {
		this.id = args.id || undefined;
		this.user = args.user ? new User(args.user) : undefined;
		this.signer = args.signer ? new Signer(args.signer) : undefined;
		this.design = new Design(args.design || {});
		this.signatureType = args.signatureType || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
	}
}
