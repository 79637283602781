import {MigrationInterface, QueryRunner} from "typeorm";

export class closer1565340350075 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` ADD `closerId` int NULL");
        await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_15c90181db7c1414910c5db44b0` FOREIGN KEY (`closerId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP FOREIGN KEY `FK_15c90181db7c1414910c5db44b0`");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `closerId`");
    }

}
