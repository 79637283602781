import {Entity, PrimaryGeneratedColumn, Column, OneToMany} from 'typeorm';
import {QuickSaleStatusChange} from './quick-sale-status-change';
import {QuickSale} from './quick-sale';

@Entity('quickSaleStatuses')
export class QuickSaleStatus {

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@Column('varchar', {
		nullable: false,
		length: 255,
		name: 'name'
	})
	name: string | undefined;

	@OneToMany(
		type => QuickSaleStatusChange,
		(quickSaleStatusChange: QuickSaleStatusChange) => quickSaleStatusChange.status,
		{ onDelete: 'SET NULL', onUpdate: 'CASCADE' }
	)
	quickSaleStatusChanges: QuickSaleStatusChange[] | undefined;

	@OneToMany(type => QuickSale, (quickSale: QuickSale) => quickSale.status, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	quickSales: QuickSale[] | undefined;

	constructor(args: {[T in keyof QuickSaleStatus]?: QuickSaleStatus[T]} = {}) {
		this.id = typeof args.id === 'number' ? args.id : undefined;
		this.name = args.name || undefined;
		this.quickSaleStatusChanges = args.quickSaleStatusChanges || undefined;
		this.quickSales = args.quickSales || undefined;
	}

}
