import {
	Column,
	CreateDateColumn,
	Entity,
	Index,
	JoinColumn,
	JoinTable,
	ManyToMany,
	ManyToOne,
	OneToMany,
	PrimaryGeneratedColumn,
	UpdateDateColumn
} from 'typeorm';

import { Design } from './design';
import { Lead } from './lead';
import { LoanProvider } from './loan-provider';

@Entity('loanProducts')
export class LoanProduct {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
	})
	@Index()
	public name: any | undefined;

	@Column('decimal', {
		name: 'factor',
		nullable: false,
		precision: 11,
		scale: 10
	})
	public factor: number | undefined;

	@Column('decimal', {
		name: 'noItcFactor',
		nullable: false,
		precision: 11,
		scale: 10
	})
	public noItcFactor: number | undefined;

	@Column('decimal', {
		name: 'savingsFactor',
		nullable: false,
		precision: 12,
		scale: 10
	})
	public savingsFactor: number | undefined;

	@Column('decimal', {
		name: 'downPayment',
		nullable: true,
		precision: 6,
		scale: 5
	})
	public downPayment: number | undefined;

	@Column('boolean', {
		name: 'shown',
		nullable: false
	})
	public shown: boolean | undefined;

	@Column('varchar', {
		name: 'lenderProductId',
		nullable: false
	})
	public lenderProductId: string | undefined;

	@Column('int', {
		name: 'columnOrder',
		nullable: false,
		default: 0
	})
	public columnOrder: number | undefined;

	@ManyToMany(type => Design)
	@JoinTable({
		name: 'hiddenLoans',
		joinColumn: {
			name: 'loanProductId',
			referencedColumnName: 'id'
		},
		inverseJoinColumn: {
			name: 'designId',
			referencedColumnName: 'id'
		}
	})
	hiddenFromDesigns: Design[] | undefined;

	@ManyToOne(type => LoanProvider, (loanProvider: LoanProvider) => loanProvider.loanProducts, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'loanProviderId'})
	loanProvider: LoanProvider | undefined;

	@OneToMany(type => Lead, (lead: Lead) => lead.loanProduct, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	leads: Lead[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LoanProduct> = {}) {
		this.id = args.id;
		this.name = args.name;
		this.factor = args.factor;
		this.noItcFactor = args.noItcFactor;
		this.savingsFactor = args.savingsFactor;
		this.downPayment = args.downPayment;
		this.shown = args.shown;
		this.columnOrder = args.columnOrder;
		this.loanProvider = args.loanProvider ? new LoanProvider(args.loanProvider) : undefined;
		this.lenderProductId = args.lenderProductId;
		this.leads = args.leads ? args.leads.map(l => new Lead(l)) : undefined;
		this.hiddenFromDesigns = args.hiddenFromDesigns ? args.hiddenFromDesigns.map(d => new Design(d)) : undefined;
	}
}
