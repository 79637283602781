import {MigrationInterface, QueryRunner} from 'typeorm';

export class companyUserView1565907394788 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('DROP VIEW IF EXISTS `company_users`');
		await queryRunner.query('CREATE VIEW `company_users` AS select c.id as \'companyId\', t.id as \'teamId\', e.loginId as \'userId\' from `companies` c left outer join `teams` t on c.id = t.companyId left outer join `employees` e on t.id = e.teamId');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('DROP VIEW IF EXISTS `company_users`');
	}

}
