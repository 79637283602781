import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUploadService } from 'app/_services/file-upload.service';

@Injectable()
export class FileInterceptor implements HttpInterceptor {
	constructor(
		private fileUploadSvc: FileUploadService,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(map(event => {
			if (event instanceof HttpResponse) {
				if (event.headers.has('x-amz-signature')) {
					const signatureObj = JSON.parse(event.headers.get('x-amz-signature'));
					for (const fileKey in signatureObj) {
						if (signatureObj.hasOwnProperty(fileKey)) {
							const signature = signatureObj[fileKey];
							this.fileUploadSvc.uploadFile(parseInt(fileKey, 10), signature);
						}
					}
				}
			}
			return event;
		}));
	}
}
