import {MigrationInterface, QueryRunner} from "typeorm";

export class linkDesignUtility1553169740493 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `utilities` CHANGE `id` `id` int NOT NULL ");
        await queryRunner.query("ALTER TABLE `utilities` DROP PRIMARY KEY");
        await queryRunner.query("ALTER TABLE `utilities` DROP COLUMN `id`");
        await queryRunner.query("ALTER TABLE `utilities` CHANGE `name` `name` varchar(255) NOT NULL");
        await queryRunner.query("ALTER TABLE `utilities` ADD PRIMARY KEY (`name`)");
        await queryRunner.query("ALTER TABLE `documentConditions` CHANGE `utilityId` `utilityName` varchar(255) NOT NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD CONSTRAINT `FK_92ea095304f026ac8e2da7f6df6` FOREIGN KEY (`utilityName`) REFERENCES `utilities`(`name`) ON DELETE RESTRICT");
    }
    
    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `designs` DROP FOREIGN KEY `FK_92ea095304f026ac8e2da7f6df6`");
        await queryRunner.query("UPDATE `documentConditions` SET `utilityName` = '0'");
        await queryRunner.query("ALTER TABLE `documentConditions` CHANGE `utilityName` `utilityId` int NOT NULL DEFAULT '0'");
        await queryRunner.query("ALTER TABLE `utilities` DROP PRIMARY KEY");
        await queryRunner.query("ALTER TABLE `utilities` CHANGE `name` `name` varchar(255) NOT NULL DEFAULT ''''");
        await queryRunner.query("ALTER TABLE `utilities` ADD `id` int PRIMARY KEY AUTO_INCREMENT");
    }

}
