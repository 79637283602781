import { Color } from './color';

export type PaletteSwatch = 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100 | 50;

export class Palette {
	private readonly luminanceThreshold = 127;

	private 900: Color;
	private 800: Color;
	private 700: Color;
	private 600: Color;
	private 500: Color;
	private 400: Color;
	private 300: Color;
	private 200: Color;
	private 100: Color;
	private 50: Color;
	// @ts-ignore
	private contrast900: Color;
	// @ts-ignore
	private contrast800: Color;
	// @ts-ignore
	private contrast700: Color;
	// @ts-ignore
	private contrast600: Color;
	// @ts-ignore
	private contrast500: Color;
	// @ts-ignore
	private contrast400: Color;
	// @ts-ignore
	private contrast300: Color;
	// @ts-ignore
	private contrast200: Color;
	// @ts-ignore
	private contrast100: Color;
	// @ts-ignore
	private contrast50: Color;

	constructor(hexCode: string, light?: string, dark?: string)
	constructor(rgb: [number, number, number], light?: [number, number, number], dark?: [number, number, number])
	constructor(color: Color, light?: Color, dark?: Color)
	constructor(c: string | [number, number, number] | Color, l?: string | [number, number, number] | Color, d?: string | [number, number, number] | Color ) {
		if (!(c instanceof Color)) c = new Color(<string>c); // <string> is just to keep typescript from complaining.
		if (!(d instanceof Color)) d = new Color(<string>d);
		if (!(l instanceof Color)) l = new Color(<string>l);
		this[500] = c;
		this.generateVariants(l, d);
	}

	private generateVariants(light = new Color('fff'), dark = new Color('000')) {
		const hsl = this[500].hsl;
		const startingLum = hsl.l;
		const lighterScale = (100 - hsl.l) / 6;
		const darkerScale = hsl.l / 5;
		// console.log(`${hsl.h}° ${hsl.s}% ${hsl.l}%`);
		this[`contrast500`] = this[500].luma < this.luminanceThreshold ? light : dark;
		for (const color of [600, 700, 800, 900]) {
			hsl.l -= darkerScale;
			// @ts-ignore
			this[color] = new Color(hsl);
			// @ts-ignore
			this[`contrast${color}`] = this[color].luma < this.luminanceThreshold ? light : dark;
		}
		hsl.l = startingLum;
		for (const color of [400, 300, 200, 100, 50]) {
			hsl.l += lighterScale;
			// @ts-ignore
			this[color] = new Color(hsl);
			// @ts-ignore
			this[`contrast${color}`] = this[color].luma < this.luminanceThreshold ? light : dark;
		}
	}

	public color(level: PaletteSwatch = 500) {
		return this[level];
	}

	public contrast(level: PaletteSwatch = 500): Color {
		// @ts-ignore
		return this[`contrast${level}`];
	}

	public sassList(set: 'color' | 'contrast') {
		let string = '';
		for (const color of [900, 800, 700, 600, 500, 400, 300, 200, 100, 50]) {
			string += `${color} : ${this[set](<PaletteSwatch>color).hex}`;
			if (color !== 50) {
				string += ', ';
			}
		}
		return string;
	}

}
