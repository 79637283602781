export enum LeadStatuses {
	'New Lead' = 1,
	'Proposal Creation',
	'Proposal Ready',
	'Sold',
	'Missing Docs',
	'Pure Approved',
	'Site Survey',
	'Design',
	'Permit Submitted',
	'Permit Recieved',
	'Install Scheduled',
	'Install Complete',
	'Funded',
	'Inspection Scheduled',
	'Inspection Passed',
	'Inspection Failed',
	'PTO',
	'IC Submitted',
	'Rebate Recieved',
	'Complete',
	'On Hold',
	'Cancelled'
}

export namespace LeadStatuses {
		export const keys: Array<string> = (() => {
			const names = Object.keys(LeadStatuses);
			return names.slice(names.length / 2);
		})();
		export const indexes: Array<number> = (() => {
			const names = Object.keys(LeadStatuses);
			return names.slice(0, (names.length  - 1) / 2).map(i => +i);
		})();
}
