import {
	Directive,
	ElementRef,
	Input,
	Output,
	HostListener,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	OnInit
} from '@angular/core';
import { CountUp, CountUpOptions } from 'countup.js';

@Directive({
	selector: '[countUp]'
})
export class CountUpDirective implements OnChanges, OnInit {

	countUp: CountUp;
	// the value you want to count to
	@Input('countUp') endVal: number;

	@Input() options: CountUpOptions = {};
	@Input() reanimateOnClick = true;
	@Output() complete = new EventEmitter<void>();

	constructor(private el: ElementRef) {}

	ngOnInit() {
		this.countUp = new CountUp(this.el.nativeElement, this.endVal, this.options);
		this.countUp.start();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.endVal && changes.endVal.currentValue && changes.endVal.previousValue !== undefined) {
			this.countUp.update(this.endVal);
		}
	}
}
