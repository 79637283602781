import { Component, OnInit, Input, Inject, OnDestroy, TemplateRef, ContentChildren, QueryList, AfterViewInit } from '@angular/core';
import { STATES_INJECTOR, StateList, StateMeta } from 'app/_helpers/states';
import { AbstractValueAccessor } from 'app/_helpers/form-fields/abstract-value-accessor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, NgControl } from '@angular/forms';
import { MatError } from '@angular/material';

@Component({
	selector: 'pecms-state-selector',
	templateUrl: './state-selector.component.html',
	styleUrls: ['./state-selector.component.scss'],
})
export class StateSelectorComponent extends AbstractValueAccessor<string> implements OnInit, OnDestroy {

	public unsubscribe = new Subject();

	@Input()
	public label = 'State';

	@Input()
	public placeholder: string;

	@Input()
	public hint: string;

	@Input()
	public country = 'us';

	public get displayName() { return this._displayName; }
	private _displayName: string;

	public allStates: {name: string, abbr: string}[] = [];

	public stateLookup: {[key: string]: {name: string, abbr: string}} = {};

	public stateAbbreviations: string[];

	constructor(
		@Inject(STATES_INJECTOR) private _states: StateList,
		control: NgControl
	) {
		super(control);
		const stateMeta: StateMeta = this._states[this.country.toUpperCase()];
		if (stateMeta) {
			this.allStates = stateMeta.all;
			this.stateLookup = stateMeta.lookup;
			this.stateAbbreviations = stateMeta.abbreviations;
		}
	}

	ngOnInit() {
		super.ngOnInit();
	}

	stateCompare = (v1: string, v2: string) => v1 && v2 && v1 === v2.toUpperCase();

	newVal = (v: string) => this.updateModel(v);

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	public updateView(val: string) {
		if (val && this.stateAbbreviations && this.stateAbbreviations.includes(val.toUpperCase())) val = this.stateLookup[val.toLowerCase()].name;
		this._displayName = val;
	}

}
