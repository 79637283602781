import { Index, Entity, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToMany, JoinTable, CreateDateColumn, UpdateDateColumn, JoinColumn, AfterLoad } from 'typeorm';
import { Appointment } from './appointment';
import { Employee } from './employee';
import { Invite } from './invite';
import { QuickSale } from './quick-sale';
import { SurveyResponse } from './survey-response';
import { UserNotification } from './user-notification';
import { Signature } from './signature';
import { SignatureAudit } from './signature-audit';
import { Email } from './email';
import { Role } from './role';
import { Levels } from './rbac-levels.enum';
import { Scopes } from './rbac-scope.enum';
import { Team } from './team';
import { Owner, ProtectedMap, CustomOwnerConfig } from './utilities/protected.utilities';
import { Privileges } from './privileges';
import { Comment } from './comment';
import { LeadStatusChange } from './lead-status-changes';
import { FileMeta } from './file';
import { UserSettings } from './user-settings';
import * as crypto from 'crypto-js/core';
const PBKDF2 = require('crypto-js/pbkdf2');
import 'crypto-js/sha512';
import * as Hex from 'crypto-js/enc-hex';
import * as WordArray from 'crypto-js/lib-typedarrays';
import { LeadUser } from './lead-user';
import { SelectorQuery } from './utilities/custom-query-decorators/selector-query';
import { LoanConfig } from './loan-config';
import { ApiKey } from './api-key';

@Entity('users')
@Index('username', ['username', ], {unique: true})
@Index('users_email', ['email', ])
@Owner()
@Owner(<CustomOwnerConfig<User>>{
	alias: 'salesmenUser.id',
	query: qb => qb.leftJoin(qb.alias + '.leadUsers', 'leads', 'leads.positionName = "Customer"')
		.leftJoin('leadUsers', 'salesmen', 'leads.leadId = salesmen.leadId AND salesmen.positionName <> "Customer"')
		.leftJoin('users', 'salesmenUser', 'salesmenUser.id = salesmen.userId')
})
export class User {

	// @Owner(Scopes.TEAM)
	get team(): Team | undefined {
		if (this.employee) return this.employee.team;
		// if (this.lead && this.lead.salesRep) return this.lead.salesRep.team;
		return undefined;
	}

	get combinedPrivileges() {
		if (!this._combinedPrivileges && this.roles && this.roles.length) this._combinedPrivileges = Role.combinePrivileges(this.roles || []);
		return this._combinedPrivileges || new Privileges();
	}

	constructor(args: Partial<User> = {}) {
		if (args.employee && args.employee.user) delete args.employee.user;
		this.id = args.id || undefined;
		this.firstName = args.firstName || undefined;
		this.lastName = args.lastName || undefined;
		this.nickname = args.nickname || undefined;
		this.email = args.email || undefined;
		this.username = args.username || undefined;
		this.photo = args.photo ? new FileMeta(args.photo) : undefined;
		this.active = args.active || false;
		this.salt = args.salt || undefined;
		this.hash = args.hash || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.appointment = args.appointment || undefined;
		this.appointment2 = args.appointment2 || undefined;
		this.employee = args.employee ? new Employee(args.employee) : undefined;
		this.invite = args.invite || undefined;
		this.quickSales = args.quickSales || undefined;
		this.loanConfigs = args.loanConfigs ? args.loanConfigs.map(a => new LoanConfig(a)) : undefined;
		this.surveyResponse = args.surveyResponse || undefined;
		this.roles = args.roles || undefined;
		this.signatures = args.signatures || undefined;
		this.signatureAudits = args.signatureAudits || undefined;
		this.signatureFile = args.signatureFile ? new FileMeta(args.signatureFile) : undefined;
		this.initialFile = args.initialFile ? new FileMeta(args.initialFile) : undefined;
		this.settings = args.settings ? new UserSettings(args.settings) : undefined;
		this.leadUsers = args.leadUsers ? args.leadUsers.map(lu => new LeadUser(lu)) : undefined;
		this.googleId = args.googleId || undefined;
		this._combinedPrivileges = args.combinedPrivileges || undefined;
		this.comments = args.comments || undefined;
		this.online = !!args.online;

		this.afterLoad();
	}

	@SelectorQuery((qs) => `SELECT u.${qs.primaryColumn}, COALESCE(u.nickname, CONCAT(u.firstName, " ", u.lastName)) as customVal FROM users u`)
	public get displayName() { return this.nickname || this.fullName; }
	@SelectorQuery((qs) => `SELECT u.${qs.primaryColumn}, CONCAT(u.firstName, " ", u.lastName) as customVal FROM users u`)
	public get fullName() { return [this.firstName, this.lastName].filter(n => !!n).join(' '); }

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'firstName'
	})
	firstName: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'lastName'
	})
	lastName: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'nickname'
	})
	nickname: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'email'
	})
	email: string | undefined;

	@Column('varchar', {
		nullable: true,
		unique: true,
		length: 255,
		name: 'username'
	})
	username: string | undefined;

	@Column({
		type: Boolean,
		nullable: true,
		width: 1,
		name: 'active'
	})
	active: boolean;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'salt'
	})
	salt: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'hash'
	})
	hash: string | undefined;

	@OneToOne(type => FileMeta, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'photo' })
	photo: FileMeta | undefined;

	@OneToOne(type => FileMeta, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'signatureFile' })
	signatureFile: FileMeta | undefined;

	@OneToOne(type => FileMeta, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'initialFile' })
	initialFile: FileMeta | undefined;

	@OneToOne(type => UserSettings, (userSettings: UserSettings) => userSettings.user, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	settings: UserSettings | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'googleId',
		unique: true
	})
	googleId: string | undefined;


	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	@OneToOne(type => Appointment, (appointment: Appointment) => appointment.employee, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	appointment: Appointment | undefined;

	@OneToOne(type => Appointment, (appointment: Appointment) => appointment.lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	appointment2: Appointment | undefined;

	@OneToOne(type => Employee, (employee: Employee) => employee.user, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@Owner({scope: Scopes.TEAM, path: ['team']})
	employee: Employee | undefined;

	@OneToOne(type => Invite, (invite: Invite) => invite.user, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	invite: Invite | undefined;

	@OneToMany(type => LeadStatusChange, (leadStatusChange: LeadStatusChange) => leadStatusChange.user, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	leadStatusChanges: LeadStatusChange[] | undefined;

	@OneToMany(type => UserNotification, (userNotification: UserNotification) => userNotification.user, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	notifications: UserNotification[] | undefined;

	@OneToMany(type => QuickSale, (quickSale: QuickSale) => quickSale.createdBy, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	quickSales: QuickSale[] | undefined;

	@OneToMany(type => LoanConfig, (loanConfig: LoanConfig) => loanConfig.user, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	loanConfigs: LoanConfig[] | undefined;

	@OneToMany(type => Comment, (comment: Comment) => comment.createdBy, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'commentId'})
	comments: Comment[] | undefined;

	@OneToMany(type => LeadUser, (leadUser: LeadUser) => leadUser.user, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'userId'})
	leadUsers: LeadUser[] | undefined;

	@OneToOne(type => SurveyResponse, (surveyResponse: SurveyResponse) => surveyResponse.user, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	surveyResponse: SurveyResponse | undefined;

	@ManyToMany(type => Role, role => role.users, { onDelete: 'SET NULL', onUpdate: 'CASCADE', cascade: true })
	@JoinTable({
		name: 'user_roles',
		inverseJoinColumn: {name: 'roleId'},
		joinColumn: {name: 'loginId'}
	})
	roles: Role[] | undefined;

	@OneToMany(type => Signature, (signature: Signature) => signature.user, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	signatures: Signature[] | undefined;

	@OneToMany(type => SignatureAudit, (signatureAudit: SignatureAudit) => signatureAudit.user, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	signatureAudits: SignatureAudit[] | undefined;

	@OneToMany(type => Email, (email: Email) => email.fromUser, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	sentEmails: Email[] | undefined;

	@OneToMany(type => Email, (email: Email) => email.toUser, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	receivedEmails: Email[] | undefined;

	@OneToMany(type => ApiKey, (apiKey: ApiKey) => apiKey.user, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	apiKeys: ApiKey[] | undefined;

	private _combinedPrivileges: Privileges | undefined;

	public online = false;

	@AfterLoad()
	protected afterLoad() {
		if (this.employee && !this.employee.user) this.employee.user = this;
	}

	toJSON() {
		const retObj = Object.assign({}, this, {employee: this.employee ? Object.assign({}, this.employee) : undefined});
		if ((retObj.employee && retObj.employee.user)) delete retObj.employee!.user;
		return retObj;
	}

	public can(level?: Levels, items?: any[]): boolean;
	public can(level?: Levels, item?: any): boolean;
	public can(level: Levels = Levels.ANY, items: any | any[] = []): boolean {
		if (!Array.isArray(items)) items = [items];
		return (<any[]>items).every(i => {
			const typeKey = typeof i === 'string' ? i : (<ProtectedMap & Privileges><unknown>Privileges).__protectedKeyFor<Privileges>(i);
			if (typeof i === 'string') i = (<ProtectedMap & Privileges><unknown>Privileges).__protectedTypeByKey(i);

			if (!typeKey || !i) return true; // not a protected type, so allow access

			// const neededScope = getScope(i, this);
			// @ts-ignore
			const role = this.combinedPrivileges[typeKey];
			return !!(role & level);
		});
	}

	public hasScope(scope?: Scopes, items?: any[]): boolean;
	public hasScope(scope?: Scopes, item?: any): boolean;
	public hasScope(scope: Scopes = Scopes.OWN, items: any | any[] = []): boolean {
		if (!Array.isArray(items)) items = [items];
		return (<any[]>items).every(i => {
			const typeKey = typeof i === 'string' ? i : (<ProtectedMap & Privileges><unknown>Privileges).__protectedKeyFor<Privileges>(i);
			if (typeof i === 'string') i = (<ProtectedMap & Privileges><unknown>Privileges).__protectedTypeByKey(i);

			if (!typeKey || !i) return true; // not a protected type, so allow access

			// const neededScope = getScope(i, this);
			// @ts-ignore
			const role = this.combinedPrivileges[typeKey];
			return !!(role & scope);
		});
	}

	public setPassword(password: string) {
		this.salt = (<any>WordArray.random(16).toString)(Hex);
		this.hash = PBKDF2(password, this.salt, {keySize: 16, iterations: 1000, hasher: crypto.algo.SHA512}).toString(Hex);
	}

	public validatePassword(password: string): boolean {
		const hash = PBKDF2(password, this.salt, {keySize: 16, iterations: 1000, hasher: crypto.algo.SHA512}).toString(Hex);
		return this.hash === hash;
	}
}
