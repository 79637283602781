import {MigrationInterface, QueryRunner} from 'typeorm';

export class additionalCharges1573628568040 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('CREATE TABLE `additionalCharges` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(55) NOT NULL, `amount` decimal(8,3) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `leadId` int NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB', undefined);
		await queryRunner.query('ALTER TABLE `additionalCharges` ADD CONSTRAINT `FK_57bdba3bb3a819aed0d6923faac` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE', undefined);
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `additionalCharges` DROP FOREIGN KEY `FK_57bdba3bb3a819aed0d6923faac`', undefined);
		await queryRunner.query('DROP TABLE `additionalCharges`', undefined);
	}

}
