import { AfterLoad, Column, Entity, JoinColumn, JoinTable, ManyToMany, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn } from 'typeorm';

import { FileMeta } from './file';
import { Inverter } from './inverter';
import { Lead } from './lead';
import { LoanProduct } from './loan-product';
import { Panel } from './panel';
import { Scopes } from './rbac-scope.enum';
import { Signature } from './signature';
import { SignatureAudit } from './signature-audit';
import { Team } from './team';
import { SelectorQuery } from './utilities/custom-query-decorators/selector-query';
import { Owner } from './utilities/protected.utilities';

@Entity('designs')
export class Design {

	@SelectorQuery((qs) => `SELECT d.${qs.primaryColumn}, d.panelCount * p.wattage / 1000 AS customVal FROM designs d JOIN panels p ON p.id = d.panelId`)
	public get systemSize() { return (this.panel && this.panelCount && this.panel.wattage) ? Math.round((this.panelCount * (this.panel.wattage / 1000)) * 100) / 100 : undefined; }

	public get production() {
		if (this.systemSize !== undefined && this.productionFactor) return this.systemSize * this.productionFactor;
		return undefined;
	}

	public get systemWatts() { return (this.systemSize) ? this.systemSize * 1000 : undefined; }

	public get offset() { return (this.production && this.usage) ? (this.production / this.usage) * 100 : undefined; }

	public get trees() { return this.systemSize ? this.systemSize * 713 : undefined; }

	public get gasoline() { return this.systemSize ? this.systemSize * 3130 : undefined; }

	public get electricity() { return this.systemSize ? this.systemSize * 3.8 : undefined; }

	public get waste() { return this.systemSize ? this.systemSize * 9.98 : undefined; }

	public get fiveYearBill() { return this.currentBill ? this.currentBill * 1.188 : undefined; }

	public get tenYearBill() { return this.currentBill ? this.currentBill * 1.41 : undefined; }

	public get twentyYearBill() { return this.currentBill ? this.currentBill * 1.99 : undefined; }

	public get solarBill() { return (this.offset && this.currentBill) ? (1 - (this.offset / 100)) * this.currentBill : undefined; }

	public get doNothingCost() { return this.currentBill ? this.currentBill * 467.39 : undefined; }

	public get financedWattCost() { return this.calculateFinancedWattCost(); }

	public get costPerWatt() {
		if (!this.baseCostPerWatt) return undefined;
		const adjustedCost = this.baseCostPerWatt - (this.lead && this.lead.cash ? .4 : this.lead && this.lead.discountType ? .1 : 0);
		if (!this.lead || !this.lead.adders || !this.lead.adders.length) return adjustedCost;
		return this.lead.adders.reduce((cost, adder) => {
			if (adder.type === 'per-watt' && adder.amount) cost += adder.amount;
			if (adder.type === 'total' && this.systemWatts && adder.amount) cost += adder.amount / this.systemWatts;
			return cost;
		}, adjustedCost);
	}

	public get maxSalesmanAdj() {
		const currentSalesmanAdj = this.lead && this.lead.getAdder('salesmanAdjustment');
		const currentSalesmanAdjVal = currentSalesmanAdj ? currentSalesmanAdj.amount || 0 : 0;
		const currentCPW = (this.costPerWatt || 0) - (currentSalesmanAdjVal);
		return currentCPW > 5 ? currentSalesmanAdjVal : 5 - currentCPW;
	}

	public get minSalesmanAdj() {
		const currentSalesmanAdj = this.lead && this.lead.getAdder('salesmanAdjustment');
		const currentSalesmanAdjVal = currentSalesmanAdj ? currentSalesmanAdj.amount || 0 : 0;
		const currentCPW = (this.costPerWatt || 0) - (currentSalesmanAdjVal);
		return currentCPW < 3 ? currentSalesmanAdjVal : 3 - currentCPW;
	}

	public get financedAmount() { return this.calculateFinancedAmount(); }

	public get financedPlusChargesAmount() {
		const financedSubtotal = this.calculateFinancedAmount();
		if (financedSubtotal == null) return;
		if (!this.lead || !this.lead.additionalCharges || !this.lead.additionalCharges.length) return financedSubtotal;
		return this.lead.additionalCharges.reduce((st, c) => c.amount ? st + +c.amount : st, financedSubtotal);
	}

	public get systemCost() { return (this.costPerWatt && this.systemWatts) ? this.costPerWatt * this.systemWatts : undefined; }

	public get totalCost() { return (this.systemCost) ? this.systemCost + (this.discount || 0) : undefined; }

	public get taxCredit() { return this.calculateTaxCredit(); }

	public get netCost() { return this.calculateNetCost(); }

	public get currentBill() {
		if (this.usage && this.lead && this.lead.utility && this.lead.utility.rate) {
			return ((this.usage * this.lead.utility.rate) / 12) + 10;
		}
	}

	constructor(args: Partial<Design> = {}) {
		this.id = args.id || undefined;
		this.approvedAt = args.approvedAt || undefined;
		this.image1 = args.image1 ? new FileMeta(args.image1) : undefined;
		this.image2 = args.image2 ? new FileMeta(args.image2) : undefined;
		this.usage = args.usage || undefined;
		this.discount = args.discount || 0;
		this.productionFactor = args.productionFactor || 0;
		this.utilityRebate = args.utilityRebate || 0;
		this.panel = args.panel ? new Panel(args.panel) : undefined;
		this.inverter = args.inverter ? new Inverter(args.inverter) : undefined;
		this.maxPanelCount = args.maxPanelCount || undefined;
		this.baseCostPerWatt = args.baseCostPerWatt || undefined;
		this.rebatePercentage = args.rebatePercentage || undefined;
		this.panelCount = args.panelCount || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.contractVersion = args.contractVersion || undefined;
		this.signatures = args.signatures ? args.signatures.map(s => new Signature(s)) : undefined;
		this.hiddenLoans = args.hiddenLoans ? args.hiddenLoans.map(l => new LoanProduct(l)) : undefined;
		this.signatureAudits = args.signatureAudits || undefined;
		this.team = args.team ? new Team(args.team) : undefined;

		this.afterLoad();
	}

	@PrimaryGeneratedColumn({
		name: 'id'
	})
	id: number | undefined;

	@Column('datetime', {
		nullable: true,
		name: 'approvedAt'
	})
	approvedAt: Date | undefined;

	@OneToOne(type => FileMeta, { onDelete: 'RESTRICT', onUpdate: 'CASCADE'})
	@JoinColumn({ name: 'image1' })
	image1: FileMeta | undefined;

	@OneToOne(type => FileMeta, { onDelete: 'RESTRICT', onUpdate: 'CASCADE'})
	@JoinColumn({ name: 'image2' })
	image2: FileMeta | undefined;

	@Column('int', {
		nullable: true,
		name: 'panelCount'
	})
	panelCount: number | undefined;

	@Column('int', {
		nullable: true,
		name: 'maxPanelCount'
	})
	maxPanelCount: number | undefined;

	@ManyToOne(type => Team, { nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'teamId' })
	@Owner({scope: Scopes.TEAM})
	team: Team | undefined;

	@Column('int', {
		nullable: true,
		name: 'usage'
	})
	usage: number | undefined;

	@Column('decimal', {
		name: 'baseCostPerWatt',
		nullable: true,
		precision: 5,
		scale: 3
	})
	public baseCostPerWatt: number | undefined;

	@Column('decimal', {
		nullable: true,
		precision: 10,
		scale: 2,
		name: 'discount',
	})
	discount = 3000;

	@Column('decimal', {
		nullable: true,
		precision: 10,
		scale: 2,
		name: 'productionFactor',
	})
	productionFactor = 3000;

	@Column('decimal', {
		nullable: true,
		precision: 10,
		scale: 2,
		name: 'utilityRebate',
	})
	utilityRebate = 3000;

	@Column('decimal', {
		nullable: true,
		precision: 5,
		scale: 4,
		name: 'rebatePercentage'
	})
	rebatePercentage: number | undefined;

	@ManyToMany(type => LoanProduct, loanProduct => loanProduct.hiddenFromDesigns)
	@JoinTable({
		name: 'hiddenLoans',
		joinColumn: {
			name: 'designId',
			referencedColumnName: 'id'
		},
		inverseJoinColumn: {
			name: 'loanProductId',
			referencedColumnName: 'id'
		}
	})
	hiddenLoans: LoanProduct[] | undefined;

	@ManyToOne(type => Inverter, (inverter: Inverter) => inverter.designs, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'inverterId'})
	inverter: Inverter | undefined;

	@ManyToOne(type => Panel, (panel: Panel) => panel.designs, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'panelId'})
	panel: Panel | undefined;

	@Column('decimal', {
		nullable: true,
		name: 'contractVersion'
	})
	contractVersion: number | undefined;

	@Column('datetime', {
		nullable: false,
		default: () => 'CURRENT_TIMESTAMP',
		name: 'createdAt'
	})
	createdAt: Date | undefined;

	@Column('datetime', {
		nullable: false,
		default: () => 'CURRENT_TIMESTAMP',
		name: 'updatedAt'
	})
	updatedAt: Date | undefined;

	@OneToOne(type => Lead, (lead: Lead) => lead.design, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	@Owner({path: ['leadUsers', 'user']})
	lead: Lead | undefined;

	@OneToMany(type => Signature, (signature: Signature) => signature.design, { onDelete: 'SET NULL', onUpdate: 'CASCADE', cascade: true })
	signatures: Signature[] | undefined;

	@OneToMany(
		type => SignatureAudit,
		(signatureAudit: SignatureAudit) => signatureAudit.design,
		{ onDelete: 'SET NULL', onUpdate: 'CASCADE' }
	)
	signatureAudits: SignatureAudit[] | undefined;

	public calculateFinancedPlusChargesMonthly(lp?: LoanProduct) {
		if (!lp) lp = this.lead!.loanProduct;
		return (this.financedPlusChargesAmount || 0) * (lp ? lp.factor || 1 : 1);
	}

	public getMonthlyPayment(term: number, interest: number, itc = true) {
		const mnthInt = interest / 12;
		const pymtCt = term * 12;
		let monthlyPayment = ((itc ? this.netCost : this.financedAmount) || 0)  * (mnthInt * Math.pow(1 + mnthInt, pymtCt));
		monthlyPayment /= (Math.pow(1 + mnthInt, pymtCt) - 1);
		return Math.round(monthlyPayment * 100) / 100;
	}

	public getSavings(factor: number) {
		return Math.round(((this.doNothingCost || 0) - ((this.financedAmount || 0)) * factor) * 100) / 100;
	}

	public calculateFinancedWattCost(lp: LoanProduct | undefined = this.lead && this.lead.loanProduct) {
		return (this.financedAmount && this.systemWatts) ? this.calculateFinancedAmount(lp)! / this.systemWatts : undefined;
	}

	public calculateFinancedAmount(lp: LoanProduct | undefined = this.lead && this.lead.loanProduct) {
		if (!this.systemCost) return undefined;
		return (this.systemCost - (this.utilityRebate || 0)) * (1 - (lp && lp.downPayment || 0));
	}

	public calculateTaxCredit(lp: LoanProduct | undefined = this.lead && this.lead.loanProduct) {
		if (!(this.financedAmount && this.rebatePercentage)) return;
		return this.calculateFinancedAmount(new LoanProduct({downPayment: 0}))! * this.rebatePercentage;
	}

	public calculateNetCost(lp: LoanProduct | undefined = this.lead && this.lead.loanProduct) {
		if (!(this.taxCredit && this.financedAmount)) return;
		return this.calculateFinancedAmount(lp)! - this.taxCredit;
	}

	@AfterLoad()
	protected afterLoad() {
		if (this.lead && !this!.lead.design) this.lead.design = this;
		if (this.lead && this.lead.leadUsers && this.signatures) {
			for (const sig of this.signatures) {
				if (sig.user && sig.user.id) {
					const targetUser = this.lead.leadUsers.find(lu => lu.user && lu.user.id === sig.user!.id);
					if (targetUser) sig.user = targetUser.user;
				}
			}
		}
	}

	toJSON() {
		if (this.lead && this.lead.design) {
			const retObj = Object.assign({}, this, {lead: Object.assign({}, this.lead)});
			delete retObj.lead.design;
			return retObj;
		}
		return this;
	}

}
