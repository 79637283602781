import {MigrationInterface, QueryRunner} from "typeorm";

export class signatureDocumentUpdates1552906197387 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `signers` (`id` int NOT NULL AUTO_INCREMENT, `firstName` varchar(255) NOT NULL, `lastName` varchar(255) NOT NULL, `email` varchar(255) NOT NULL, `phone` varchar(11) NOT NULL, `signatureFile` varchar(255) NULL, `initialFile` varchar(255) NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("CREATE TABLE `documentFields` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NULL, `description` varchar(1000) NULL, `type` varchar(255) NOT NULL, `config` varchar(2000) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `documentId` int NULL, UNIQUE INDEX `UQ_NAME` (`name`, `documentId`), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("CREATE TABLE `documentEntries` (`id` int NOT NULL AUTO_INCREMENT, `values` varchar(2000) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `documentId` int NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("CREATE TABLE `documents` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NOT NULL, `fileName` varchar(255) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `signatures` ADD `signerId` int NULL");
        await queryRunner.query("ALTER TABLE `documentFields` ADD CONSTRAINT `FK_bcea5a8348548a97cb4ad9cee41` FOREIGN KEY (`documentId`) REFERENCES `documents`(`id`) ON DELETE RESTRICT");
        await queryRunner.query("ALTER TABLE `documentEntries` ADD CONSTRAINT `FK_ea9c781f3768d81920da275c804` FOREIGN KEY (`documentId`) REFERENCES `documents`(`id`) ON DELETE RESTRICT");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `documentEntries` DROP FOREIGN KEY `FK_ea9c781f3768d81920da275c804`");
        await queryRunner.query("ALTER TABLE `documentFields` DROP FOREIGN KEY `FK_bcea5a8348548a97cb4ad9cee41`");
        await queryRunner.query("DROP TABLE `documents`");
        await queryRunner.query("DROP TABLE `documentEntries`");
        await queryRunner.query("DROP INDEX `UQ_NAME` ON `documentFields`");
        await queryRunner.query("DROP TABLE `documentFields`");
        await queryRunner.query("DROP TABLE `signers`");
    }

}
