import {MigrationInterface, QueryRunner} from "typeorm";

export class documentConditions1552908013140 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `documentConditions` (`teamId` int NOT NULL DEFAULT 0, `utilityId` int NOT NULL DEFAULT 0, `documentId` int NOT NULL, PRIMARY KEY (`teamId`, `documentId`, `utilityId`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `documentConditions` ADD CONSTRAINT `FK_d146c466a3e42aa160b8d7a539d` FOREIGN KEY (`documentId`) REFERENCES `documents`(`id`) ON DELETE CASCADE");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `documentConditions` DROP FOREIGN KEY `FK_d146c466a3e42aa160b8d7a539d`");
        await queryRunner.query("DROP TABLE `documentConditions`");
    }

}
