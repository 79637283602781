import { environment as env } from 'environments/environment';
import { Observable, Subscribable, Subscriber, forkJoin, fromEvent, of } from 'rxjs';
import { delayWhen, first, map, startWith, tap } from 'rxjs/operators';

import { Injectable, NgZone } from '@angular/core';

interface ScriptMeta {
	element?: HTMLScriptElement;
	loaded?: boolean;
	name: string;
	src: string;
	cb?: () => Observable<void>;
}

interface ScriptLoadStatus {
	script: string;
	loaded: boolean;
	status: 'Loaded' | 'Already Loaded' | 'Error';
}

export const ScriptStore: ScriptMeta[] = [
	{
		name: 'gapi',
		src: 'https://apis.google.com/js/api.js',
		cb: () => {
			return new Observable(subscriber => {
				if (gapi.client) {
					subscriber.next();
					subscriber.complete();
					return;
				}
				gapi.load('client', () => {
					gapi.client.init(env.gapiConfig).then(() => {
						subscriber.next();
						subscriber.complete();
					});
				});
			});
		}
	},
	{
		name: 'gmaps',
		src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDkPirhyPKOVZTf1AOCCSWTT8Z00QUwYSE'
	}
];

@Injectable({
	providedIn: 'root'
})
export class DynamicScriptLoaderService {
	private scripts: {[key: string]: ScriptMeta} = {};

	constructor(
		private _ngZone: NgZone
	) {
		ScriptStore.forEach((script) => {
			this.scripts[script.name] = {
				name: script.name,
				loaded: false,
				src: script.src,
				cb: script.cb
			};
		});
	}

	load(...scripts: string[]): Subscribable<{[k in (typeof scripts)[number]]: ScriptLoadStatus}> {
		return forkJoin(scripts.map(s => this.loadScript(s))).pipe(map(slss => {
			return slss.reduce((obj, sls) => Object.assign(obj, {[sls.script]: sls}), {});
		}));
	}

	isLoaded(name: string): boolean {
		return this.scripts[name] && !!this.scripts[name].loaded;
	}

	loadScript(name: string): Observable<ScriptLoadStatus> {
		if (this.scripts[name] && !this.scripts[name].loaded) {
			// load script
			if (!this.scripts[name].element) {
				this.scripts[name].element = document.createElement('script');
				this.scripts[name].element.type = 'text/javascript';
				this.scripts[name].element.src = this.scripts[name].src;
				document.getElementsByTagName('head')[0].appendChild(this.scripts[name].element);
			}
			const script = this.scripts[name].element;
			return fromEvent(script, 'load').pipe(
				first(),
				delayWhen((e) => (this.scripts[name].cb ? this.scripts[name].cb() : of(undefined)).pipe(tap(() => {
					this.scripts[name].loaded = true;
				}))),
				map(e => {
					return {script: name, loaded: true, status: 'Loaded'};
				})
			);
		} else {
			return of({ script: name, loaded: true, status: 'Already Loaded' });
		}
	}
}
