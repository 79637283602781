import { Component, OnInit, Input, Output } from '@angular/core';
import { ThemePalette, MatButton } from '@angular/material';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { EventEmitter } from 'events';

@Component({
	selector: 'pecms-status-button',
	templateUrl: './status-button.component.html',
	styleUrls: ['./status-button.component.scss']
})
export class StatusButtonComponent implements OnInit {

	@Input()
	public color: ThemePalette;

	@Input()
	set buttonStyle(val) {
		this.statusStyle = ['regular', 'raised', 'flat', 'stroked'].includes(val.toLowerCase()) ? 'bar' : 'spinner';
		this._buttonStyle = val;
	}
	get buttonStyle() {
		return this._buttonStyle;
	}

	private _buttonStyle: 'regular' | 'raised' | 'flat' | 'stroked' | 'fab' | 'mini-fab' | 'icon' = 'regular';
	public statusStyle: 'spinner' | 'bar' = 'bar';

	@Input()
	public mode: 'indeterminate' | 'determinate' | 'buffer' | 'query' | 'ready' = 'ready';

	public disabled: boolean;

	@Input('disabled')
	private set _disabled(val) {
		this.disabled = coerceBooleanProperty(val);
	}
	private get _disabled() {
		return this.disabled;
	}

	@Input()
	public percentage: number;

	@Input('matButton')
	private set _regularButton(val) {
		if (coerceBooleanProperty(val)) this.buttonStyle = 'regular';
	}
	@Input('mat-raised-button')
	private set _raisedButton(val) {
		if (coerceBooleanProperty(val)) this.buttonStyle = 'raised';
	}
	@Input('mat-flat-button')
	private set _flatButton(val) {
		if (coerceBooleanProperty(val)) this.buttonStyle = 'flat';
	}
	@Input('mat-stroked-button')
	private set _strokedButton(val) {
		if (coerceBooleanProperty(val)) this.buttonStyle = 'stroked';
	}
	@Input('mat-icon-button')
	private set _iconButton(val) {
		if (coerceBooleanProperty(val)) this.buttonStyle = 'icon';
	}
	@Input('mat-fab')
	private set _fabButton(val) {
		if (coerceBooleanProperty(val)) this.buttonStyle = 'fab';
	}
	@Input('mat-mini-fab')
	private set _miniFabButton(val) {
		if (coerceBooleanProperty(val)) this.buttonStyle = 'mini-fab';
	}

	constructor() { }

	ngOnInit() {
	}

}
