import {MigrationInterface, QueryRunner} from 'typeorm';

export class LoanConfigUpdate1580083108053 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('CREATE TABLE `loanConfigs` (`id` int NOT NULL AUTO_INCREMENT, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `leadId` int NULL, `userId` int NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB', undefined);
		await queryRunner.query('CREATE TABLE `loanConfigSets` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `loanConfigId` int NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB', undefined);
		await queryRunner.query('CREATE TABLE `loanConfigColumns` (`id` int NOT NULL AUTO_INCREMENT, `order` int NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `LoanProductId` int NULL, `loanConfigSetId` int NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigs` ADD CONSTRAINT `FK_2b034e9969c4959319d6bafde28` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigs` ADD CONSTRAINT `FK_c80bbfb1a9d4d99e1c17d7f00d5` FOREIGN KEY (`userId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigSets` ADD CONSTRAINT `FK_d077ff0ee0f6f7e2ab00d193233` FOREIGN KEY (`loanConfigId`) REFERENCES `loanConfigs`(`id`) ON DELETE CASCADE ON UPDATE CASCADE', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigColumns` ADD CONSTRAINT `FK_24144b8abbb9de994fc4592dd95` FOREIGN KEY (`LoanProductId`) REFERENCES `loanProducts`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigColumns` ADD CONSTRAINT `FK_59913621b9f452a515a0b834623` FOREIGN KEY (`loanConfigSetId`) REFERENCES `loanConfigSets`(`id`) ON DELETE CASCADE ON UPDATE CASCADE', undefined);
		await queryRunner.query('ALTER TABLE `loanProducts` ADD `columnOrder` int NOT NULL DEFAULT 0', undefined);
		await queryRunner.query('ALTER TABLE `panels` ADD `shown` tinyint NOT NULL DEFAULT 1', undefined);
		await queryRunner.query('CREATE UNIQUE INDEX `IDX_535c6e731f192c2f908d380eef` ON `loanConfigs` (`leadId`, `userId`)', undefined);
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `loanConfigColumns` DROP FOREIGN KEY `FK_59913621b9f452a515a0b834623`', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigColumns` DROP FOREIGN KEY `FK_24144b8abbb9de994fc4592dd95`', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigSets` DROP FOREIGN KEY `FK_d077ff0ee0f6f7e2ab00d193233`', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigs` DROP FOREIGN KEY `FK_c80bbfb1a9d4d99e1c17d7f00d5`', undefined);
		await queryRunner.query('ALTER TABLE `loanConfigs` DROP FOREIGN KEY `FK_2b034e9969c4959319d6bafde28`', undefined);
		await queryRunner.query('ALTER TABLE `panels` DROP COLUMN `shown`', undefined);
		await queryRunner.query('ALTER TABLE `loanProducts` DROP COLUMN `columnOrder`', undefined);
		await queryRunner.query('DROP INDEX `IDX_535c6e731f192c2f908d380eef` ON `loanConfigs`', undefined);
		await queryRunner.query('DROP TABLE `loanConfigColumns`', undefined);
		await queryRunner.query('DROP TABLE `loanConfigSets`', undefined);
		await queryRunner.query('DROP TABLE `loanConfigs`', undefined);
	}

}
