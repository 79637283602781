import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import { User } from './user';

@Entity('appointments')
@Index('employeeId', ['employee', ], {unique: true})
@Index('leadId', ['lead', ], {unique: true})
export class Appointment {

	@PrimaryGeneratedColumn({
		name: 'id'
	})
	id: number | undefined;

	@OneToOne(type => User, (user: User) => user.appointment, { nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'employeeId' })
	employee: User | undefined;

	@OneToOne(type => User, (user: User) => user.appointment2, { nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'leadId' })
	lead: User | undefined;

	@Column('datetime', {
		nullable: true,
		name: 'date'
	})
	date: Date | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'appointmentType'
	})
	appointmentType: string | undefined;

	@Column('int', {
		nullable: true,
		name: 'length'
	})
	length: number | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: {[T in keyof Appointment]?: Appointment[T]} = {}) {
		this.id = args.id || undefined;
		this.employee = args.employee || undefined;
		this.lead = args.lead || undefined;
		this.date = args.date || undefined;
		this.appointmentType = args.appointmentType || undefined;
		this.length = args.length || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
	}

}
