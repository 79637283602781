import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, OneToOne, JoinColumn } from 'typeorm';
import { Lead } from './lead';

@Entity('proposalSettings')
export class ProposalSettings {
	public static default: ProposalSettings = new ProposalSettings({language: 'en', showCurrentBill: false});

	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@OneToOne(type => Lead, (lead: Lead) => lead.settings, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	lead: Lead | undefined;

	@Column('tinyint', {
		name: 'showCurrentBill',
		nullable: false,
		default: 0
	})
	public showCurrentBill: boolean;

	@Column('varchar', {
		name: 'language',
		nullable: false,
		default: 'en'
	})
	public language: string;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<ProposalSettings> = {}) {
		this.id = args.id;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.showCurrentBill = !!args.showCurrentBill || false;
		this.language = args.language || 'en';
		this.createdAt = args.createdAt;
		this.updatedAt = args.updatedAt;
	}
}
