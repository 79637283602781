import { Entity, PrimaryGeneratedColumn, Column, ManyToMany, JoinTable, OneToMany, PrimaryColumn } from 'typeorm';
import {Document} from './document';
import { Design } from './design';
import { Lead } from './lead';

@Entity('utilities')
export class Utility {

	@PrimaryColumn('varchar', {
		length: 255,
		name: 'name',
		unique: true
	})
	name: string | undefined;

	@Column('decimal', {
		name: 'baseCostPerWatt',
		nullable: false,
		scale: 3,
		precision: 5,
		default: 4.0
		// length: 255
	})
	public baseCostPerWatt: number | undefined;

	@Column('decimal', {
		name: 'rebate',
		nullable: false,
		scale: 3,
		precision: 9,
		default: 0
	})
	public rebate: number | undefined;

	@Column('decimal', {
		name: 'rate',
		nullable: false,
		scale: 6,
		precision: 8
	})
	public rate: any | undefined;

	@ManyToMany(type => Document, document => document.utilities)
	@JoinTable({
		name: 'documentConditions',
		joinColumn: {
			name: 'utilityName',
			referencedColumnName: 'name'
		},
		inverseJoinColumn: {
			name: 'documentId',
			referencedColumnName: 'id'
		}
	})
	documents: Document[] | undefined;

	@OneToMany(type => Lead, (lead: Lead) => lead.utility, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	leads: Lead[] | undefined;

	public get hasDocs(): boolean {
		return this.documents ? !!this.documents.length : false;
	}

	constructor(args: Partial<Utility> = {}) {
		this.name = args.name || undefined;
		this.documents = args.documents ? args.documents.map(d => new Document(d)) : undefined;
		this.leads = args.leads ? args.leads.map(l => new Lead(l)) : undefined;
		this.baseCostPerWatt = args.baseCostPerWatt;
		this.rebate = args.rebate;
		this.rate = args.rate;
	}
}
