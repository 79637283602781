import {MigrationInterface, QueryRunner} from "typeorm";

export class savingsFactor1553948817698 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `loanProducts` ADD `savingsFactor` decimal(12,10) NOT NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `loanProducts` DROP COLUMN `savingsFactor`");
    }

}
