import { Column, CreateDateColumn, Entity, JoinColumn, OneToMany, OneToOne, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';

import { FileMeta } from './file';
import { Signature } from './signature';

@Entity('signers')
export class Signer {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'firstName',
		nullable: false,
	})
	public firstName: any | undefined;

	@Column('varchar', {
		name: 'lastName',
		nullable: false,
	})
	public lastName: any | undefined;

	@Column('varchar', {
		name: 'email',
		nullable: false,
	})
	public email: any | undefined;

	@Column('varchar', {
		name: 'phone',
		nullable: false,
		length: 11
	})
	public phone: any | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'addr1'
	})
	addr1: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'addr2'
	})
	addr2: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'city'
	})
	city: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'state'
	})
	state: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'zip'
	})
	zip: string | undefined;

	@OneToOne(type => FileMeta, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'signatureFile' })
	signatureFile: FileMeta | undefined;

	@OneToOne(type => FileMeta, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'initialFile' })
	initialFile: FileMeta | undefined;

	public get fullName() { return [this.firstName, this.lastName].filter(n => !!n).join(' '); }

	@OneToMany(type => Signature, (signature: Signature) => signature.signer, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'signatureId'})
	signatures: Signature[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<Signer> = {}) {
		this.id = args.id;
		this.firstName = args.firstName;
		this.lastName = args.lastName;
		this.email = args.email;
		this.phone = args.phone;
		this.addr1 = args.addr1;
		this.addr2 = args.addr2;
		this.city = args.city;
		this.state = args.state;
		this.zip = args.zip;
		this.signatureFile = args.signatureFile ? new FileMeta(args.signatureFile) : undefined;
		this.initialFile = args.initialFile ? new FileMeta(args.initialFile) : undefined;
	}
}
