import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import { User } from './user';
import {SurveyQuestion} from './survey-question';

@Entity('surveyResponses')
@Index('id', ['id', ], {unique: true})
@Index('questionId', ['question', ])
export class SurveyResponse {

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@OneToOne(type => User, (user: User) => user.surveyResponse, { primary: true, nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'loginId' })
	user: User | undefined;

	@OneToOne(
		type => SurveyQuestion,
		(surveyQuestion: SurveyQuestion) => surveyQuestion.surveyResponses,
		{ primary: true, nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' }
	)
	@JoinColumn({ name: 'questionId' })
	question: SurveyQuestion | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'response'
	})
	response: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: {[T in keyof SurveyResponse]?: SurveyResponse[T]} = {}) {
		this.id = args.id || undefined;
		this.user = args.user || undefined;
		this.question = args.question || undefined;
		this.response = args.response || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
	}
}
