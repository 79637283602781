import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject, Observable, Subscription, } from 'rxjs';
import { LeadStatus, Lead } from 'pecms-shared';

import { WebsocketService } from 'app/_services/websocket.service';
import { AlertService } from 'app/_services/alert.service';
import { LeadService } from 'app/_services/lead.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	messages: Subject<any>;

	private _statusCount: BehaviorSubject<number> = new BehaviorSubject(0);
	public importantStatuses: BehaviorSubject<number[]> = new BehaviorSubject([]);

	public leadChanges = new Subject();
	public leadUpdate(leadId: number): Observable<Lead> {
		return this._websocketSvc.leadChange(leadId);
	}

	constructor(
		private _websocketSvc: WebsocketService,
		private _alertSvc: AlertService,
		private _statusSvc: LeadService
	) {
		// this._websocketSvc.gainedStatuses(this._getStartingStatuses).subscribe(this._handleNewStatus.bind(this));
		// this._websocketSvc.lostStatuses().subscribe(this._handleOldStatus.bind(this));
	}


	private _incrementStatus(status: LeadStatus, amount = 1) {
		const newVal = this._statusCount.getValue() + amount;
		if (newVal >= 0) {
			this._statusCount.next(newVal);
		}
	}

	private _addImportantStatus(id: number) {
		const currentVal = this.importantStatuses.getValue();
		if (currentVal.indexOf(id) !== -1) return;
		currentVal.push(id);
		this.importantStatuses.next(currentVal);
	}

	private _removeImportantStatus(id: number) {
		const currentVal = this.importantStatuses.getValue();
		const i = currentVal.indexOf(id);
		if (i === -1) return;
		currentVal.splice(i, 1);
		this.importantStatuses.next(currentVal);
	}

	private _getStartingStatuses = () => {
		this._statusSvc.watchedLeads.subscribe(lds => {
			this._statusCount.next(lds.length);
			this.importantStatuses.next(lds.map(ld => ld.id));
		});
	}
	private _handleNewStatus = (s: {leadId: number, statusId: LeadStatus, message: string}) => {
		this._incrementStatus(s.statusId);
		this._addImportantStatus(s.leadId);
		this.leadChanges.next(['add', s.leadId]);
		this._alertSvc.success({title: s.message});
	}
	private _handleOldStatus = (s: {leadId: number, statusId: LeadStatus, message: string}) => {
		this._removeImportantStatus(s.leadId);
		this._incrementStatus(s.statusId, -1);
	}

	public get statusCount() {
		return this._statusCount;
	}
}
