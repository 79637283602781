import {MigrationInterface, QueryRunner} from "typeorm";

export class addSignatureSupport1551685611451 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `signatures` (`id` int NOT NULL AUTO_INCREMENT, `signatureType` varchar(255) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `loginId` int NULL, `designId` int NULL, INDEX `signatures_userId_foreign_idx` (`loginId`), INDEX `signatures_designId_foreign_idx` (`designId`), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("CREATE TABLE `signatureAudits` (`id` int NOT NULL AUTO_INCREMENT, `referrer` varchar(255) NOT NULL, `actionType` varchar(255) NOT NULL, `ipAddress` varchar(15) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `loginId` int NULL, `designId` int NULL, INDEX `signatureAudits_userId_foreign_idx` (`loginId`), INDEX `signatureAudits_designId_foreign_idx` (`designId`), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `leads` ADD `digitalBusinessAgreementDate` date NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `contractVersion` decimal NULL");
        await queryRunner.query("ALTER TABLE `signatures` ADD CONSTRAINT `FK_34b7685f212c9024d84a07c925a` FOREIGN KEY (`loginId`) REFERENCES `users`(`id`) ON DELETE SET NULL");
        await queryRunner.query("ALTER TABLE `signatures` ADD CONSTRAINT `FK_d37e433343aff20a68f6d961381` FOREIGN KEY (`designId`) REFERENCES `designs`(`id`) ON DELETE SET NULL");
        await queryRunner.query("ALTER TABLE `signatureAudits` ADD CONSTRAINT `FK_5ca272c3d371e8f9b8e2b98d349` FOREIGN KEY (`loginId`) REFERENCES `users`(`id`) ON DELETE SET NULL");
        await queryRunner.query("ALTER TABLE `signatureAudits` ADD CONSTRAINT `FK_99a73488bec0ea24d63aec72ed8` FOREIGN KEY (`designId`) REFERENCES `designs`(`id`) ON DELETE SET NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `signatureAudits` DROP FOREIGN KEY `FK_99a73488bec0ea24d63aec72ed8`");
        await queryRunner.query("ALTER TABLE `signatureAudits` DROP FOREIGN KEY `FK_5ca272c3d371e8f9b8e2b98d349`");
        await queryRunner.query("ALTER TABLE `signatures` DROP FOREIGN KEY `FK_d37e433343aff20a68f6d961381`");
        await queryRunner.query("ALTER TABLE `signatures` DROP FOREIGN KEY `FK_34b7685f212c9024d84a07c925a`");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `digitalBusinessAgreementDate`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `contractVersion`");
        await queryRunner.query("DROP INDEX `signatureAudits_designId_foreign_idx` ON `signatureAudits`");
        await queryRunner.query("DROP INDEX `signatureAudits_userId_foreign_idx` ON `signatureAudits`");
        await queryRunner.query("DROP TABLE `signatureAudits`");
        await queryRunner.query("DROP INDEX `signatures_designId_foreign_idx` ON `signatures`");
        await queryRunner.query("DROP INDEX `signatures_userId_foreign_idx` ON `signatures`");
        await queryRunner.query("DROP TABLE `signatures`");
    }

}
