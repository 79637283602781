import { Palette, PaletteSwatch } from './palette';
import { Color, RGB, HSL } from './color';

export type ColorReference = Palette | Color | string | [number, number, number] | RGB | HSL | null;

export interface ThemePalettes {
	primary: Palette;
	secondary?: Palette;
	highlight?: Palette;
	accent?: Palette;
	success?: Palette;
	info?: Palette;
	warn?: Palette;
	danger?: Palette;
	lightContrast?: Palette;
	darkContrast?: Palette;
}

export class Theme {
	private _themePalettes: ThemePalettes;

	public get palettes() {
		return this._themePalettes;
	}

	public get primary(): string {
		return this._themePalettes.primary.color(500).hex;
	}
	public get secondary(): string | undefined {
	return this._themePalettes.secondary ? this._themePalettes.secondary.color(500).hex : undefined;
	}
	public get highlight(): string | undefined {
	return this._themePalettes.highlight ? this._themePalettes.highlight.color(500).hex : undefined;
	}
	public get accent(): string | undefined {
	return this._themePalettes.accent ? this._themePalettes.accent.color(500).hex : undefined;
	}
	public get success(): string | undefined {
	return this._themePalettes.success ? this._themePalettes.success.color(500).hex : undefined;
	}
	public get info(): string | undefined {
	return this._themePalettes.info ? this._themePalettes.info.color(500).hex : undefined;
	}
	public get warn(): string | undefined {
	return this._themePalettes.warn ? this._themePalettes.warn.color(500).hex : undefined;
	}
	public get danger(): string | undefined {
	return this._themePalettes.danger ? this._themePalettes.danger.color(500).hex : undefined;
	}
	public get lightContrast(): string | undefined {
	return this._themePalettes.lightContrast ? this._themePalettes.lightContrast.color(500).hex : undefined;
	}
	public get darkContrast(): string | undefined {
	return this._themePalettes.darkContrast ? this._themePalettes.darkContrast.color(500).hex : undefined;
	}

	constructor(
		primary?: ColorReference,
		secondary?: ColorReference,
		highlight?: ColorReference,
		accent?: ColorReference,
		success?: ColorReference,
		info?: ColorReference,
		warn?: ColorReference,
		danger?: ColorReference,
		lightContrast?: ColorReference,
		darkContrast?: ColorReference,
	) {
		this._themePalettes = {
			primary: this.colorRefToPalette(primary || '03547f'),
		};
		this._themePalettes.secondary = secondary ? this.colorRefToPalette(secondary, lightContrast, darkContrast) : new Palette(this.getColor('primary')!.compliment, this.getColor('lightContrast'), this.getColor('darkContrast')),
		this._themePalettes.highlight = highlight ? this.colorRefToPalette(highlight, lightContrast, darkContrast) : this._themePalettes.secondary;
		this._themePalettes.accent = accent ? this.colorRefToPalette(accent, lightContrast, darkContrast) : this._themePalettes.highlight;
		this._themePalettes.success = success ? this.colorRefToPalette(success, lightContrast, darkContrast) : this._themePalettes.highlight;
		this._themePalettes.warn = warn ? this.colorRefToPalette(warn, lightContrast, darkContrast) : this._themePalettes.highlight;
		this._themePalettes.danger = danger ? this.colorRefToPalette(danger, lightContrast, darkContrast) : this._themePalettes.highlight;
		this._themePalettes.info = info ? this.colorRefToPalette(info, lightContrast, darkContrast) : new Palette('DDD');
		this._themePalettes.lightContrast = lightContrast ? this.colorRefToPalette(lightContrast, lightContrast, darkContrast) : new Palette('FFF');
		this._themePalettes.darkContrast = darkContrast ? this.colorRefToPalette(darkContrast, lightContrast, darkContrast) : new Palette('000');
	}

	private colorRefToPalette(ref: ColorReference, light?: any, dark?: any): Palette {
		if (ref instanceof Palette) ref = ref.color(500);
		if (!(ref instanceof Color)) ref = new Color(<any>ref);
		return new Palette(<Color>ref, light, dark);
	}

	getColor(palette: keyof ThemePalettes, swatch?: PaletteSwatch) {
		// @ts-ignore
		return this._themePalettes[palette] ? this._themePalettes[palette].color(swatch) : undefined;
	}

	getContrast(palette: keyof ThemePalettes, swatch?: PaletteSwatch) {
		// @ts-ignore
		return this._themePalettes[palette] ? this._themePalettes[palette].contrast(swatch) : undefined;
	}

	buildScssPalette(key: keyof ThemePalettes): string {
		return `$pecms-${key} : ( ${(<Palette>this._themePalettes[key]).sassList('color')},
		contrast : ( ${(<Palette>this._themePalettes[key]).sassList('contrast')} ) );
		.pecms-${key}-bg {background-color: ${(<Palette>this._themePalettes[key]).color().hex}!important; }
		.pecms-${key}-stroke {stroke: ${(<Palette>this._themePalettes[key]).color().hex}!important; }
		.pecms-${key}-fg {color: ${(<Palette>this._themePalettes[key]).color().hex}!important; }
		.pecms-${key}-contrast-fg {color: ${(<Palette>this._themePalettes[key]).contrast().hex}!important; }
		.pecms-${key}-contrast-bg {background-color: ${(<Palette>this._themePalettes[key]).contrast().hex}!important; }
		.pecms-${key}-hover-fg:hover {color: ${(<Palette>this._themePalettes[key]).color().hex}!important; }
		.pecms-${key}-hover-bg:hover {background-color: ${(<Palette>this._themePalettes[key]).color().hex}!important; }
		.pecms-${key}-hover-contrast-fg:hover {color: ${(<Palette>this._themePalettes[key]).contrast().hex}!important; }
		.pecms-${key}-hover-contrast-bg:hover {background-color: ${(<Palette>this._themePalettes[key]).contrast().hex}!important; }
		.pecms-${key}-border {border-color: ${(<Palette>this._themePalettes[key]).color().hex}!important; }
		`;
	}
}

export const siteTheme = new Theme('03547F', '022A40', '00B0E5', 'E8E000', '037f2e', null, '7f6a03', '7f032c');

export function isThemePaletteKey(val: any): val is keyof ThemePalettes {
	if (typeof val === 'string' && val.endsWith('.contrast')) val = val.slice(0, -9)[0];
	return Object.keys(Theme.prototype).indexOf(val) !== 0;
}
