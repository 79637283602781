import {MigrationInterface, QueryRunner} from "typeorm";

export class addEnvironmentVsMoney1550693745173 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` ADD `environmentVsMoney` int NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `environmentVsMoney`");
    }

}
