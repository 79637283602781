import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'id'
})
export class IdPipe implements PipeTransform {

	public static makeId(value: any) {
		return String(value).padStart(5, '0');
	}

	transform(value: number): any {
		return IdPipe.makeId(value);
	}

}
