export enum AccessScopes {
	COMPANY = 128,
	REGION = 192,
	TEAM = 224,
	OWN = 240
}

export enum Scopes {
	COMPANY = 128,
	REGION = 64,
	TEAM = 32,
	OWN = 16
}
