import {MigrationInterface, QueryRunner} from "typeorm";

export class loanProviderTable1553528729579 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `loanProviders` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `loanProducts` DROP COLUMN `provider`");
        await queryRunner.query("ALTER TABLE `loanProducts` ADD `loanProviderId` int NULL");
        await queryRunner.query("ALTER TABLE `loanProducts` ADD CONSTRAINT `FK_56be543b8d92aa40053e761eb7e` FOREIGN KEY (`loanProviderId`) REFERENCES `loanProviders`(`id`) ON DELETE RESTRICT");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `loanProducts` DROP FOREIGN KEY `FK_56be543b8d92aa40053e761eb7e`");
        await queryRunner.query("ALTER TABLE `loanProducts` DROP COLUMN `loanProviderId`");
        await queryRunner.query("ALTER TABLE `loanProducts` ADD `provider` varchar(255) NOT NULL");
        await queryRunner.query("DROP TABLE `loanProviders`");
    }

}
