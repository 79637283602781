import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, JoinColumn, ManyToOne } from 'typeorm';
import { User } from './user';

@Entity('emails')
export class Email {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'subject',
		nullable: false,
	})
	public subject: string | undefined;

	@Column('varchar', {
		name: 'template',
		nullable: false,
	})
	public template: string | undefined;

	@Column('json', {
		name: 'replacements',
		nullable: true,
	})
	public replacements: {[key: string]: any} | undefined;

	@ManyToOne(type => User, (user: User) => user.sentEmails, { onDelete: 'RESTRICT', onUpdate: 'CASCADE', nullable: true })
	@JoinColumn({name: 'fromUserId'})
	fromUser: User | undefined;

	@ManyToOne(type => User, (user: User) => user.receivedEmails, { onDelete: 'RESTRICT', onUpdate: 'CASCADE', nullable: true })
	@JoinColumn({name: 'toUserId'})
	toUser: User | undefined;

	@Column('varchar', {
		name: 'fromAddress',
		nullable: true,
	})
	public fromAddress: string | undefined;

	@Column('varchar', {
		name: 'toAddress',
		nullable: true,
	})
	public toAddress: string | undefined;

	@Column('varchar', {
		name: 'status',
		nullable: false,
		default: 'new'
	})
	public status: 'new' | 'delivered' | 'error' | 'failed' | 'opened';

	@Column('datetime', {
		name: 'sendAt',
		nullable: true,
	})
	public sendAt: Date | undefined;

	@Column('datetime', {
		name: 'lastAttempt',
		nullable: true,
	})
	public lastAttempt: Date | undefined;

	@Column('datetime', {
		name: 'sentAt',
		nullable: true,
	})
	public sentAt: Date | undefined;

	@Column('datetime', {
		name: 'openedAt',
		nullable: true,
	})
	public openedAt: Date | undefined;

	@Column('varchar', {
		name: 'openedIP',
		nullable: true,
	})
	public openedIP: string | undefined;

	@Column('int', {
		name: 'failCount',
		nullable: true,
		default: 0
	})
	public failCount: number | undefined;

	@Column('longtext', {
		name: 'failReason',
		nullable: true,
	})
	public failReason: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	get toEmail() { return (this.toUser && this.toUser.email) || this.toAddress || undefined; }
	get fromEmail() { return (this.fromUser && this.fromUser.email) || this.fromAddress || undefined; }

	constructor(args: Partial<Email> = {}) {
		this.id = args.id;
		this.subject = args.subject;
		this.template = args.template;
		this.replacements = args.replacements;
		this.fromUser = args.fromUser ? new User(args.fromUser) : undefined;
		this.toUser = args.toUser ? new User(args.toUser) : undefined;
		this.fromAddress = args.fromAddress;
		this.status = args.status || 'new';
		this.sendAt = args.sendAt;
		this.toAddress = args.toAddress;
		this.lastAttempt = args.lastAttempt;
		this.sentAt = args.sentAt;
		this.openedAt = args.openedAt;
		this.openedIP = args.openedIP;
		this.failCount = args.failCount;
		this.failReason = args.failReason;
	}
}
