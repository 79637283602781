import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, FormControl, Validators, ValidatorFn, Form } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Document, DocumentEntry, DocumentField } from 'pecms-shared';

@Injectable({
	providedIn: 'root'
})
export class FormHelperService {

	constructor() {	}

	public static primitiveTypes: (new(...args: any[]) => any)[] = [Date];
	private _primitiveKeys: string[] = [];
	private _primitiveTypes: (new(...args: any[]) => any)[] = [];
	private _trackedForms: [AbstractControl, FormChangeTracker][] = [];

	public static isPrimitive(val: any, types = this.primitiveTypes) {
		return typeof val !== 'object' || val === null || types.some(t => val instanceof t);
	}
	public static controlTypeMatchesValueType(form: AbstractControl, value: any) {
		const arrayMatch = Array.isArray(value) && form instanceof FormArray;
		const objMatch = !this.isPrimitive(value) && form instanceof FormGroup;
		const primitiveMatch = this.isPrimitive(value) && form instanceof FormControl;

		return arrayMatch || objMatch || primitiveMatch;
	}
	private _isPrimitive(obj: any, key: string) {
		return FormHelperService.isPrimitive(obj, this._primitiveTypes) || (this._primitiveKeys.length && this._primitiveKeys.includes(key));
	}

	createOrUpdateForm(obj: string | number | Date, form?: FormControl, config?: PatchValConfig): FormControl;
	createOrUpdateForm(obj: Array<any>, form?: FormArray, config?: PatchValConfig): FormArray;
	createOrUpdateForm(obj: object, form?: FormGroup, config?: PatchValConfig): FormGroup;
	createOrUpdateForm(obj: any, form?: AbstractControl, config: PatchValConfig = {}): AbstractControl {
		return this._createOrUpdateForm(obj, form, config);
	}

	private _createOrUpdateForm(obj: any, form?: AbstractControl, config: PatchValConfig = {}, keys: string[] = []): AbstractControl {
		this._primitiveKeys = config.primitiveKeys || [];
		this._primitiveTypes = FormHelperService.primitiveTypes.concat(config.primitiveTypes || []);

		if (!form || !(form instanceof AbstractControl) || !FormHelperService.controlTypeMatchesValueType(form, obj)) {
			if (form && form.parent) {
				const parentKey = Object.entries(form.parent.controls).find(e => e[1] === form)![0];
				(<FormGroup>form.parent).setControl(<any>parentKey, this._createOrUpdateForm(obj, undefined, config, keys));
				return <AbstractControl>form.parent.get(parentKey);
			} else {
				if (this._isPrimitive(obj, keys.join('.'))) {
					form = new FormControl(null);
				} else if (Array.isArray(obj)) {
					form = new FormArray([]);
				} else {
					form = new FormGroup({});
				}
			}
		}

		if (!this._isPrimitive(obj, keys.join('.')) && this._forceFormGroupOrArray(form)) {
			let formKeys = this._isFormGroupOrFormArray(form) ? Object.keys(form.controls) : [];
			for (const key of Object.keys(obj)) { // prop is either an array index or object
				formKeys = formKeys.filter(fk => fk !== key);
				const targetControl = form.get(key);
				if (targetControl) {
					if (FormHelperService.controlTypeMatchesValueType(targetControl, obj[key])) { // If val and control types match
						this._createOrUpdateForm(obj[key], form.get(key) as any, config, [...keys, key]);
					} else {
						const newControl = this._createOrUpdateForm(obj[key], undefined, config, [...keys, key]);
						(<FormGroup>form).setControl(<string & number>key, newControl);
					}
				} else {
					const controlToAdd = this._isPrimitive(obj[key], [...keys, key].join('.')) ?
						new FormControl(obj[key]) :
						this._createOrUpdateForm(obj[key] || null, undefined, config, [...keys, key]);

					if (Array.isArray(obj)) {
						(<FormArray>form).insert(<string & number>key, controlToAdd);
					} else {
						(<FormGroup>form).addControl(key, controlToAdd);
					}
				}
			}
			formKeys.reverse().forEach(fk => {
				if (form instanceof FormGroup) form.removeControl(fk);
				if (form instanceof FormArray) form.removeAt(parseInt(fk, 10));
			});
			return form;
		} else { // obj is a number or string
			if (form instanceof FormControl) {
				if (form.value !== obj) {
					form.setValue(obj || null);
					config.makeDirty && form.markAsDirty();
				}
			} else {
				form = new FormControl(obj || null);
			}
			return form;
		}
	}

	forEachControl(
		form: AbstractControl,
		lambda: ControlLambda,
		config: FormTreeConfig = {}
	) {
		if (config.onlyLeafs === undefined) config.onlyLeafs = true;
		return this._forEachControl(form, lambda, config);
	}

	private _forEachControl(
		form: AbstractControl,
		lambda: ControlLambda,
		config: FormTreeConfig = {},
		keys: string[] = []
	) {
		const subControls: {[key: string]: AbstractControl} = form['controls'];
		if (!subControls || !config.onlyLeafs) lambda(form, keys);
		if (subControls) {
			for (const [subKey, subControl] of Object.entries(subControls)) this._forEachControl(subControl, lambda, config, [...keys, subKey]);
		}
	}

	recordChanges(form: AbstractControl, syncWith?: any) {
		const tracker = new FormChangeTracker(form, this, syncWith).startRecording();
		this._trackedForms.push([form, tracker]);
		return tracker;
	}

	getChangeTracker(targetForm: AbstractControl) {
		const targetFormTrackerPair = this._trackedForms.find(([form, tracker]) => form === targetForm);
		return targetFormTrackerPair ? targetFormTrackerPair[1] : undefined;
	}

	controlFromDocument(fields: DocumentField[] = [], entry: DocumentEntry): FormGroup {
		const fg = new FormGroup({});
		for (const f of fields) {
			if (!f.config || !f.config.prompt || !f.name) continue;
			const validators: ValidatorFn[] = [];
			if (f.config.required) validators.push(Validators.required);
			fg.addControl(f.name, new FormControl((entry && entry.values && entry.values[f.name]) || f.config.default, validators));
		}
		return fg;
	}

	compareWith(propName1 = 'id', propName2?: string) {
		return (o1: any, o2: any) => {
			return o1 && o2 && o1[propName1] === o2[propName2 || propName1];
		};
	}

	private _forceFormGroupOrArray(form: AbstractControl): form is FormGroup | FormArray {
		return true;
	}

	private _isFormGroupOrFormArray(obj: any): obj is FormGroup | FormArray {
		return !!obj.controls;
	}
}

interface FormTreeConfig {
	onlyLeafs?: boolean;
}
interface PatchValConfig {
	makeDirty?: boolean;
	primitiveKeys?: string[];
	primitiveTypes?: (new(...args: any[]) => any)[];
}

type ControlLambda = (control: AbstractControl, keys: string[], ...args: any[]) => any;

export class FormChangeTracker {
	public get changes() { return this._changeObj; }
	private _changeObj: any;
	private _changeList: string[][] = [];
	private _changeSubs: Subscription[] = [];
	private _startingValue: any;

	constructor(private _form: AbstractControl, private _fhSvc: FormHelperService, private _syncWith?: any) {}

	startRecording() {
		this._changeList = [];
		this._startingValue = undefined;
		this._fhSvc.forEachControl(this._form, (c, ks) => {
			this._deepSet(this, ['_startingValue', ...ks], c.value);
			this._changeSubs.push(c.valueChanges.subscribe(change => this._handleChange(ks, change)));
		});
		return this;
	}

	addControl(keyPath: string[], control: AbstractControl, change = true) {
		this._fhSvc.forEachControl(control, (c, ks) => {
			const changeKeys = [...keyPath, ...ks];
			this._deepSet(this, ['_startingValue', ...changeKeys], change ? undefined : c.value);
			change && this._handleChange(changeKeys, c.value);
			this._changeSubs.push(c.valueChanges.subscribe(ch => this._handleChange(changeKeys, ch)));
		});
	}

	stopRecording() {
		for (const sub of this._changeSubs) sub.unsubscribe();
		return this.changes;
	}

	revertChanges(keys: string[][] = [...this._changeList]) {
		while (keys.length) {
			const ch = <string[]>keys.shift();
			const oldVal = this._deepGet(this._startingValue, ch);
			this._form.get(ch)!.setValue(oldVal);
			this._handleChange(ch, oldVal);
		}
	}

	public pluck(keyArr: string[], include?: string[]) {
		const changes = this._deepGet(this.changes, keyArr);
		if (changes === undefined) return undefined;
		let retVal: any;
		if (!(this._form.get(keyArr.join('.')) instanceof FormGroup)) {
			retVal = changes;
		} else {
			retVal = {};
			for (const key of Object.keys(changes)) {
				retVal[key] = this._form.get(keyArr.concat([key]).join('.'))!.value;
			}
		}
		this._removeChange(keyArr);
		if (include) {
			const original = this._form.get(keyArr.join('.'))!.value;
			for (const key of include) retVal[key] = original[key];
		}
		return retVal;
	}

	private _handleChange(ks: string[], change: any = null) {
		if (change === '') change = null;
		if (this._deepGet(this._startingValue, ks) === change) {
			if (this._deepGet(this._changeObj, ks) !== undefined) {
				this._removeChange(ks);
				if (this._syncWith) this._deepSet(this._syncWith, ks, change);
			}
		} else {
			if (this._deepGet(this._changeObj, ks) !== change) {
				this._deepSet(this, ['_changeObj', ...ks], change);
				if (!this._changeList.includes(ks)) this._changeList.push(ks);
				if (this._syncWith) this._deepSet(this._syncWith, ks, change);
			}
		}
	}

	private _deepSet = (prop: any, propKeys: string[], value: any) => {
		if (!propKeys.length) throw new Error('Must supply at least one key');
		if (prop === undefined) throw new Error('Must supply a defined property');
		if (propKeys.length === 1 ) return prop[propKeys[0]] = value;
		const nextKey = propKeys[0];
		if (prop[nextKey] === undefined) prop[nextKey] = {};
		this._deepSet(prop[nextKey], propKeys.slice(1), value);
	}
	private _deepGet = (prop: any, propKeys: string[]) => {
		return propKeys.length && prop ? this._deepGet(prop[propKeys[0]], propKeys.slice(1)) : prop;
	}
	private _removeChange = (ks: string[]) => {
		const chIndex = this._changeList.indexOf(ks);
		if (chIndex !== -1) this._changeList.splice(chIndex, 1);
		if (!ks.length) return this._changeObj = undefined;
		const lastKey = ks[ks.length - 1];
		const parentObj = this._deepGet(this._changeObj, ks.slice(0, -1));
		delete parentObj[lastKey];
		if (!Object.keys(parentObj).length) this._removeChange(ks.slice(0, -1));
	}
}
