import {MigrationInterface, QueryRunner} from 'typeorm';

export class userChanges1566799899491 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('DROP VIEW IF EXISTS `user_customers`');
		await queryRunner.query('CREATE VIEW `user_customers` AS SELECT luo.userId as userId, luc.userId as customerId FROM leadUsers luo LEFT JOIN leadUsers luc ON luo.leadId = luc.leadId AND luc.positionName = "Customer" WHERE luo.positionName <> "Customer"');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('DROP VIEW IF EXISTS `user_customers`');
	}

}
