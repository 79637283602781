import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany, JoinColumn, ManyToOne, RelationId } from 'typeorm';
import { LeadStatus } from './lead-status';
import { User } from './user';
import { Lead } from './lead';

@Entity('leadStatusChanges')
export class LeadStatusChange {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@ManyToOne(type => LeadStatus, (leadStatus: LeadStatus) => leadStatus.leadChanges, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadStatusName'})
	public leadStatus: LeadStatus | undefined;

	@ManyToOne(type => Lead, (lead: Lead) => lead.statusChanges, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	public lead: Lead | undefined;

	@ManyToOne(type => User, (user: User) => user.leadStatusChanges, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'userId'})
	public user: User | undefined;

	@CreateDateColumn()
	public createdAt: Date | undefined;

	constructor(args: Partial<LeadStatusChange> = {}) {
		this.id = args.id;
		this.leadStatus = args.leadStatus ? new LeadStatus(args.leadStatus) : undefined;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.user = args.user ? new User(args.user) : undefined;
		this.createdAt = args.createdAt ? new Date(args.createdAt) : undefined;
	}
	toJSON() {
		const retObj = Object.assign({}, this);
		if (retObj.user) retObj.user = retObj.user.toJSON();
		return retObj;
	}
}
