import {MigrationInterface, QueryRunner} from "typeorm";

export class LoanTableAndRebateField1553526369630 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `loanProducts` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(255) NOT NULL, `factor` decimal(11,10) NOT NULL, `noItcFactor` decimal(11,10) NOT NULL, `provider` varchar(255) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `utilities` ADD `rebate` decimal(9,3) NOT NULL DEFAULT 3000");
        await queryRunner.query("ALTER TABLE `utilities` CHANGE `baseCostPerWatt` `baseCostPerWatt` decimal(5,3) NOT NULL DEFAULT 4");
        await queryRunner.query("ALTER TABLE `panels` CHANGE `wattage` `wattage` decimal(7,3) NOT NULL DEFAULT 300");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `panels` CHANGE `wattage` `wattage` decimal(10,0) NOT NULL DEFAULT '300'");
        await queryRunner.query("ALTER TABLE `utilities` CHANGE `baseCostPerWatt` `baseCostPerWatt` decimal(10,0) NOT NULL DEFAULT '4'");
        await queryRunner.query("ALTER TABLE `utilities` DROP COLUMN `rebate`");
        await queryRunner.query("DROP TABLE `loanProducts`");
    }

}
