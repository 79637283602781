import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, PrimaryColumn, Column, JoinColumn, OneToMany, ManyToMany, JoinTable, ManyToOne } from 'typeorm';
import { Role } from './role';
import { Comment } from './comment';

@Entity('commentTags')
export class CommentTag {
	@PrimaryColumn('varchar', { length: 55 })
	public name: string | undefined;

	@Column('varchar', {
		name: 'description',
		nullable: true,
		length: 140
	})
	public description: string | undefined;

	@ManyToMany(type => Comment, { onDelete: 'CASCADE', onUpdate: 'CASCADE' })
	comments: Comment[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<CommentTag> = {}) {
		this.name = args.name;
		this.comments = args.comments ? args.comments.map(t => new Comment(t)) : undefined;
		this.description = args.description;
	}
}
