import {MigrationInterface, QueryRunner} from 'typeorm';

export class sunHoursChanges1568540314103 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `designs` ADD `productionFactor` decimal(10,2) NULL');
		await queryRunner.query('UPDATE `designs` d JOIN `teams` t ON t.id = d.teamId SET d.productionFactor = t.productionFactor');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `designs` DROP COLUMN `productionFactor`');
	}

}
