import {MigrationInterface, QueryRunner} from 'typeorm';

export class userProposalSettings1569680481644 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('CREATE TABLE `proposalSettings` (`id` int NOT NULL AUTO_INCREMENT, `showCurrentBill` tinyint NOT NULL DEFAULT 0, `language` varchar(5) NOT NULL DEFAULT "en", `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `leadId` int NULL, UNIQUE INDEX `REL_ae0bb633f3570f8e11e7aa2843` (`leadId`), PRIMARY KEY (`id`)) ENGINE=InnoDB');
		await queryRunner.query('CREATE TABLE `userSettings` (`id` int NOT NULL AUTO_INCREMENT, `currentBillDefault` tinyint NOT NULL DEFAULT 0, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `userId` int NULL, UNIQUE INDEX `REL_551a8a9a9ac4b13dcec83202db` (`userId`), PRIMARY KEY (`id`)) ENGINE=InnoDB');
		await queryRunner.query('ALTER TABLE `proposalSettings` ADD CONSTRAINT `FK_ae0bb633f3570f8e11e7aa2843b` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE');
		await queryRunner.query('ALTER TABLE `userSettings` ADD CONSTRAINT `FK_551a8a9a9ac4b13dcec83202dbe` FOREIGN KEY (`userId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `userSettings` DROP FOREIGN KEY `FK_551a8a9a9ac4b13dcec83202dbe`');
		await queryRunner.query('ALTER TABLE `proposalSettings` DROP FOREIGN KEY `FK_ae0bb633f3570f8e11e7aa2843b`');
		await queryRunner.query('DROP INDEX `REL_551a8a9a9ac4b13dcec83202db` ON `userSettings`');
		await queryRunner.query('DROP TABLE `userSettings`');
		await queryRunner.query('DROP INDEX `REL_ae0bb633f3570f8e11e7aa2843` ON `proposalSettings`');
		await queryRunner.query('DROP TABLE `proposalSettings`');
	}

}
