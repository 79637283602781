import {MigrationInterface, QueryRunner} from "typeorm";

export class fileUpdates1564080006200 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `files` ADD `savedName` varchar(1000) NOT NULL");
        await queryRunner.query("ALTER TABLE `files` ADD UNIQUE INDEX `IDX_294748ce784b8234cd5766735b` (`savedName`)");
        await queryRunner.query("CREATE INDEX `IDX_332d10755187ac3c580e21fbc0` ON `files` (`name`)");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("DROP INDEX `IDX_332d10755187ac3c580e21fbc0` ON `files`");
        await queryRunner.query("ALTER TABLE `files` DROP INDEX `IDX_294748ce784b8234cd5766735b`");
        await queryRunner.query("ALTER TABLE `files` DROP COLUMN `savedName`");
    }

}
