import { Entity, PrimaryGeneratedColumn, Column, OneToMany } from 'typeorm';
import { Lead } from './lead';

@Entity('hoas')
export class HOA {

	@PrimaryGeneratedColumn()
	id: number | null;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'name'
	})
	name: string | null;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'phone'
	})
	phone: string | null;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'email'
	})
	email: string | null;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'docName'
	})
	docName: string | null;

	@OneToMany(type => Lead, (lead: Lead) => lead.hoa, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	leads: Lead[] | undefined;

	constructor(args: Partial<HOA> = {}) {
		this.id = args.id || null;
		this.name = args.name || null;
		this.phone = args.phone || null;
		this.email = args.email || null;
		this.docName = args.docName || null;
		this.leads = args.leads ? args.leads.map(l => new Lead(l)) : undefined;
	}
}
