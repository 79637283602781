import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
	MatButtonModule,
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
	MatIconModule,
	GestureConfig
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { STATES_INJECTOR, STATES_BY_COUNTRY } from './_helpers/states';

import { AppComponent } from 'app/app.component';
import { CustomDirectives } from 'app/_directives/custom-directives.module';
import { AppService } from 'app/app.service';
import { AuthService } from 'app/_services/auth.service';
import { JwtInterceptor, ErrorInterceptor, FileInterceptor } from 'app/_helpers';
import { SharedModule } from 'app/shared/shared.module';
import { routing } from 'app/app.routing';
import { HAMMER_GESTURE_CONFIG, BrowserModule } from '@angular/platform-browser';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';

export function init_theme(appLoadService: AppService) {
	return () => appLoadService.loadTheme();
}
export function init_statuses(appLoadService: AppService) {
	return () => appLoadService.updateStatusEnum();
}

export class HammerConfig extends GestureConfig  {
	buildHammer(element: HTMLElement) {
		const mc = <HammerManager>super.buildHammer(element);
		mc.set({touchAction: 'pan-y'});
		mc.get('pinch').set({enable: true});
		return mc;
	}
}

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CustomDirectives,
		FlexModule,
		FlexLayoutModule.withConfig({useColumnBasisZero: true}),
		HttpClientModule,
		MatIconModule,
		MatMomentDateModule,
		MatButtonModule,
		routing,
		SharedModule
	],
	providers: [
		AppService,
		AuthService,
		{ provide: OverlayContainer, useClass: FullscreenOverlayContainer },
		{ provide: APP_INITIALIZER, useFactory: init_theme, deps: [AppService], multi: true },
		{ provide: APP_INITIALIZER, useFactory: init_statuses, deps: [AppService], multi: true },
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}},
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: FileInterceptor, multi: true },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
		{ provide: STATES_INJECTOR, useValue: STATES_BY_COUNTRY }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
