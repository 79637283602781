import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FullscreenPhotoComponent } from 'app/authenticated/_extras/fullscreen-photo/fullscreen-photo.component';

@Directive({
	selector: 'button[pecmsImageModal], img[pecmsImageModal], a[pecmsImageModal]'
})
export class ImageModalDirective {

	@Input('pecmsImageModal') imageUrl: string;
	@Input('pecmsImageMode') mode: string;

	private _dlgRef;

	@HostListener('click') onclick() {
		this._dlgRef = this._dlg.open(FullscreenPhotoComponent, {
			data: {
				url: this.imageUrl,
				mode: this.mode
			},
			width: '750px',
			maxWidth: '100vw',
			height: '750px',
			maxHeight: '100vh'
		});
	}

	constructor(private _dlg: MatDialog) { }

}
