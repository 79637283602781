import { Injectable } from '@angular/core';
import { Theme, ThemePalettes, LeadStatus } from 'pecms-shared';
import { HttpClient } from '@angular/common/http';

import { environment as env } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AppService {

	private _theme: Theme;
	public get theme(): Theme {
		return this._theme;
	}

	constructor(
		private http: HttpClient
	) {	}

	public loadTheme() {
		return new Promise((resolve, reject) => {
			this.http.get('/colors').subscribe((t: ThemePalettes) => {
				this._theme = new Theme(
					t.primary,
					t.secondary,
					t.highlight,
					t.accent,
					t.success,
					t.info,
					t.warn,
					t.danger,
					t.lightContrast,
					t.darkContrast
				);
				resolve(this._theme);
			});
		});
	}

	public updateStatusEnum() {
		return LeadStatus.updateEnum(
			this.http.get<LeadStatus[]>('/leads/statuses').pipe(map(lss => lss.map(ls => new LeadStatus(ls)))).toPromise()
		);
	}
}
