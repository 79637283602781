import {MigrationInterface, QueryRunner} from "typeorm";

export class moveSignatureFilesToUser1551716080469 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `sigFile`");
        await queryRunner.query("ALTER TABLE `users` ADD `signatureFile` varchar(255) NULL");
        await queryRunner.query("ALTER TABLE `users` ADD `initialFile` varchar(255) NULL");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `users` DROP COLUMN `initialFile`");
        await queryRunner.query("ALTER TABLE `users` DROP COLUMN `signatureFile`");
        await queryRunner.query("ALTER TABLE `designs` ADD `sigFile` varchar(255) NULL");
    }

}
