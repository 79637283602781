import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, Unique, OneToMany, ManyToMany, JoinTable, Index } from 'typeorm';
import { DocumentField } from './document-field';
import { DocumentEntry } from './document-entry';
import { Team } from './team';
import { Utility } from './utility';

@Entity('documents')
export class Document {

	constructor(args: Partial<Document> = {}) {
		this.id = args.id;
		this.fields = args.fields ? args.fields.map(f => new DocumentField(f)) : undefined;
		this.entries = args.entries ? args.entries.map(e => new DocumentEntry(e)) : undefined;
		this.name = args.name;
		this.fileName = args.fileName;
	}
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@OneToMany(type => DocumentField, (documentField: DocumentField) => documentField.document, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	fields: DocumentField[] | undefined;

	@OneToMany(type => DocumentEntry, (documentEntry: DocumentEntry) => documentEntry.document, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	entries: DocumentEntry[] | undefined;

	@ManyToMany(type => Team)
	@JoinTable({
		name: 'documentConditions',
		joinColumn: {
			name: 'documentId',
			referencedColumnName: 'id'
		},
		inverseJoinColumn: {
			name: 'teamId',
			referencedColumnName: 'id'
		}
	})
	teams: Team[] | undefined;

	@ManyToMany(type => Utility)
	@JoinTable({
		name: 'documentConditions',
		joinColumn: {
			name: 'documentId',
			referencedColumnName: 'id'
		},
		inverseJoinColumn: {
			name: 'utilityName',
			referencedColumnName: 'name'
		}
	})
	utilities: Utility[] | undefined;

	@Column('varchar', {
		name: 'name'
	})
	@Index()
	public name: string | undefined;

	@Column('varchar', {
		name: 'fileName',
		nullable: false,
	})
	public fileName: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	getFieldsForPage(pageNum: number) {
		return this.fields ? this.fields.filter(f => f.pageNumber === pageNum) : [];
	}
}
