import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId} from 'typeorm';
import {Team} from './team';
import { Theme } from './color/theme';

@Entity('companies')
@Index('name', ['name'], {unique: true})
export class Company {

	@PrimaryGeneratedColumn({
		name: 'id'
	})
	id: number | undefined;

	@Column('varchar', {
		nullable: true,
		unique: true,
		length: 255,
		name: 'name'
	})
	name: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'primaryColor'
	})
	primaryColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'secondaryColor'
	})
	secondaryColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'highlightColor'
	})
	highlightColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'accentColor'
	})
	accentColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'successColor'
	})
	successColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'warnColor'
	})
	warnColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'dangerColor'
	})
	dangerColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'infoColor'
	})
	infoColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'lightContrastColor'
	})
	lightContrastColor: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'darkContrastColor'
	})
	darkContrastColor: string | undefined;

	@Column('datetime', {
		nullable: false,
		default: () => 'CURRENT_TIMESTAMP',
		name: 'createdAt'
	})
	createdAt: Date | undefined;

	@Column('datetime', {
		nullable: false,
		default: () => 'CURRENT_TIMESTAMP',
		name: 'updatedAt'
	})
	updatedAt: Date | undefined;

	@OneToMany(type => Team, (team: Team) => team.company, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	teams: Team[] | undefined;

	constructor(args: {[T in keyof Company]?: Company[T]} = {}) {
		this.id = args.id || undefined;
		this.name = args.name || undefined;
		this.primaryColor = args.primaryColor || undefined;
		this.secondaryColor = args.secondaryColor || undefined;
		this.highlightColor = args.highlightColor || undefined;
		this.accentColor = args.accentColor || undefined;
		this.successColor = args.successColor || undefined;
		this.warnColor = args.warnColor || undefined;
		this.dangerColor = args.dangerColor || undefined;
		this.infoColor = args.infoColor || undefined;
		this.lightContrastColor = args.lightContrastColor || undefined;
		this.darkContrastColor = args.darkContrastColor || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.teams = args.teams || undefined;
	}

	public getTheme(): Theme {
		return new Theme(
			this.primaryColor,
			this.secondaryColor,
			this.highlightColor,
			this.accentColor,
			this.successColor,
			this.infoColor,
			this.warnColor,
			this.dangerColor,
			this.lightContrastColor,
			this.darkContrastColor
		);
	}

}
