import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Role, RoleNotification } from 'pecms-shared';

@Injectable({
	providedIn: 'root'
})
export class RoleService {

	constructor(
		private http: HttpClient
	) { }

	public allRoles: Observable<Role[]> = this.http.get<any[]>('/roles').pipe(map((rs) => rs.map(r => new Role(r))));

	public schema: Observable<{[key: string]: string | string[]}> = this.http.get<{[key: string]: string | string[]}>('/roles/schema');

	public save: (role: Role) => Observable<Role> = (role) => {
		if (role.id) return this.http.patch<Role>(`/roles/${role.id}`, role).pipe(map(r => new Role(r)));
		return this.http.post<Role>(`/roles`, role).pipe(map(r => new Role(r)));
	}

	public delete(id: number): Observable<number>;
	public delete(name: string): Observable<number>;
	public delete(identifier: string | number): Observable<number> {
		return this.http.delete<number>(`/roles/${identifier}`);
	}

	public roleNotifications(roleId: number): Observable<RoleNotification[]> {
		return this.http.get<any[]>(`/roles/${roleId}/notification`).pipe(map((rs) => rs.map(r => new RoleNotification(r))));
	}

	public postRoleNotifications(roleId: number, roleNotification: RoleNotification): Observable<RoleNotification> {
		return this.http.post(`/roles/${roleId}/notification`, roleNotification)
			.pipe(map((rn) => new RoleNotification(rn)));
	}

	public updateRoleNotifications(roleId: number, notificationId: number, patchDoc: any): Observable<RoleNotification> {
		return this.http.patch(`/roles/${roleId}/notification/${notificationId}`, patchDoc)
			.pipe(map((rn) => new RoleNotification(rn)));
	}

	public deleteRoleNotification(roleId: number, notificationId: number): Observable<number> {
		return this.http.delete<number>(`/roles/${roleId}/notification/${notificationId}`);
	}
}
