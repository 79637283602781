import { QueryStringBuilder } from 'app/_helpers/query-string-builder';
import { ExpandType } from 'app/_services/abstract-service.service';
import { AppService } from 'app/app.service';
import { environment } from 'environments/environment';
import { Color, FileMeta, User } from 'pecms-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(
		private http: HttpClient,
		private appSvc: AppService
	) { }

	public allUsers: Observable<User[]> = this.http.get<any[]>('/users').pipe(map((us) => us.map(u => new User(u))));

	public passwordAuth(username: string, password: string): Observable<string> {
		return this.http.post<string>(`/users/authenticate`, { username, password });
	}

	public googleAuth(token: string): Observable<string | {username: string}> {
		return this.http.post<string>(`/users/authenticate/google`, { token });
	}

	public claimGoogleAccount(token: string): Observable<string> {
		return this.http.post<string>(`/users/authenticate/google`, { token }, {params: {claim: 'true'}});
	}
	public getUser: (number) => Observable<User> = (id: number) =>
		this.http.get<any>(`/users/${id}`).pipe(map(u => new User(u)))

	public getPublicUser: (number) => Observable<User> = (id: number) =>
		this.http.get<any>(`/users/${id}/public`).pipe(map(u => new User(u)))

	public getUserByEmail: (email: string) => Observable<User> = (email: string) =>
		this.http.get<User>(`/users`, {params: {email}}).pipe(map(u => new User(u)))

	public patchUser = (patchObj: Partial<User>, id?: number, expand?: ExpandType<User>) => {
		if (!id) id = patchObj.id;
		if (!id) throw new Error('You must either pass an id or include it in the patch body');
		return this.http.patch<any>(`/users/${id}${new QueryStringBuilder({expand})}`, patchObj).pipe(map(u => new User(u)));
	}

	public createUser(user: User) {
		if (user.hasOwnProperty('online')) delete user.online;
		return this.http.post<User>(`/users`, user).pipe(map(u => new User(u)));
	}

	public setSignature(file: FileMeta, userId: number, type: 'signature' | 'initial') {
		return this.http.post(`/users/${userId}/${type}`, file).pipe(map(u => new User(u)));
	}

	public impersonateUser(userId: number) {
		return this.http.get(`/users/${userId}/impersonate`);
	}

	public getPhotoUrl(user?: User, size = 150) {
		if (!user || !user.photo) return this.getPlaceholderPhotoUrl(user, size);
		return user.photo.buildUrl(environment);
	}

	public getPlaceholderPhotoUrl(user: User, size = 150) {
		if (!user) user = new User({firstName: '. .', lastName: '.'});
		const url = [`https://ui-avatars.com/api?name=${user.fullName}&size=${size}`];
		url.push(`length=${user.fullName.split(' ').length}`);
		const possibleColors = [this.appSvc.theme.getColor('primary'), this.appSvc.theme.getColor('secondary'), this.appSvc.theme.getColor('accent')];
		const targetColor = possibleColors[user.id ? user.id % 3 : 0];
		url.push(`background=${targetColor.cleanHex}`);
		url.push(`color=${this.appSvc.theme.getColor(targetColor.luma > 127 ? 'darkContrast' : 'lightContrast').cleanHex}`);
		return url.join('&');
	}

}
