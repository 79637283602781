import {MigrationInterface, QueryRunner} from 'typeorm';

export class userPhotoRelation1573515450465 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `users` DROP COLUMN `photo`', undefined);
		await queryRunner.query('ALTER TABLE `users` ADD `photo` int NULL', undefined);
		await queryRunner.query('ALTER TABLE `users` ADD UNIQUE INDEX `IDX_da675fff89ce00f36453c8b242` (`photo`)', undefined);
		await queryRunner.query('CREATE UNIQUE INDEX `REL_da675fff89ce00f36453c8b242` ON `users` (`photo`)', undefined);
		await queryRunner.query('ALTER TABLE `users` ADD CONSTRAINT `FK_da675fff89ce00f36453c8b2423` FOREIGN KEY (`photo`) REFERENCES `files`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE', undefined);
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `users` DROP FOREIGN KEY `FK_da675fff89ce00f36453c8b2423`', undefined);
		await queryRunner.query('DROP INDEX `REL_da675fff89ce00f36453c8b242` ON `users`', undefined);
		await queryRunner.query('ALTER TABLE `users` DROP COLUMN `photo`', undefined);
		await queryRunner.query('ALTER TABLE `users` ADD `photo` varchar(255) NULL', undefined);
	}

}
