import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import {QuickSaleStatus} from './quick-sale-status';
import {Employee} from './employee';
import { User } from './user';
import {QuickSaleStatusChange} from './quick-sale-status-change';
import { Owner } from './utilities';
import { Scopes } from './rbac-scope.enum';
import { Lead } from './lead';
import { LeadStatuses } from './lead-statuses.enum';

@Entity('quickSales')
@Index('statusId', ['status', ])
@Index('employeeId', ['employee', ])
@Index('createdBy', ['createdBy', ])
export class QuickSale {

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@Column({name: 'statusId'})
	get statusId() { return this.status && this.status.id ? this.status.id : 0; }
	set statusId(val) {
		if (!this.status) this.status = new QuickSaleStatus();
		this.status.id = val;
	}
	@ManyToOne(type => QuickSaleStatus, (quickSaleStatus: QuickSaleStatus) => quickSaleStatus.quickSales, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'statusId' })
	status: QuickSaleStatus | undefined;

	@Column({name: 'employeeId', type: 'int'})
	get employeeId() { return this.employee && this.employee.id ? this.employee.id : undefined; }
	set employeeId(val) {
		if (!this.employee) this.employee = new Employee();
		this.employee.id = val;
	}

	@ManyToOne(type => Employee, (employee: Employee) => employee.quickSales, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'employeeId' })
	@Owner({path: ['user']})
	@Owner({scope: Scopes.TEAM, path: ['team']})
	employee: Employee | undefined;

	@Column('decimal', {
		nullable: false,
		precision: 10,
		scale: 2,
		name: 'size'
	})
	size: number | undefined;

	@Column({name: 'createdBy'})
	get createdById() { return this.createdBy && this.createdBy.id ? this.createdBy.id : 0; }
	set createdById(val) {
		if (!this.createdBy) this.createdBy = new User();
		this.createdBy.id = val;
	}

	@ManyToOne(type => User, (user: User) => user.quickSales, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'createdBy' })
	createdBy: User | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	@OneToMany(type => QuickSaleStatusChange, (quickSaleStatusChange: QuickSaleStatusChange) => quickSaleStatusChange.quickSale, { onDelete: 'RESTRICT', onUpdate: 'CASCADE', cascade: true })
	quickSaleStatusChanges: QuickSaleStatusChange[] | undefined;

	constructor(args: {[T in keyof QuickSale]?: QuickSale[T]} = {}) {
		this.id = args.id || undefined;
		this.status = args.status ? new QuickSaleStatus(args.status) : undefined;
		this.employee = args.employee ? new Employee(args.employee) : undefined;
		this.size = args.size || undefined;
		this.createdBy = args.createdBy ? new User(args.createdBy) : undefined;
		this.createdAt = args.createdAt ? new Date(args.createdAt) : undefined;
		this.updatedAt = args.updatedAt ? new Date(args.updatedAt) : undefined;
		this.quickSaleStatusChanges = args.quickSaleStatusChanges || undefined;
		if (args.employeeId) this.employeeId = args.employeeId;
		if (args.statusId) this.statusId = args.statusId;
		if (args.createdById) this.createdById = args.createdById;
	}

	public static fromLead(lead: Lead): QuickSale {
		if (lead.statusChanges && lead.statusChanges.length) {
			(lead.statusChanges.sort((l1, l2) => <any>l2.createdAt - <any>l1.createdAt));
			const soldStatus = lead.statusChanges.find(sc => sc.leadStatus!.name === 'Pure Approved');
			if (soldStatus) lead.updatedAt = soldStatus.createdAt;
		}
		return new QuickSale({
			status: new QuickSaleStatus({name: lead.status && ((lead.status.order && lead.status.order >= LeadStatuses['Pure Approved']) && lead.status.activeLead) ? 'Sold' : 'Lead'}),
			employee: lead.salesRep ? new Employee((<User>lead.salesRep).employee) : undefined,
			size: lead.design && lead.design.systemSize,
			createdAt: lead.createdAt,
			updatedAt: lead.updatedAt
		});
	}
}
