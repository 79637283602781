import {MigrationInterface, QueryRunner} from "typeorm";

export class adjustDesign1549996594744 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `systemSize`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `production`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `offset`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `trees`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `gasoline`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `electricity`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `waste`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `fiveYearBill`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `tenYearBill`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `solarBill`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `doNothingCost`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `wattCost`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `financedAmount`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `taxCredit`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `netCost`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `loanPayment`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `savings`");
        await queryRunner.query("ALTER TABLE `designs` ADD `teamId` int NOT NULL");
        await queryRunner.query("UPDATE `designs` SET `teamId` = 2");
        await queryRunner.query("ALTER TABLE `teams` ADD `productionFactor` int NOT NULL DEFAULT 1500");
        await queryRunner.query("ALTER TABLE `designs` ADD `panelCount` int NULL");
        await queryRunner.query("ALTER TABLE `teams` CHANGE `createdAt` `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6)");
        await queryRunner.query("ALTER TABLE `teams` CHANGE `updatedAt` `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6)");
        await queryRunner.query("ALTER TABLE `designs` CHANGE `approvedAt` `approvedAt` datetime NULL");
        await queryRunner.query("ALTER TABLE `designs` CHANGE `createdAt` `createdAt` datetime NOT NULL DEFAULT CURRENT_TIMESTAMP");
        await queryRunner.query("ALTER TABLE `designs` CHANGE `updatedAt` `updatedAt` datetime NOT NULL DEFAULT CURRENT_TIMESTAMP");
        await queryRunner.query("ALTER TABLE `designs` ADD CONSTRAINT `FK_9243f6345233ff4c8ccfb000a91` FOREIGN KEY (`teamId`) REFERENCES `teams`(`id`) ON DELETE RESTRICT");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `designs` DROP FOREIGN KEY `FK_9243f6345233ff4c8ccfb000a91`");
        await queryRunner.query("ALTER TABLE `designs` CHANGE `approvedAt` `approvedAt` datetime(0) NULL");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `panelCount`");
        await queryRunner.query("ALTER TABLE `teams` DROP COLUMN `productionFactor`");
        await queryRunner.query("ALTER TABLE `designs` ADD `savings` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `loanPayment` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `netCost` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `taxCredit` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `financedAmount` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `wattCost` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `doNothingCost` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `solarBill` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `tenYearBill` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `fiveYearBill` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `waste` int NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `electricity` int NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `gasoline` int NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `trees` int NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `offset` int NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `production` int NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `systemSize` decimal(10,2) NULL");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `teamId`");
    }

}
