import {Index, Entity, PrimaryGeneratedColumn, Column, ManyToOne, JoinColumn, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import {Role} from './role';
import {LeadStatus} from './lead-status';

@Entity('roleNotifications')
@Index('roleId', ['role', ])
@Index('statusName', ['status', ])
export class RoleNotification {

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@ManyToOne(type => Role, (role: Role) => role.roleNotifications, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'roleId' })
	role: Role | undefined;

	@Column('enum', {
		nullable: true,
		enum: ['NONE', 'OWN', 'TEAM', 'COMPANY', 'ALL'],
		name: 'ownership'
	})
	ownership: string | undefined;

	@Column({name: 'statusName'})
	get statusName() { return this.status && this.status.name ? this.status.name : ''; }
	set statusName(val) {
		if (!this.status) this.status = new LeadStatus();
		this.status.name = val;
	}

	@ManyToOne(type => LeadStatus, (leadStatus: LeadStatus) => leadStatus.roleNotifications, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'statusName' })
	status: LeadStatus | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'message'
	})
	message: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: {[T in keyof RoleNotification]?: RoleNotification[T]} = {}) {
		this.id = args.id || undefined;
		this.role = args.role || undefined;
		this.ownership = args.ownership || undefined;
		this.status = args.status || undefined;
		this.message = args.message || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		if (args.statusName) this.statusName = args.statusName;
	}
}
