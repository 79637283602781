import { MatchFontDirective } from 'app/_directives/match-font.directive';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, map, throttleTime } from 'rxjs/operators';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MatchFontService {

	public matchGroups: {[key: string]: MatchFontRegistrar} = {};

	register(mfd: MatchFontDirective): BehaviorSubject<number> {
		if (!mfd.groupName) mfd.groupName = Date.now().toString();
		let grp = this.matchGroups[mfd.groupName];
		if (!grp) grp = this.matchGroups[mfd.groupName] = { instances: [], smallestSize: new BehaviorSubject<number>(Infinity) };
		grp.instances.push(mfd);
		this._updateSizeChanges(mfd.groupName);
		mfd.ngOnDestroy = () => { this.deregister(mfd); };
		return grp.smallestSize;
	}

	deregister(mfd: MatchFontDirective) {
		const grp = this.matchGroups[mfd.groupName];
		grp.instances = grp.instances.filter(mfi => mfi !== mfd);
		this._updateSizeChanges(mfd.groupName);
		if (!grp.instances.length) delete this.matchGroups[mfd.groupName];
	}

	private _updateSizeChanges = (groupName: string) => {
		this.matchGroups[groupName].sizeChanges && this.matchGroups[groupName].sizeChanges.unsubscribe();
		this.matchGroups[groupName].sizeChanges = combineLatest(this.matchGroups[groupName].instances.map(mf => mf.fontSize))
			.pipe(map(nums => Math.min(...nums)), throttleTime(50))
			.subscribe(n => {
				const group = this.matchGroups[groupName];
				return group && group.smallestSize.next(n);
			});
	}

	constructor() { }
}

interface MatchFontRegistrar {
	instances: MatchFontDirective[];
	smallestSize: BehaviorSubject<number>;
	sizeChanges?: Subscription;
}
