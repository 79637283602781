import {MigrationInterface, QueryRunner} from "typeorm";

export class addCash1554085815847 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `cash`");
        await queryRunner.query("INSERT INTO `loanProviders` (`name`) VALUES ('Cash')");
        await queryRunner.query("INSERT INTO `loanProducts` (`name`, `factor`, `noItcFactor`, `loanProviderId`, `savingsFactor`) VALUES ('Cash', 0, 0, 1, 0)");
    }
    
    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("DELETE FROM `loanProducts` where name = 'Cash'");
        await queryRunner.query("DELETE FROM `loanProviders` where name = 'Cash'");
        await queryRunner.query("ALTER TABLE `loanProviders` AUTO_INCREMENT = 1;");
        await queryRunner.query("ALTER TABLE `leads` ADD `cash` tinyint NOT NULL DEFAULT 0");
    }

}
