import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultUrlSerializer } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class FileUploadService {

	static id = 0;

	private _queuedFiles: {[key: string]: File} = {};

	constructor(
		private http: HttpClient
	) { }

	queueFile(file: File): number;
	queueFile(dataURI: string): number;
	queueFile(fileOrURi: File | string): number {
		if (typeof(fileOrURi) === 'string') {
			fileOrURi = this._dataURItoFile(fileOrURi);
		}
		const id = FileUploadService.id++;
		this._queuedFiles[id] = fileOrURi;
		return id;
	}

	uploadFile(id: number, signature: string) {
		const urlSerializer = new DefaultUrlSerializer();
		const headers: any = {'Content-Type': ''};
		const url = urlSerializer.parse(signature.split('//')[1]);
		const expires = url.queryParamMap.get('Expires');
		const cType = url.queryParamMap.get('Content-Type');
		const acl = url.queryParamMap.get('x-amz-acl');
		if (expires) headers.Expires = expires;
		if (cType) headers['Content-Type'] = cType;
		if (acl) headers['x-amz-acl'] = acl;
		if (this._queuedFiles[id]) {
			this.http.put(signature, this._queuedFiles[id], {headers}).subscribe(r => {
				this.removeQueuedFile(id);
			}, e => console.error(e));
		} else {
			console.error(`file with id ${id} not found`);
			console.error(`${Object.keys(this._queuedFiles)}`);
		}
	}

	updateQueuedFile = (id: number, file: File) => this._queuedFiles[id] = file;

	removeQueuedFile(id: number) {
		delete this._queuedFiles[id];
	}

	retrieveFile(id: number) {
		return this._queuedFiles[id];
	}

	private _dataURItoFile(dataURI, type = 'image/png', filename = 'fname') {
		const binary = atob(dataURI);
		const array = [];
		for (let i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i));
		}
		return new File([new Uint8Array(array)], filename, {type});
	}

	private _isFile(file: any): file is File {
		return file.hasOwnProperty('lastModified') && file.hasOwnProperty('name');
	}
}

export interface UploadStatus {
	type: 1 | 0;
	loaded: number;
	total: number;
}

export function isUploadStatus(response: any): response is UploadStatus {
	return response.type === 1 || response.type === 0;
}
