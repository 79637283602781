import {MigrationInterface, QueryRunner} from "typeorm";

export class statusChanges1563140344132 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `leadStatusChanges` (`id` int NOT NULL AUTO_INCREMENT, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `leadStatusName` varchar(255) NULL, `leadId` int NULL, `userId` int NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `leadStatusChanges` ADD CONSTRAINT `FK_3b8d16ad1b352b3a47933f5e660` FOREIGN KEY (`leadStatusName`) REFERENCES `leadStatuses`(`name`) ON DELETE RESTRICT ON UPDATE CASCADE");
        await queryRunner.query("ALTER TABLE `leadStatusChanges` ADD CONSTRAINT `FK_c49a7de0c698b2289879538b30c` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
        await queryRunner.query("ALTER TABLE `leadStatusChanges` ADD CONSTRAINT `FK_f9a1f7f8a53fc244d4e3778248e` FOREIGN KEY (`userId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
        await queryRunner.query("INSERT INTO `leadStatusChanges` (`leadStatusName`, `userId`, `leadId`, `createdAt`) (SELECT `statusName`, `salesRepId`, `id`, `updatedAt` from `leads`)");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leadStatusChanges` DROP FOREIGN KEY `FK_f9a1f7f8a53fc244d4e3778248e`");
        await queryRunner.query("ALTER TABLE `leadStatusChanges` DROP FOREIGN KEY `FK_c49a7de0c698b2289879538b30c`");
        await queryRunner.query("ALTER TABLE `leadStatusChanges` DROP FOREIGN KEY `FK_3b8d16ad1b352b3a47933f5e660`");
        await queryRunner.query("DROP TABLE `leadStatusChanges`");
    }

}
