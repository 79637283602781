import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Employee } from 'pecms-shared';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { GetConfig, AbstractServiceService } from 'app/_services/abstract-service.service';
import { QueryStringBuilder } from 'app/_helpers/query-string-builder';

@Injectable({
	providedIn: 'root'
})
export class EmployeeService extends AbstractServiceService<Employee> {
	protected entityClass = Employee;
	protected entityClassName = 'Employee';

	public makeUserEmployee(loginId: number, teamId: number) {
		return this.http.post<Employee>(`/employees`, {loginId, teamId}).pipe(map(r => new Employee(r)));
	}

	public createEmployee(teamId, roleId, firstName, lastName, email, username, ssFile, checkFile, photo?, password?) {
		return this.http.post<Employee>(`/employees/invite`, {
			teamId,
			roleId,
			firstName,
			lastName,
			email,
			username,
			photo,
			ssFile,
			checkFile,
			password
		}).pipe(map(r => new Employee(r)));
	}

	public allPublic: () => Observable<Employee[]> = () => {
		return this.http.get<Employee[]>(`/employees/public`).pipe(map(es => es.map(e => new Employee(e))));
	}

	public save: (employee: Employee) => Observable<Employee> = (employee) => {
		return this.http.post<Employee>(`/employees`, employee).pipe(map(r => new Employee(r)));
	}

	public delete(identifier: number): Observable<number> {
		return this.http.delete<number>(`/employees/${identifier}`);
	}
}
