import { Entity, PrimaryColumn, ManyToOne, JoinColumn, Column } from 'typeorm';
import { LeadStatus } from './lead-status';
import { User } from './user';

@Entity('user_notifications')
export class UserNotification {

	@PrimaryColumn({type: 'int', name: 'loginId'})
	get userId() { return this.user && this.user.id ? this.user.id : undefined; }
	set userId(val) {
		if (!this.user) this.user = new User();
		this.user.id = val;
	}

	@ManyToOne(type => User, (user: User) => user.notifications, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'loginId' })
	user: User | undefined;

	@Column({name: 'statusName'})
	get statusName() { return this.status && this.status.name ? this.status.name : ''; }
	set statusName(val) {
		if (!this.status) this.status = new LeadStatus();
		this.status.name = val;
	}
	@ManyToOne(type => LeadStatus, (leadStatus: LeadStatus) => leadStatus.roleNotifications, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'statusName' })
	public status: LeadStatus | undefined;

	@Column('varchar')
	public message: string | undefined;

	constructor(args: {[T in keyof UserNotification]?: UserNotification[T]} = {}) {
		this.userId = args.userId || undefined;
		this.status = args.status || undefined;
		this.message = args.message || undefined;
	}
}
