import {MigrationInterface, QueryRunner} from "typeorm";

export class cashLead1553961768770 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` ADD `cash` tinyint NOT NULL DEFAULT 0");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `cash`");
    }

}
