import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'app/_helpers';
import { AppComponent } from 'app/app.component';

const appRoutes: Routes = [{
	path: 'login',
	component: AppComponent,
	loadChildren: 'app/unauthenticated/unauthenticated.module#UnauthenticatedModule'
}, {
	path: '',
	component: AppComponent,
	canActivate: [AuthGuard],
	loadChildren: 'app/authenticated/authenticated.module#AuthenticatedModule'
}];

export const routing = RouterModule.forRoot(appRoutes);
