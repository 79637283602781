import {MigrationInterface, QueryRunner} from 'typeorm';

export class addAddressToSigner1571629679314 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `signers` ADD `addr1` varchar(255) NULL', undefined);
		await queryRunner.query('ALTER TABLE `signers` ADD `addr2` varchar(255) NULL', undefined);
		await queryRunner.query('ALTER TABLE `signers` ADD `city` varchar(255) NULL', undefined);
		await queryRunner.query('ALTER TABLE `signers` ADD `state` varchar(255) NULL', undefined);
		await queryRunner.query('ALTER TABLE `signers` ADD `zip` varchar(255) NULL', undefined);
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `signers` DROP COLUMN `zip`', undefined);
		await queryRunner.query('ALTER TABLE `signers` DROP COLUMN `state`', undefined);
		await queryRunner.query('ALTER TABLE `signers` DROP COLUMN `city`', undefined);
		await queryRunner.query('ALTER TABLE `signers` DROP COLUMN `addr2`', undefined);
		await queryRunner.query('ALTER TABLE `signers` DROP COLUMN `addr1`', undefined);
	}

}
