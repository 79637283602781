import {MigrationInterface, QueryRunner} from "typeorm";

export class comments1561100194689 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `comments` (`id` int NOT NULL AUTO_INCREMENT, `content` varchar(1000) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `createdById` int NULL, `leadId` int NULL, FULLTEXT INDEX `IDX_b6496f77981b10109fd0fff325` (`content`), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("CREATE TABLE `commentTags` (`name` varchar(55) NOT NULL, `description` varchar(140), `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`name`)) ENGINE=InnoDB");
        await queryRunner.query("CREATE TABLE `comment_commentTags` (`commentId` int NOT NULL, `commentTagName` varchar(55) NOT NULL, INDEX `IDX_df67221b64377fb0c869d0a497` (`commentId`), INDEX `IDX_6544d9f96243913591de90804e` (`commentTagName`), PRIMARY KEY (`commentId`, `commentTagName`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `comments` ADD CONSTRAINT `FK_cd31cf1f563a06aeeaab821bd1c` FOREIGN KEY (`createdById`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
        await queryRunner.query("ALTER TABLE `comments` ADD CONSTRAINT `FK_8bcef8c6394cfb93ff8171757ae` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
        await queryRunner.query("ALTER TABLE `comment_commentTags` ADD CONSTRAINT `FK_df67221b64377fb0c869d0a497d` FOREIGN KEY (`commentId`) REFERENCES `comments`(`id`) ON DELETE CASCADE ON UPDATE NO ACTION");
        await queryRunner.query("ALTER TABLE `comment_commentTags` ADD CONSTRAINT `FK_6544d9f96243913591de90804ef` FOREIGN KEY (`commentTagName`) REFERENCES `commentTags`(`name`) ON DELETE NO ACTION ON UPDATE NO ACTION");
        await queryRunner.query("INSERT INTO `commentTags` (`name`, `description`) VALUES ('CustomerQuestions', 'Notes based on customer comments that are visible on the proposal')");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `comment_commentTags` DROP FOREIGN KEY `FK_6544d9f96243913591de90804ef`");
        await queryRunner.query("ALTER TABLE `comment_commentTags` DROP FOREIGN KEY `FK_df67221b64377fb0c869d0a497d`");
        await queryRunner.query("ALTER TABLE `comments` DROP FOREIGN KEY `FK_8bcef8c6394cfb93ff8171757ae`");
        await queryRunner.query("ALTER TABLE `comments` DROP FOREIGN KEY `FK_cd31cf1f563a06aeeaab821bd1c`");
        await queryRunner.query("DROP INDEX `IDX_6544d9f96243913591de90804e` ON `comment_commentTags`");
        await queryRunner.query("DROP INDEX `IDX_df67221b64377fb0c869d0a497` ON `comment_commentTags`");
        await queryRunner.query("DROP TABLE `comment_commentTags`");
        await queryRunner.query("DROP TABLE `commentTags`");
        await queryRunner.query("DROP INDEX `IDX_b6496f77981b10109fd0fff325` ON `comments`");
        await queryRunner.query("DROP TABLE `comments`");
    }

}
