import { Router } from '@angular/router';

import { Component } from '@angular/core';


@Component({
	selector: 'pecms-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent  {
	constructor() {}
}
