import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, OneToOne, JoinColumn, ManyToOne } from 'typeorm';
import { Lead } from './lead';

@Entity('leadAdders')
export class LeadAdder {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
		length: 50
	})
	public name: string | undefined;

	@Column('varchar', {
		name: 'type',
		nullable: false,
	})
	public type: 'per-watt' | 'total' | undefined;

	@Column('decimal', {
		name: 'amount',
		nullable: false,
		precision: 8,
		scale: 3
	})
	public amount: number | undefined;

	@ManyToOne(type => Lead, (lead: Lead) => lead.adders, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	lead: Lead | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LeadAdder> = {}) {
		this.id = args.id;
		this.name = args.name;
		this.type = args.type;
		this.amount = args.amount;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
	}
}
