import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { environment as env } from 'environments/environment';
import { ApiResponse, isSuccessResponse } from 'app/_models/api-response';
import { AuthService } from 'app/_services';
import { VersionCheckService } from 'app/_services/version-check.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(
		private authSvc: AuthService,
		private versionCheckSvc: VersionCheckService
	) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		if (request.url.match(/^\/[^/]/)) {
			let url = '';
			if (env.apiUrl.match(/^\/\//)) {
				url += env.apiUrl;
			} else {
				const hn = window.location.hostname === 'client' ? 'api' : window.location.hostname;
				url += `${window.location.protocol}//${hn}:${env.apiPort}${env.apiUrl}`;
			}
			const token = localStorage.getItem('currentUser');
			const cloneObj: any = {url: url + request.url};
			if (token) cloneObj.setHeaders = { Authorization: `Bearer ${token}` };
			request = request.clone(cloneObj);
			return next.handle(request).pipe(map(event => {
				if (event instanceof HttpResponse) {
					const res = <ApiResponse>event.body;
					if (isSuccessResponse(res)) event = event.clone({ body: res.data.details || res.data.message });
					if (event.headers.has('refresh-token')) this.authSvc.updateUser(event.headers.get('refresh-token'));
					if (event.headers.has(VersionCheckService.headerName)) this.versionCheckSvc.checkVersion(event);
				}
				return event;
			}));
		}

		return next.handle(request);
	}
}
