import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';

import { LoanConfigSet } from './loan-config-set';
import { LoanProduct } from './loan-product';

@Entity('loanConfigColumns')
export class LoanConfigColumn {
	public static get Empty() {
		return new LoanConfigColumn();
	}

	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('int', {
		name: 'order',
		nullable: false,
		// length: 255
	})
	public order: number | undefined;

	@ManyToOne(type => LoanProduct, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'LoanProductId'})
	loanProduct: LoanProduct | undefined;

	@ManyToOne(type => LoanConfigSet, (loanConfigSet: LoanConfigSet) => loanConfigSet.columns, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'loanConfigSetId'})
	set: LoanConfigSet | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LoanConfigColumn> = {}) {
		this.id = args.id;
		this.order = args.order;
		this.loanProduct = args.loanProduct ? new LoanProduct(args.loanProduct) : undefined;
		this.set = args.set ? new LoanConfigSet(args.set) : undefined;
	}
}
