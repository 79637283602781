import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, OneToMany, JoinColumn, Index } from 'typeorm';
import { LoanProduct } from './loan-product';
import { Lead } from './lead';

@Entity('loanProviders')
export class LoanProvider {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
		// length: 255
	})
	@Index()
	public name: string | undefined;

	@Column('varchar', {
		name: 'lender',
		nullable: false,
		// length: 255
	})
	@Index()
	public lender: string | undefined;

	@OneToMany(type => LoanProduct, (loanProduct: LoanProduct) => loanProduct.loanProvider, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	loanProducts: LoanProduct[] | undefined;

	@OneToMany(type => Lead, (lead: Lead) => lead.loanProvider, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	leads: Lead[] | undefined;

	@Column('boolean', {
		name: 'active',
		nullable: false
		// length: 255
	})
	public active: boolean | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<LoanProvider> = {}) {
		this.id = args.id;
		this.name = args.name;
		this.lender = args.lender;
		this.active = args.active == null ? true : args.active;
		this.loanProducts = args.loanProducts ? args.loanProducts.map(lp => new LoanProduct(lp)) : undefined;
		this.leads = args.leads ? args.leads.map(l => new Lead(l)) : undefined;
	}
}
