import { SelectQueryBuilder } from 'typeorm';
import 'reflect-metadata';
import { SelectorCallback, QueryState } from './models';

export class JoinQueryBuilder<T> {

	constructor(selectorCB: SelectorCallback, targetClass: new(...args: any[]) => T, tableName: string, alias: string);
	constructor(selector: string, targetClass: new(...args: any[]) => T, tableName: string, alias: string);
	constructor(
		private _selector: string | SelectorCallback,
		private _targetClass: new(...args: any[]) => T,
		public tableName: string, public alias: string
	) { }

	public joinQuery(qb: SelectQueryBuilder<T>, aliasName?: string) {
		if (typeof this._selector === 'function') return this._selector(this._evalState(qb, aliasName));
		return this._selector;
	}

	private _evalState(qb: SelectQueryBuilder<T>, aliasName?: string) {
		const state: Partial<QueryState> = {};
		const targetAlias = qb.expressionMap.aliases.find(a => {
			return a.target === this._targetClass || (<any>a.target).prototype instanceof this._targetClass;
		});
		state.tableAlias = aliasName || (targetAlias && targetAlias.name);
		state.primaryColumn = targetAlias!.metadata.primaryColumns[0].databasePath;
		state.aliasFor = (target: any) => {
			const alias = qb.expressionMap.aliases.find(a => a.target === target || ((<any>a.target).prototype instanceof target));
			if (alias) return alias.name;
			return '';
		};
		return <QueryState>state;
	}

}

export function JoinQuery(tableName: string, alias: string, config: string | SelectorCallback) {
	return (target: any, name: string, descriptor: PropertyDescriptor) => {
		const existingMeta = Reflect.getMetadata('join_query', target.constructor) || {};
		// @ts-ignore
		existingMeta[name] = new JoinQueryBuilder<typeof target>(config, target.constructor, tableName, alias);
		Reflect.defineMetadata('join_query', existingMeta, target.constructor);
	};
}
