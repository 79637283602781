import {MigrationInterface, QueryRunner} from "typeorm";

export class loanRelations1553593070311 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `hiddenLoans` (`loanProductId` int NOT NULL, `designId` int NOT NULL, PRIMARY KEY (`loanProductId`, `designId`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `leads` ADD `loanProviderId` int NULL");
        await queryRunner.query("ALTER TABLE `leads` ADD `loanProductId` int NULL");
        await queryRunner.query("CREATE INDEX `IDX_276d19ecd231b8c9f8e5d5e849` ON `loanProviders`(`name`)");
        await queryRunner.query("CREATE INDEX `IDX_6084567e968c1e32e5be8b6b92` ON `loanProducts`(`name`)");
        await queryRunner.query("CREATE INDEX `IDX_f5aa827a6a4f1f29940278412f` ON `documents`(`name`)");
        await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_ab7809626bc1da3df97e8e960b8` FOREIGN KEY (`loanProviderId`) REFERENCES `loanProviders`(`id`) ON DELETE RESTRICT");
        await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_333159633ed86767371917cbef1` FOREIGN KEY (`loanProductId`) REFERENCES `loanProducts`(`id`) ON DELETE RESTRICT");
        await queryRunner.query("ALTER TABLE `hiddenLoans` ADD CONSTRAINT `FK_fdd4fadc0216c2f8eca20aa756c` FOREIGN KEY (`loanProductId`) REFERENCES `loanProducts`(`id`) ON DELETE CASCADE");
        await queryRunner.query("ALTER TABLE `hiddenLoans` ADD CONSTRAINT `FK_c65d561645edca387aa4eb9800b` FOREIGN KEY (`designId`) REFERENCES `designs`(`id`) ON DELETE CASCADE");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `hiddenLoans` DROP FOREIGN KEY `FK_c65d561645edca387aa4eb9800b`");
        await queryRunner.query("ALTER TABLE `hiddenLoans` DROP FOREIGN KEY `FK_fdd4fadc0216c2f8eca20aa756c`");
        await queryRunner.query("ALTER TABLE `leads` DROP FOREIGN KEY `FK_333159633ed86767371917cbef1`");
        await queryRunner.query("ALTER TABLE `leads` DROP FOREIGN KEY `FK_ab7809626bc1da3df97e8e960b8`");
        await queryRunner.query("DROP INDEX `IDX_f5aa827a6a4f1f29940278412f` ON `documents`");
        await queryRunner.query("DROP INDEX `IDX_6084567e968c1e32e5be8b6b92` ON `loanProducts`");
        await queryRunner.query("DROP INDEX `IDX_276d19ecd231b8c9f8e5d5e849` ON `loanProviders`");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `loanProductId`");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `loanProviderId`");
        await queryRunner.query("DROP TABLE `hiddenLoans`");
    }

}
