import { Column, CreateDateColumn, Entity, JoinColumn, JoinTable, ManyToMany, ManyToOne, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';

import { FileMeta, User } from '.';

/*
[ ] Add to db.index.ts
*/

@Entity('siteSurveys')
export class SiteSurvey {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'companyName',
		nullable: true,
	})
	public companyName: string | undefined;
	
	@ManyToOne(type => User, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'userId'})
	user: User | undefined;
	
	@Column('varchar', {
		name: 'ahj',
		nullable: true
	})
	public ahj: any | undefined;
	
	@Column('varchar', {
		name: 'installType',
		nullable: true
	})
	public installType: 'roof' | 'ground' | undefined;
	
	@Column('varchar', {
		name: 'runConduit',
		nullable: true
	})
	public runConduit: 'roof' | 'attic' | undefined;
	
	@Column('varchar', {
		name: 'rmBrand',
		nullable: true
	})
	public rmBrand: string | undefined;
	
	@Column('varchar', {
		name: 'rmBoxRating',
		nullable: true
	})
	public rmBoxRating: '125' | '200' | undefined;
	
	@Column('varchar', {
		name: 'rmFeederConduit',
		nullable: true
	})
	public rmFeederConduit: 'over' | 'under' | undefined;
	
	@Column('varchar', {
		name: 'feederConduitSize',
		nullable: true
	})
	public feederConduitSize: string | undefined;
	
	@Column('varchar', {
		name: 'location',
		nullable: true
	})
	public location: 'wall' | 'pole' | undefined;
	
	@Column('varchar', {
		name: 'mspBrand',
		nullable: true
	})
	public mspBrand: string | undefined;
	
	@Column('varchar', {
		name: 'mspType',
		nullable: true
	})
	public mspType: 'lug' | 'breaker' | undefined;
	
	@Column('varchar', {
		name: 'mspMainBreaker',
		nullable: true
	})
	public mspMainBreaker: string | undefined;
	
	@Column('varchar', {
		name: 'mspBussBarSize',
		nullable: true
	})
	public mspBussBarSize: string | undefined;
	
	@Column('varchar', {
		name: 'mspFeederNippleSize',
		nullable: true
	})
	public mspFeederNippleSize: '1.25' | '2' | undefined;
	
	@Column('varchar', {
		name: 'mspFeederWireSize',
		nullable: true
	})
	public mspFeederWireSize: string | undefined;
	
	@Column('varchar', {
		name: 'gasMeterMSPDistance',
		nullable: true,
	})
	public gasMeterMSPDistance: string | undefined;
	
	@Column('varchar', {
		name: 'gasMeterREVDistance',
		nullable: true
	})
	public gasMeterREVDistance: string | undefined;
	
	@Column('varchar', {
		name: 'leftObstruction',
		nullable: true
	})
	public leftObstruction: string | undefined;
	
	@Column('varchar', {
		name: 'frontObstruction',
		nullable: true
	})
	public frontObstruction: string | undefined;
	
	@Column('varchar', {
		name: 'rightObstruction',
		nullable: true
	})
	public rightObstruction: string | undefined;
	
	@Column('varchar', {
		name: 'spPanelBrand',
		nullable: true
	})
	public spPanelBrand: string | undefined;
	
	@Column('varchar', {
		name: 'spFeederBreakerSize',
		nullable: true
	})
	public spFeederBreakerSize: string | undefined;
	
	@Column('varchar', {
		name: 'spBussBar',
		nullable: true
	})
	public spBussBar: string | undefined;
	
	@Column('varchar', {
		name: 'spFeederWireSize',
		nullable: true
	})
	public spFeederWireSize: string | undefined;
	
	@Column('varchar', {
		name: 'spFeederConduitSize',
		nullable: true
	})
	public spFeederConduitSize: string | undefined;
	
	@Column('varchar', {
		name: 'spOther',
		nullable: true,
		length: 1000
	})
	public spOther: string | undefined;
	
 	@Column('tinyint', {
		 name: 'hasGroundRod',
		 nullable: false
	 })
	 public hasGroundRod: boolean | undefined;

	@Column('int', {
		name: 'groundRodQty',
		nullable: true
	})
	public groundRodQty: number | undefined;
	 
	@Column('tinyint', {
		name: 'hasGasBond',
		nullable: true
	})
	public hasGasBond: boolean | undefined;

	@Column('varchar', {
		name: 'gasBondDist',
		nullable: true
	})
	public gasBondDist: string | undefined;
	
	@Column('tinyint', {
		name: 'hasWaterBond',
		nullable: true
	})
	public hasWaterBond: boolean | undefined;
	
	@Column('varchar', {
		name: 'waterBondDist',
		nullable: true
	})
	public waterBondDist: string | undefined;
	
	@Column('tinyint', {
		name: 'hasIntersystemBridge',
		nullable: true
	})
	public hasIntersystemBridge: boolean | undefined;
	
	@Column('boolean', {
		name: 'mspHasGroundBushing',
		nullable: true
	})
	public mspHasGroundBushing: boolean | undefined;
	
	@Column('varchar', {
		name: 'roofType',
		nullable: true
	})
	public roofType: string | undefined;
	
	@Column('varchar', {
		name: 'roofCondition',
		nullable: true
	})
	public roofCondition: 'good' | 'fair' | 'poor' | undefined;
	
	@Column('varchar', {
		name: 'stories',
		nullable: true
	})
	public stories: string | undefined;
	
	@Column('varchar', {
		name: 'roofOther',
		nullable: true
	})
	public roofOther: string | undefined;
	
	@Column('varchar', {
		name: 'pitchA',
		nullable: true
	})
	public pitchA: string | undefined;
	
	@Column('varchar', {
		name: 'pitchB',
		nullable: true
	})
	public pitchB: string | undefined;
	
	@Column('varchar', {
		name: 'pitchC',
		nullable: true
	})
	public pitchC: string | undefined;
	
	@Column('varchar', {
		name: 'pitchD',
		nullable: true
	})
	public pitchD: string | undefined;
	
	@Column('varchar', {
		name: 'pitchE',
		nullable: true
	})
	public pitchE: string | undefined;
	
	@Column('varchar', {
		name: 'pitchF',
		nullable: true
	})
	public pitchF: string | undefined;

	@Column('varchar', {
		name: 'azimuthA',
		nullable: true
	})
	public azimuthA: string | undefined;	
	
	@Column('varchar', {
		name: 'azimuthB',
		nullable: true
	})
	public azimuthB: string | undefined;	
	
	@Column('varchar', {
		name: 'azimuthC',
		nullable: true
	})
	public azimuthC: string | undefined;	
	
	@Column('varchar', {
		name: 'azimuthD',
		nullable: true
	})
	public azimuthD: string | undefined;	
	
	@Column('varchar', {
		name: 'azimuthE',
		nullable: true
	})
	public azimuthE: string | undefined;	
	
	@Column('varchar', {
		name: 'azimuthF',
		nullable: true
	})
	public azimuthF: string | undefined;	
	
	@Column('varchar', {
		name: 'treeShadingA',
		nullable: true
	})
	public treeShadingA: string | undefined;
	
	@Column('varchar', {
		name: 'treeShadingB',
		nullable: true
	})
	public treeShadingB: string | undefined;

	@Column('varchar', {
		name: 'treeShadingC',
		nullable: true
	})
	public treeShadingC: string | undefined;

	@Column('varchar', {
		name: 'treeShadingD',
		nullable: true
	})
	public treeShadingD: string | undefined;

	@Column('varchar', {
		name: 'treeShadingE',
		nullable: true
	})
	public treeShadingE: string | undefined;

	@Column('varchar', {
		name: 'treeShadingF',
		nullable: true
	})
	public treeShadingF: string | undefined;

	@Column('varchar', {
		name: 'pvWallSpaceLeft',
		nullable: true
	})
	public pvWallSpaceLeft: string | undefined;
	
	@Column('varchar', {
		name: 'pvWallSpaceRight',
		nullable: true
	})
	public pvWallSpaceRight: string | undefined;
	
	@Column('varchar', {
		name: 'pvEquipment',
		nullable: true
	})
	public pvEquipment: 'meter' | 'disconnect' | undefined;
	
	@Column('varchar', {
		name: 'interconnectionType',
		nullable: true
	})
	public interconnectionType: 'breaker' | 'gutter' | 'clamps' | undefined;
	
	@Column('varchar', {
		name: 'notes',
		nullable: true,
		length: 1000
	})
	public notes: string | undefined;

	@ManyToMany(type => FileMeta, {cascade: true})
	@JoinTable({
		name: 'siteSurveyFiles',
		joinColumn: {
			name: 'surveyId',
			referencedColumnName: 'id'
		},
		inverseJoinColumn: {
			name: 'fileId',
			referencedColumnName: 'id'
		}
	})
	files: FileMeta[] | undefined;
	
	@CreateDateColumn()
	createdAt: Date | undefined;
	
	@UpdateDateColumn()
	updatedAt: Date | undefined;
	
	constructor(args: Partial<SiteSurvey> = {}) {
		this.id = args.id;
		this.companyName = args.companyName;
		this.user = args.user ? new User(args.user) : undefined;
		this.ahj = args.ahj;
		this.installType = args.installType;
		this.runConduit = args.runConduit;
		this.rmBrand = args.rmBrand;
		this.rmBoxRating = args.rmBoxRating;
		this.rmFeederConduit = args.rmFeederConduit;
		this.feederConduitSize = args.feederConduitSize;
		this.location = args.location;
		this.mspBrand = args.mspBrand;
		this.mspType = args.mspType;
		this.mspMainBreaker = args.mspMainBreaker;
		this.mspBussBarSize = args.mspBussBarSize;
		this.mspFeederNippleSize = args.mspFeederNippleSize;
		this.mspFeederWireSize = args.mspFeederWireSize;
		this.gasMeterMSPDistance = args.gasMeterMSPDistance;
		this.gasMeterREVDistance = args.gasMeterREVDistance;
		this.leftObstruction = args.leftObstruction;
		this.frontObstruction = args.frontObstruction;
		this.rightObstruction = args.rightObstruction;
		this.spPanelBrand = args.spPanelBrand;
		this.spFeederBreakerSize = args.spFeederBreakerSize;
		this.spBussBar = args.spBussBar;
		this.spFeederWireSize = args.spFeederWireSize;
		this.spFeederConduitSize = args.spFeederConduitSize;
		this.spOther = args.spOther;
		this.hasGroundRod = args.hasGroundRod;
		this.groundRodQty = args.groundRodQty;
		this.hasGasBond = args.hasGasBond;
		this.hasWaterBond = args.hasWaterBond;
		this.hasIntersystemBridge = args.hasIntersystemBridge;
		this.mspHasGroundBushing = args.mspHasGroundBushing;
		this.gasBondDist = args.gasBondDist;
		this.waterBondDist = args.waterBondDist;
		this.roofType = args.roofType;
		this.roofCondition = args.roofCondition;
		this.stories = args.stories;
		this.roofOther = args.roofOther;
		this.pitchA = args.pitchA;
		this.pitchB = args.pitchB;
		this.pitchC = args.pitchC;
		this.pitchD = args.pitchD;
		this.pitchE = args.pitchE;
		this.pitchF = args.pitchF;
		this.azimuthA = args.azimuthA;
		this.azimuthB = args.azimuthB;
		this.azimuthC = args.azimuthC;
		this.azimuthD = args.azimuthD;
		this.azimuthE = args.azimuthE;
		this.azimuthF = args.azimuthF;
		this.treeShadingA = args.treeShadingA;
		this.treeShadingB = args.treeShadingB;
		this.treeShadingC = args.treeShadingC;
		this.treeShadingD = args.treeShadingD;
		this.treeShadingE = args.treeShadingE;
		this.treeShadingF = args.treeShadingF;
		this.pvWallSpaceLeft = args.pvWallSpaceLeft;
		this.pvWallSpaceRight = args.pvWallSpaceRight;
		this.pvEquipment = args.pvEquipment;
		this.interconnectionType = args.interconnectionType;
		this.files = args.files ? args.files.filter(f => f).map(f => new FileMeta(f)) : undefined;
		this.notes = args.notes;
		this.createdAt = args.createdAt ? new Date(args.createdAt) : undefined;
		this.updatedAt = args.updatedAt ? new Date(args.updatedAt) : undefined;
	}

	public getFile(type: string): FileMeta | undefined {
		if (!this.files || !this.files.length) return;
		const targetFile = this.files.find(lu => lu.fileType && lu.fileType.name === type);
		return targetFile;
	}

	public getFileUrl(type: string, env: any): string | undefined {
		const targetFile = this.getFile(type);
		if (targetFile) return targetFile.buildUrl(env);
	}
}