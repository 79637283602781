declare module 'global-extensions' {
	global {
		interface Array<T> {
			fold(size?: number , square?: boolean, squareWith?: any): Array<Array<T>>;
			fold(size?: Predicate<[T, number]>, square?: boolean, squareWith?: any): Array<Array<T>>;
			includesByProp<P extends keyof T>(prop: P, value: T[P] extends any[] ? T[P] | Predicate<T[P]> : T[P]): boolean;
			findByProp<P extends keyof T>(prop: P, value: T[P] extends any[] ? T[P] | Predicate<T[P]> : T[P]): T[P];
			findLast(predicate: Predicate<T[]>): T | undefined;
			insertAfter(insert: T, after: T): T[];
		}
	}
}

// @ts-ignore
type Predicate<T extends any[]> = (...args: T) => boolean;

if (!Array.prototype.fold) {
	Array.prototype.fold = function<T>(this: T[], size: number | Predicate<[T, number]> = 2, square = false, squareWith: any): T[] {
		const ret = [];
		const l = this.length;
		if (typeof size === 'number') {
			const newLen = Math.ceil(l / size);
			for (let i = 0; i < newLen; i++) {
				const si = Math.min(i * size, l);
				ret.push(this.slice(si, si + size));
			}
			if (square && ret[ret.length - 1] && ret[ret.length - 1].length !== size) {
				ret[ret.length - 1] = ret[ret.length - 1].concat(Array(size - ret[ret.length - 1].length).fill(squareWith));
			}
		} else {
			let ti = 0;
			let i = 0;
			let e: any;
			for ([i, e] of this.entries()) {
				if (size(e, i)) ti++;
				if (!ret[ti]) ret[ti] = [];
				ret[ti].push(e);
			}
			if (square) {
				while (!size(squareWith, ++i)) {
					ret[ti].push(squareWith);
				}
			}
		}
		return ret;
	};
}

if (!Array.prototype.includesByProp) {
	Array.prototype.includesByProp = function<T = any, P extends keyof T = any>(this: T[], prop: P, value: T[P] extends any[] ? T[P] | Predicate<T[P]> : T[P]): boolean {
		return (this.findIndex(item => {
			if (item.hasOwnProperty(prop)) {
				if (typeof value === 'function' && Array.isArray(item[prop])) {
					return (<any[]><unknown>item[prop]).find(<any>value);
				} else {
					return item[prop] === value;
				}
			} else {
				return false;
			}
		}) !== -1 );
	};
}

if (!Array.prototype.findByProp) {
	Array.prototype.findByProp = function<T = any, P extends keyof T = any>(this: T[], prop: P, value: T[P]): T {
		return this.find(item => {
			if (item && item.hasOwnProperty(prop)) {
				return item[prop] === value;
			} else {
				return false;
			}
		}) ;
	};
}

if (!Array.prototype.findLast) {
	Array.prototype.findLast = function<T = any>(this: T[], predicate: Predicate<T[]>): T | undefined {
		for (let i = this.length - 1; i >= 0; i--) {
			if (predicate(this[i])) return this[i];
		}
	};
}

if (!Array.prototype.insertAfter) {
	Array.prototype.insertAfter = function<T = any>(this: T[], insert: T, after: T): T[] {
		const targetIndex = this.indexOf(after);
		if (targetIndex === -1) return;
		return this.splice(targetIndex, 0, insert);
	};
}
