import { ThemePalettes } from 'pecms-shared';

import { Directive, Input, ElementRef, OnInit, HostBinding } from '@angular/core';
import { AppService } from 'app/app.service';
import { ThemePalette } from '@angular/material';

@Directive({
	selector: '[backgroundPalette], [foregroundPalette], [fillPalette], [theme]'
})
export class PaletteDirective {
	@Input()
	get foregroundPalette() { return this._foregroundPalette; }
	set foregroundPalette(val) { this._paletteSetter('foregroundPalette', val); }
	private _foregroundPalette: keyof ThemePalettes;
	@HostBinding('style.color')
	public bound_foregroundPalette: string;

	@Input()
	get backgroundPalette() { return this._backgroundPalette; }
	set backgroundPalette(val) { this._paletteSetter('backgroundPalette', val); }
	private _backgroundPalette: keyof ThemePalettes;
	@HostBinding('style.backgroundColor')
	public bound_backgroundPalette: string;

	@Input()
	get fillPalette() { return this._fillPalette; }
	set fillPalette(val) { this._paletteSetter('fillPalette', val); }
	private _fillPalette: keyof ThemePalettes;
	@HostBinding('style.fill')
	public bound_fillPalette: string;

	@Input() theme: keyof ThemePalettes;

	constructor(private appConf: AppService) {}

	private _paletteSetter = (propKey: keyof PaletteDirective, val: keyof ThemePalettes) => {
		if (!val) return;
		this['_' + propKey] = val as any;
		this['bound_' + propKey] = val.endsWith('.contrast') ?
			this.appConf.theme.getContrast(<any>val.split('.')[0]).hex :
			this.appConf.theme.getColor(val).hex;
	}
}
