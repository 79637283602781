import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import {QuickSale} from './quick-sale';
import {QuickSaleStatus} from './quick-sale-status';

@Entity('quickSaleStatusChanges')
@Index('quickSaleId', ['quickSale', ])
@Index('statusId', ['status', ])
export class QuickSaleStatusChange {

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@Column('datetime', {
		nullable: true,
		name: 'completedDate'
	})
	completedDate: Date | undefined;

	@ManyToOne(type => QuickSale, (quickSale: QuickSale) => quickSale.quickSaleStatusChanges, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'quickSaleId' })
	quickSale: QuickSale | undefined;

	@ManyToOne(
		type => QuickSaleStatus,
		(quickSaleStatus: QuickSaleStatus) => quickSaleStatus.quickSaleStatusChanges,
		{ onDelete: 'SET NULL', onUpdate: 'CASCADE' }
	)
	@JoinColumn({ name: 'statusId' })
	status: QuickSaleStatus | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: {[T in keyof QuickSaleStatusChange]?: QuickSaleStatusChange[T]} = {}) {
		this.id = args.id || undefined;
		this.quickSale = args.quickSale || undefined;
		this.status = args.status || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.completedDate = args.completedDate || undefined;
	}

}
