import { isJoiFailResponse } from 'app/_models/api-response';
import { AlertService, AuthService } from 'app/_services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private authSvc: AuthService,
		private alertSvc: AlertService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {
			if (err.status === 403) {
				this.authSvc.sessionExpired();
				// location.reload(true);
				return;
			}

			const response = err.error;

			if (err.status !== 404) {
				if (isJoiFailResponse(response)) {
					for (const detail of response.data.details || []) {
						this.alertSvc.error({title: detail.message});
					}
				} else if (err.error && err.error.data && err.error.data.message) {
					this.alertSvc.error({title: err.error.data.message});
				}
			}

			const error = (err.error && err.error.data) || err;
			return throwError(error);
		}));
	}
}
