import { AfterLoad, BeforeInsert, BeforeUpdate, Column, CreateDateColumn, Entity, Index, JoinColumn, JoinTable, ManyToMany, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn, UpdateDateColumn } from 'typeorm';
import { AdditionalCharge } from './additional-charge';
import { Comment } from './comment';
import { Design } from './design';
import { FileMeta } from './file';
import { HOA } from './hoa';
import { LeadAdder } from './lead-adders';
import { LeadPosition } from './lead-positions';
import { LeadStatus } from './lead-status';
import { LeadStatusChange } from './lead-status-changes';
import { LeadUser } from './lead-user';
import { LoanProduct } from './loan-product';
import { LoanProvider } from './loan-provider';
import { ProposalSettings } from './proposal-settings';
import { User } from './user';
import { JoinQuery, SelectorQuery } from './utilities';
import { Owner } from './utilities/protected.utilities';
import { Utility } from './utility';
import { LoanConfig } from './loan-config';
import { SiteSurvey } from '.';

@Entity('leads')
// @Index('userId', ['user', ], {unique: true})
@Index('leads_statusName_foreign_idx', ['status', ])
@Index('leads_designId_foreign_idx', ['design', ])
export class Lead {

	@SelectorQuery((qs) => `SELECT l.${qs.primaryColumn}, l.statusName, COALESCE(MAX(ls.createdAt), l.createdAt) AS customVal FROM leads l LEFT JOIN leadStatusChanges ls ON ls.leadId = l.${qs.primaryColumn} GROUP BY l.${qs.primaryColumn}`)
	get statusDate() {
		if (!this._statusDate) {
			if (!this.statusChanges || !this.statusChanges.length) return this.createdAt;
			for (let i = this.statusChanges.length - 1; i >= 0; i--) {
				if (this.statusChanges[i].leadStatus!.name === (this.status && this.status.name)) {
					this._statusDate = this.statusChanges[i].createdAt;
					break;
				}
			}
		}
		return this._statusDate;
	}

	get validatedStatuses(): LeadStatusChange[] {
		if (!this.statusChanges) return [];
		let highestOrder = 0;
		return Object.values(this.statusChanges.reduce((vcs, sc) => {
			const order = sc.leadStatus && sc.leadStatus.order;
			if (!order) return vcs;
			if (order > highestOrder) {
				vcs[order] = sc;
				highestOrder = order;
			} else if (order < highestOrder) {
				vcs.splice(order + 1);
				if (!vcs[order])vcs[order] = sc;
				highestOrder = order;
			}
			return vcs;
		}, <LeadStatusChange[]>[]));
	}

	get salesmanAdjustment() {
		const targetAdder = this.getAdder('salesmanAdjustment');
		return targetAdder ? targetAdder.amount : undefined;
	}

	set salesmanAdjustment(val: number | undefined) {
		const existingAdder = this.getAdder('salesmanAdjustment');
		if (!existingAdder) {
			if (!this.adders) this.adders = [];
			this.adders.push(new LeadAdder({name: 'salesmanAdjustment', type: 'per-watt', amount: val}));
		} else {
			existingAdder.amount = val;
		}
	}

	get notes() {
		if (this._notes === undefined) {
			const notesComment = this.comments && this.comments.find(c => c.tags && c.tags.some(ct => ct.name === 'CustomerQuestions'));
			this._notes = notesComment ? notesComment.content : null;
		}
		return this._notes;
	}

	get extraComments() {
		if (this._extraComments === undefined) {
			this._extraComments = this.comments ? this.comments.filter(c => !c.tags || !c.tags.some(t => t.name === 'CustomerQuestions')) : [];
		}
		return this._extraComments;
	}

	public get commentCount() { return this.extraComments ? this.extraComments.length : 0; }

	public get statusName() { return this.status && this.status.name; }
	public set statusName(val: string | undefined) { this.status = val ? new LeadStatus({name: val}) : undefined; }

	@JoinQuery('leadStatusChanges', 'pureApprovedStatus', (qs) => `pureApprovedStatus.id = (SELECT id FROM leadStatusChanges WHERE leadStatusChanges.leadId = ${qs.tableAlias}.id AND leadStatusChanges.leadStatusName = 'Pure Approved' ORDER BY leadStatusChanges.createdAt DESC LIMIT 1)`)
	public get pureApprovedStatus() {
		return this.validatedStatuses.find(s => s.leadStatus && s.leadStatus.name === 'Pure Approved');
	}

	@JoinQuery('users', 'salesrep', (qs) => `salesrep.id = (SELECT userId FROM leadUsers WHERE leadUsers.leadId = ${qs.tableAlias}.id AND leadUsers.positionName = 'Sales Rep' LIMIT 1)`)
	public get salesRep() {
		if (this._salesRep === undefined) this._salesRep = this.getUser('Sales Rep') || null;
		return this._salesRep;
	}
	public set salesRep(val) {
		this.setUser(<User>val, 'Sales Rep');
		this._salesRep = val;
	}

	@JoinQuery('users', 'customer', (qs) => `customer.id = (SELECT userId FROM leadUsers WHERE leadUsers.leadId = ${qs.tableAlias}.id AND leadUsers.positionName = 'Customer' LIMIT 1)`)
	public get user() {
		if (this._user === undefined) this._user = this.getUser('Customer') || null;
		return this._user;
	}
	public set user(val) {
		this.setUser(<User>val, 'Customer');
		this._user = val;
	}

	@JoinQuery('users', 'primaryrep', (qs) => `primaryrep.id = (SELECT userId FROM leadUsers WHERE leadUsers.leadId = ${qs.tableAlias}.id AND leadUsers.positionName IN ('Sales Rep', 'Closer') ORDER BY leadUsers.positionName LIMIT 1)`)
	public get primaryRep() {
		if (this._primaryRep === undefined) this._primaryRep = this.getUser('Closer') || this.getUser('Sales Rep') || null;
		return this._primaryRep;
	}
	public set primaryRep(val) {
		this.closer ? this.closer = val : this.salesRep = val;
		this._primaryRep = val;
	}

	@JoinQuery('users', 'secondaryrep', (qs) => `secondaryrep.id = (SELECT userId FROM leadUsers WHERE leadUsers.leadId = ${qs.tableAlias}.id AND leadUsers.positionName IN ('Sales Rep', 'Closer') ORDER BY leadUsers.positionName LIMIT 1 OFFSET 1)`)
	public get secondaryRep(): User | null {
		if (this._secondaryRep === undefined) this._secondaryRep = this.getUser('Closer') ? this.getUser('Sales Rep') : null;
		return <User | null>this._secondaryRep;
	}
	public set secondaryRep(val) {
		if (!this.closer) this.closer = this.salesRep;
		this.salesRep = val;
		this._secondaryRep = val;
	}

	@JoinQuery('users', 'closer', (qs) => `closer.id = (SELECT userId FROM leadUsers WHERE leadUsers.leadId = ${qs.tableAlias}.id AND leadUsers.positionName = 'Closer' LIMIT 1)`)
	public get closer() {
		if (this._closer === undefined) this._closer = this.getUser('Closer') || null;
		return this._closer;
	}
	public set closer(val) {
		this.setUser(<User>val, 'Closer');
		this._closer = val;
	}

	public get hasDocs(): boolean {
		return this.utility ? this.utility.hasDocs : false;
	}

	public get cash(): boolean {
		return !!(this.loanProvider && this.loanProvider.id === 1);
	}

	constructor(args: Partial<Lead> = {}) {
		if (args.design && args.design.lead) delete args.design.lead;

		this.id = args.id || undefined;
		this.discountType = args.discountType || undefined;
		this.status = args.status ? new LeadStatus(args.status) : undefined;
		this.design = args.design ? new Design(args.design) : undefined;
		this.siteSurvey = args.siteSurvey ? new SiteSurvey(args.siteSurvey) : undefined;
		this.settings = args.settings ? new ProposalSettings(args.settings) : undefined;
		this.utility = args.utility ? new Utility(args.utility) : undefined;
		this.hoa = args.hoa ? new HOA(args.hoa) : undefined;
		this.utilityAccount = args.utilityAccount;
		this.phone = args.phone || undefined;
		this.addr1 = args.addr1 || undefined;
		this.addr2 = args.addr2 || undefined;
		this.gateCode = args.gateCode;
		this.city = args.city || undefined;
		this.state = args.state || undefined;
		this.lat = args.lat || undefined;
		this.lng = args.lng || undefined;
		this.environmentVsMoney = args.environmentVsMoney || 2;
		this.zip = args.zip || undefined;
		this.prequalifier = args.prequalifier || undefined;
		this.prequalified = args.prequalified || undefined;
		this.prequalProjectRef = args.prequalProjectRef || undefined;
		this.prequalUserRef = args.prequalUserRef || undefined;
		this.providerProjectRef = args.providerProjectRef || undefined;
		this.providerUserRef = args.providerUserRef || undefined;
		this.loanStatus = args.loanStatus || undefined;
		this.maxOutRoof = args.maxOutRoof || undefined;
		this.squareFootage = args.squareFootage || undefined;
		this.createdAt = new Date(<any>args.createdAt) || undefined;
		this.updatedAt = new Date(<any>args.updatedAt) || undefined;
		this.digitalBusinessAgreementDate = args.digitalBusinessAgreementDate || undefined;
		this.loanProvider = args.loanProvider ? new LoanProvider(args.loanProvider) : undefined;
		this.loanProduct = args.loanProduct ? new LoanProduct(args.loanProduct) : undefined;
		this.adders = args.adders ? args.adders.map(a => new LeadAdder(a)) : undefined;
		this.additionalCharges = args.additionalCharges ? args.additionalCharges.map(a => new AdditionalCharge(a)) : undefined;
		this.loanConfigs = args.loanConfigs ? args.loanConfigs.map(a => new LoanConfig(a)) : undefined;
		this.leadUsers = args.leadUsers ? args.leadUsers.map(lu => new LeadUser(lu)) : undefined;
		this.files = args.files ? args.files.filter(f => f).map(f => new FileMeta(f)) : undefined;
		this.comments = args.comments ? args.comments.map(c => new Comment(c)) : undefined;
		this.statusChanges = args.statusChanges ? args.statusChanges.map(sc => new LeadStatusChange(sc)) : undefined;

		this.afterLoad();
	}

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@OneToMany(type => LeadStatusChange, (leadStatusChange: LeadStatusChange) => leadStatusChange.lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE', cascade: true })
	statusChanges: LeadStatusChange[] | undefined;

	private _statusDate: Date | undefined;

	@OneToMany(type => LeadAdder, (leadAdder: LeadAdder) => leadAdder.lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	adders: LeadAdder[] | undefined;

	@OneToMany(type => AdditionalCharge, (additionCharge: AdditionalCharge) => additionCharge.lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE', cascade: true })
	additionalCharges: AdditionalCharge[] | undefined;

	@OneToMany(type => LoanConfig, (loanConfig: LoanConfig) => loanConfig.lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	loanConfigs: LoanConfig[] | undefined;

	@OneToMany(type => Comment, (comment: Comment) => comment.lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE'})
	comments: Comment[] | undefined;
	private _notes: string | null | undefined;
	private _extraComments: Comment[] | undefined;

	@ManyToOne(type => LeadStatus, (leadStatus: LeadStatus) => leadStatus.leads, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'statusName' })
	status: LeadStatus | undefined;

	@OneToMany(type => LeadUser, leadUser => leadUser.lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE', cascade: ['insert']})
	@Owner({path: ['user']})
	public leadUsers: LeadUser[] | undefined;

	private _salesRep: User | undefined | null;

	private _user: User | undefined | null;

	private _primaryRep: User | undefined | null;
	
	private _secondaryRep: User | undefined | null;

	private _closer: User | undefined | null;

	@ManyToOne(type => Design, (design: Design) => design.lead, { cascade: ['update'], onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'designId' })
	design: Design | undefined;

	@ManyToOne(type => SiteSurvey, { cascade: ['update'], onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'siteSurveyId' })
	siteSurvey: SiteSurvey | undefined;

	@OneToOne(type => ProposalSettings, (proposalSettings: ProposalSettings) => proposalSettings.lead)
	settings: ProposalSettings | undefined;

	@ManyToOne(type => LoanProvider, (loanProvider: LoanProvider) => loanProvider.leads, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'loanProviderId'})
	loanProvider: LoanProvider | undefined;

	@ManyToOne(type => LoanProduct, (loanProduct: LoanProduct) => loanProduct.leads, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'loanProductId'})
	loanProduct: LoanProduct | undefined;

	@ManyToOne(type => HOA, (hoa: HOA) => hoa.leads, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'hoaId'})
	hoa: HOA | undefined;

	@ManyToOne(type => Utility, (utility: Utility) => utility.leads, { onDelete: 'RESTRICT', onUpdate: 'CASCADE'})
	@JoinColumn({name: 'utilityName', referencedColumnName: 'name'})
	utility: Utility | undefined;

	@ManyToMany(type => FileMeta, {cascade: true})
	@JoinTable({
		name: 'leadFiles',
		joinColumn: {
			name: 'leadId',
			referencedColumnName: 'id'
		},
		inverseJoinColumn: {
			name: 'fileId',
			referencedColumnName: 'id'
		}
	})
	files: FileMeta[] | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'utilityAccount'
	})
	utilityAccount: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'phone'
	})
	phone: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'addr1'
	})
	addr1: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'addr2'
	})
	addr2: string | undefined;

	@Column('varchar', {
		name: 'gateCode',
		nullable: true,
		// length: 255
	})
	public gateCode: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'city'
	})
	city: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'state'
	})
	state: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'zip'
	})
	zip: string | undefined;

	@Column('decimal', {
		nullable: true,
		precision: 10,
		scale: 7,
		name: 'lat'
	})
	lat: number | undefined;

	@Column('decimal', {
		nullable: true,
		precision: 10,
		scale: 7,
		name: 'lng'
	})
	lng: number | undefined;

	// TODO: convert prequalification to related table

	@Column('varchar', {
		nullable: true,
		length: 50,
		name: 'prequalifier'
	})
	prequalifier: string | undefined;

	@Column('datetime', {
		nullable: true,
		name: 'prequalDate'
	})
	prequalDate: Date | undefined;

	@Column('tinyint', {
		nullable: true,
		name: 'prequalified'
	})
	prequalified: boolean | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'prequalProjectRef'
	})
	prequalProjectRef: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'prequalUserRef'
	})
	prequalUserRef: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'loanStatus'
	})
	loanStatus: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'providerProjectRef'
	})
	providerProjectRef: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'providerUserRef'
	})
	providerUserRef: string | undefined;

	@Column('date', {
		nullable: true,
		name: 'digitalBusinessAgreementDate'
	})
	digitalBusinessAgreementDate: Date | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'discountType'
	})
	discountType: string | undefined;

	@Column('integer', {
		nullable: true,
	})
	environmentVsMoney: number | undefined;

	@Column('tinyint', {
		nullable: false,
		default: 0
	})
	maxOutRoof: boolean | undefined;

	@Column('integer', {
		nullable: true,
	})
	squareFootage: number | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	getAdder(name: string) {
		return this.adders && this.adders.find(a => a.name === name);
	}

	public initializeDesign(): Design {
		const targetTeam = this.salesRep && this.salesRep.employee ? this.salesRep.employee.team : undefined;
		this.design = new Design({
			baseCostPerWatt: this.utility && this.utility.baseCostPerWatt || 4,
			utilityRebate: this.utility && this.utility.rebate,
			team: targetTeam,
			discount: targetTeam ? targetTeam.discount : 3000,
			productionFactor: targetTeam ? targetTeam.productionFactor : undefined,
			rebatePercentage: .26,
			lead: this,
			panel: targetTeam ? targetTeam.defaultPanel : undefined
		});

		return this.design;
	}

	public getUser(position: string): User | undefined {
		if (!this.leadUsers || !this.leadUsers.length) return;
		const targetLeadUser = this.leadUsers.find(lu => (lu.position && lu.position.name === position));
		if (!targetLeadUser || !targetLeadUser.user) return;
		return targetLeadUser.user;
	}

	public getFile(type: string): FileMeta | undefined {
		if (!this.files || !this.files.length) return;
		const targetFile = this.files.find(lu => lu.fileType && lu.fileType.name === type);
		return targetFile;
	}

	public getFileUrl(type: string, env: any): string | undefined {
		const targetFile = this.getFile(type);
		if (targetFile) return targetFile.buildUrl(env);
	}

	public setUser(user: User, position: string): void {
		if (!this.leadUsers) this.leadUsers = [];
		const targetLeadUserRef = this.leadUsers.find(lu => lu.positionName === position);
		if (position === 'Sales Rep') this._salesRep = user;
		if (position === 'Closer') this._closer = user;
		if (!targetLeadUserRef) {
			this.leadUsers.push(new LeadUser({user, position: new LeadPosition(position)}));
		} else {
			targetLeadUserRef.user = user;
		}
	}

	@AfterLoad()
	protected afterLoad() {
		if (this.design && !this.design.lead) this.design.lead = this;
		if (this.statusChanges) this.statusChanges = this.statusChanges.sort((sc1, sc2) => (sc1 && Number(sc1.createdAt) || 0) - (sc2 ? Number(sc2.createdAt) : 0))
		this.updateStatus();
	}

	@BeforeInsert()
	@BeforeUpdate()
	protected updateStatus() {
	}

	dateForStatus(name: string, format: string): string | undefined;
	dateForStatus(name: string): Date | undefined;
	dateForStatus(name: string, format?: string): string | Date | undefined {
		const moment = require('moment');

		const targetStatusChange = this.validatedStatuses.find(s => s.leadStatus && s.leadStatus.name && s.leadStatus.name.toLowerCase() === name.toLowerCase());
		if (targetStatusChange) {
			return format ? <any>moment(targetStatusChange.createdAt).format(format) : targetStatusChange.createdAt;
		}
	}

	hasStatus(name: string): boolean {
		return !!this.validatedStatuses.find(s => s.leadStatus && s.leadStatus.name && s.leadStatus.name.toLowerCase() === name.toLowerCase());
	}

	public toPost() {
		return {
			leadUsers: this.leadUsers,
			files: this.files,
			phone: this.phone,
			addr1: this.addr1,
			addr2: this.addr2,
			city: this.city,
			state: this.state,
			zip: this.zip,
			maxOutRoof: this.maxOutRoof,
			squareFootage: this.squareFootage
		};
	}

	toJSON() {
		const retObj = Object.assign({}, this, {design: this.design ? Object.assign({}, this.design) : undefined});
		if ((this.design && this.design.lead)) delete (<Design>retObj.design).lead;
		this.leadUsers && this.leadUsers.map(lu => lu.toJSON());
		this.statusChanges && this.statusChanges.map(sc => sc.toJSON());
		return retObj;
	}
}
