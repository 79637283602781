import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import {Company} from './company';
import {Employee} from './employee';
import {Document} from './document';
import { Panel } from './panel';
import { Owner } from './utilities';
import { Scopes } from './rbac-scope.enum';

@Entity('teams')
@Index('companyId', ['company', ])
@Owner({scope: Scopes.TEAM})
export class Team {

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@ManyToMany(type => Document, document => document.teams)
	@JoinTable({
		name: 'documentConditions',
		joinColumn: {
			name: 'documentId',
			referencedColumnName: 'id'
		},
		inverseJoinColumn: {
			name: 'teamId',
			referencedColumnName: 'id'
		}
	})
	documents: Document[] | undefined;

	@Column('varchar', {
		nullable: false,
		length: 255,
		name: 'name'
	})
	name: string | undefined;

	@Column('int', {
		nullable: false,
		name: 'productionFactor',
		default: 1500
	})
	productionFactor: number | undefined;

	@ManyToOne(type => Panel, (panel: Panel) => panel.teams, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'defaultPanelId'})
	defaultPanel: Panel | undefined;

	@Column('decimal', {
		name: 'discount',
		nullable: true,
		default: 3000,
		precision: 7,
		scale: 2
	})
	public discount: number | undefined;

	@ManyToOne(type => Company, (company: Company) => company.teams, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'companyId' })
	company: Company | undefined;

	@Column()
	get companyId() { return this.company && this.company.id ? this.company.id : 0; }
	set companyId(val) {
		if (!this.company) this.company = new Company();
		this.company.id = val;
	}

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	@OneToOne(type => Employee, (employee: Employee) => employee.team, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	employees: Employee | undefined;

	constructor(args: {[T in keyof Team]?: Team[T]} = {}) {
		this.id = args.id || undefined;
		this.discount = args.discount;
		this.name = args.name || undefined;
		this.company = args.company || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.employees = args.employees || undefined;
		this.productionFactor = args.productionFactor || undefined;
		this.defaultPanel = args.defaultPanel ? new Panel(args.defaultPanel) : undefined;
		if (args.companyId) this.companyId = args.companyId;
	}

}
