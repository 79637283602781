import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, Index, ManyToOne, JoinColumn, ManyToMany, JoinTable } from 'typeorm';
import { User } from './user';
import { Lead } from './lead';
import { CommentTag } from './comment-tag';

@Entity('comments')
export class Comment {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'content',
		nullable: false,
		length: 1000
	})
	@Index({ fulltext: true })
	public content: string | undefined;

	@ManyToOne(type => User, (user: User) => user.comments, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'createdById'})
	createdBy: User | undefined;

	@ManyToOne(type => Lead, (lead: Lead) => lead.comments, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	lead: Lead | undefined;

	@ManyToMany(type => CommentTag, commentTag => commentTag.comments, { onDelete: 'CASCADE', onUpdate: 'CASCADE'})
	@JoinTable({
		name: 'comment_commentTags',
		inverseJoinColumn: {name: 'commentTagName', referencedColumnName: 'name'},
		joinColumn: {name: 'commentId', referencedColumnName: 'id'}
	})
	tags: CommentTag[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<Comment> = {}) {
		this.id = args.id;
		this.content = args.content;
		this.createdBy = args.createdBy ? new User(args.createdBy) : undefined;
		this.createdAt = args.createdAt ? new Date(args.createdAt) : undefined;
		this.updatedAt = args.updatedAt ? new Date(args.updatedAt) : undefined;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.tags = args.tags ? args.tags.map(t => new CommentTag(t)) : undefined;
	}
}
