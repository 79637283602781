import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import {RoleNotification} from './role-notification';
import { Privileges } from './privileges';
import { User } from './user';

@Entity('roles')
@Index('name', ['name', ], {unique: true})
export class Role {

	constructor(args: Partial<Role> = {}) {
		this.id = args.id || undefined;
		this.name = args.name || undefined;
		this.privileges = args.privileges ? new Privileges(args.privileges) : new Privileges();
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.users = args.users ? args.users.map(u => new User(u)) : undefined;
		this.roleNotifications = args.roleNotifications ? args.roleNotifications.map(rn => new RoleNotification(rn)) : undefined;
	}

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@Column('varchar', {
		nullable: true,
		unique: true,
		length: 255,
		name: 'name'
	})
	name: string | undefined;

	@Column(type => Privileges, {prefix: ''})
	privileges: Privileges;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	@OneToMany(type => RoleNotification, (roleNotification: RoleNotification) => roleNotification.role, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	roleNotifications: RoleNotification[] | undefined;

	@ManyToMany(type => User, { onDelete: 'CASCADE', onUpdate: 'CASCADE' })
	@JoinTable({
		name: 'user_roles',
		joinColumn: {name: 'roleId'},
		inverseJoinColumn: {name: 'loginId'}
	})
	users: User[] | undefined;

	public static combinePrivileges(roles: Role[]): Privileges {
		return roles.reduce((combinedPrivileges, role) => {
			for (const [key, val] of Object.entries(role.privileges)) combinedPrivileges[<keyof Privileges>key] |= val;
			return combinedPrivileges;
		}, new Privileges());
	}

}
