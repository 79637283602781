
import {
	Directive,
	Input,
	Output,
	EventEmitter,
	HostBinding,
	HostListener,
	ViewContainerRef
} from '@angular/core';

@Directive({ selector: '[longPress]' })
export class LongPressDirective {

	@Input() duration = 500;

	@Output() longPress: EventEmitter<any> = new EventEmitter();
	@Output() longPressing: EventEmitter<any> = new EventEmitter();
	@Output() longPressEnd: EventEmitter<any> = new EventEmitter();

	private pressing: boolean;
	private isLongPressing: boolean;
	private timeout: any;
	private mouseX = 0;
	private mouseY = 0;
	private hostComponent: any;


	@HostBinding('class.press')
	get pressClass() { return this.pressing; }

	@HostBinding('class.longpress')
	get longPressClass() { return this.isLongPressing; }

	@HostListener('mousedown', ['$event'])
	onMouseDown(event) {
		if (event.which !== 1) return;

		this.mouseX = event.clientX;
		this.mouseY = event.clientY;

		this.pressing = true;
		this.isLongPressing = false;

		this.timeout = setTimeout(() => {
			this.isLongPressing = true;
			this.longPress.emit(event);
			this.loop(event);
		}, this.duration);

		this.loop(event);
	}

	@HostListener('mousemove', ['$event'])
	onMouseMove(event) {
		if (this.pressing && !this.isLongPressing) {
				const xThres = (event.clientX - this.mouseX) > 10;
				const yThres = (event.clientY - this.mouseY) > 10;
			if (xThres || yThres) {
				this.endPress();
			}
		}
	}

	constructor(
		viewContainerRef: ViewContainerRef
	) {
		this.hostComponent = viewContainerRef['_data'].componentView.component;
	}

	protected get hostDisabled() {
		return this.hostComponent && this.hostComponent.disabled;
	}

	loop(event) {
		if (this.isLongPressing && !this.hostDisabled) {
			this.timeout = setTimeout(() => {
				this.longPressing.emit(event);
				this.loop(event);
			}, 50);
	 	 }
	}

	endPress() {
		clearTimeout(this.timeout);
		this.isLongPressing = false;
		this.pressing = false;
		this.longPressEnd.emit(true);
	}

	@HostListener('mouseup')
	onMouseUp() { this.endPress(); }

}
