export interface RGB {
	r: number;
	g: number;
	b: number;
	a?: number;
}

export interface HSL {
	h: number;
	s: number;
	l: number;
}

export class Color {
	public r = 0;
	public g = 0;
	public b = 0;
	public a?: number;

	static fromHex(HexString: string): RGB {
		const RGBObj = { r: 0, g: 0, b: 0 };
		if (HexString[0] === '#') {
			HexString = HexString.slice(1);
		}
		if (HexString.length === 6) {
			RGBObj.r = parseInt(HexString.slice(0, 2), 16);
			RGBObj.g = parseInt(HexString.slice(2, 4), 16);
			RGBObj.b = parseInt(HexString.slice(4), 16);
		} else if (HexString.length === 3) {
			RGBObj.r = parseInt(HexString.slice(0, 1), 16) * 17; // * 17 because f -> ff
			RGBObj.g = parseInt(HexString.slice(1, 2), 16) * 17; // * 17 because f -> ff
			RGBObj.b = parseInt(HexString.slice(2), 16) * 17; // * 17 because f -> ff
		}
		return RGBObj;
	}

	static fromHSL(hsl: {h: number, s: number, l: number}): RGB {
		const rgbArr = [];

		let defG = hsl.h / 360;
		let defR = defG + .333;
		let defB = defG - .333;

		defR < 1 && defR++;
		defG < 1 && defG++;
		defB < 1 && defB++;
		defR > 1 && defR--;
		defG > 1 && defG--;
		defB > 1 && defB--;

		let defFactor: number;
		if (hsl.l < 50) {
			defFactor = (hsl.l / 100) * (1 + (hsl.s / 100));
		} else {
			defFactor = ((hsl.l / 100) + (hsl.s / 100) - (hsl.l / 100) * (hsl.s / 100));
		}
		const def: number = (2 * (hsl.l / 100) - defFactor);

		for (const channel of [defR, defG, defB]) {
			if ((6 * channel) < 1) {
				rgbArr.push(def + (defFactor - def) * 6 * channel);
			} else if ((2 * channel) < 1) {
				rgbArr.push(defFactor);
			} else if ((3 * channel) < 2) {
				rgbArr.push(def + (defFactor - def) * 6 * (((2 / 3) - channel)));
			} else {
				rgbArr.push(def);
			}
		}
		return {r: Math.round(rgbArr[0] * 255), g: Math.round(rgbArr[1] * 255), b: Math.round(rgbArr[2] * 255)};
	}

	static toHex(RGBVal: number): string {
		return (RGBVal.toString(16).length === 1 ? ('0' + RGBVal.toString(16)) : RGBVal.toString(16));
	}

	constructor(rgb: [number, number, number])
	constructor(rgba: [number, number, number, number])
	constructor(rgb: RGB)
	constructor(hsl: HSL)
	constructor(hex: string)
	constructor(rgb?: [number, number, number] | [number, number, number, number] | RGB | HSL | string) {
		if (!rgb) {
			this.r = 0;
			this.g = 0;
			this.b = 0;
			this.a = 1;
		} else if (rgb instanceof Array) {
			this.r = rgb[0] || 0;
			this.g = rgb[1] || 0;
			this.b = rgb[2] || 0;
			this.a = (rgb.length > 3 ? rgb[3] : 1);
		} else if (typeof rgb === 'string') {
			if (rgb.startsWith('rgb')) return new Color(<[number, number, number]>(<string[]>rgb.match(/([0-9]+)/g)).map((v, i) => parseInt(v, 10)));
			const parsedRGB = Color.fromHex(rgb);
			this.r = parsedRGB.r || 0;
			this.g = parsedRGB.g || 0;
			this.b = parsedRGB.b || 0;
			this.a = 1;
		} else if (typeof (rgb) === 'object') {
			if (this.isRGB(rgb)) {
				this.r = rgb.r || 0;
				this.g = rgb.g || 0;
				this.b = rgb.b || 0;
				this.a = rgb.a ? rgb.a : 1;
			} else {
				const parsedRGB = Color.fromHSL(rgb);
				this.r = parsedRGB.r || 0;
				this.g = parsedRGB.g || 0;
				this.b = parsedRGB.b || 0;
			}
		}
	}

	get hex(): string {
		const hexArr = ['#'];
		hexArr.push(this.cleanHex);
		return hexArr.join('');
	}

	get cleanHex(): string {
		const hexArr = [Color.toHex(this.r)];
		hexArr.push(Color.toHex(this.g));
		hexArr.push(Color.toHex(this.b));
		return hexArr.join('');
	}

	get hexNum(): number {
		return parseInt(Color.toHex(this.r) + Color.toHex(this.g) + Color.toHex(this.b), 16);
	}

	get rgb(): string {
		const rgbArr = [this.r, this.g, this.b];
		if (this.a && this.a < 1) rgbArr.push(this.a);
		return 'rgb(' + rgbArr.join(', ') + ')';
	}

	get hsl(): {h: number, s: number, l: number} {
		const result = {h: 0, s: 0, l: 0};
		if (!this.r && !this.g && !this.b) return result;
		const rgbArr: [number, number, number] = [this.r, this.g, this.b];
		result.l = this.calcLuminance(rgbArr);
		result.s = this.calcSaturation(rgbArr, result.l);
		result.h = this.calcHue(rgbArr);
		return result;
	}

	get luma() {
		return ((3 * this.r) + this.b + (4 * this.g)) >> 3;
	}

	get compliment() {
		const hsl = this.hsl;
		hsl.h = Math.abs(hsl.h - 180);
		return new Color(hsl);
	}

	public toString() {
		return this.hex;
	}

	private calcLuminance(rgbArr: [number, number, number]): number {
		return Math.round((((Math.max(...rgbArr) / 255) + (Math.min(...rgbArr) / 255))) / 2 * 100);
	}

	private calcSaturation(rgbArr: [number, number, number], luminance: number): number {
		const min = Math.min(...rgbArr);
		const max = Math.max(...rgbArr);
		let percentDec: number;
		if (luminance <= .5) {
			percentDec = (max - min) / (max + min);
		} else {
			percentDec = (max - min) / (2 - max - min);
		}
		return Math.abs(Math.round(percentDec) * 100);
	}

	private calcHue(rgbArr: [number, number, number]) {
		let hueDegrees: number;
		const max = Math.max(...rgbArr);
		const min = Math.min(...rgbArr);

		switch (rgbArr.indexOf(max)) {
			case 0:
				hueDegrees = (rgbArr[1] - rgbArr[2]) / (max - min);
				break;
			case 1:
				hueDegrees =  2 + (rgbArr[2] - rgbArr[0]) / (max - min);
				break;
			default:
				hueDegrees =  4 + (rgbArr[0] - rgbArr[1]) / (max - min);
				break;
		}
		return Math.round(hueDegrees * 60);
	}

	private isRGB(rgb: RGB | HSL): rgb is RGB {
		return (<RGB>rgb).r !== undefined;
	}
	private isHSL(hsl: RGB | HSL): hsl is HSL {
		return (<HSL>hsl).h !== undefined;
	}
}
