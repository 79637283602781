import {MigrationInterface, QueryRunner} from 'typeorm';

export class fileChanges1564001930562 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query('CREATE TABLE `fileTypes` (`name` varchar(55) NOT NULL, `public` tinyint NOT NULL DEFAULT 0, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`name`)) ENGINE=InnoDB');
        await queryRunner.query('CREATE TABLE `files` (`id` int NOT NULL AUTO_INCREMENT, `name` varchar(55) NOT NULL, `mimeType` varchar(255) NOT NULL, `signature` varchar(1000) NOT NULL, `complete` tinyint NOT NULL DEFAULT 0, `groupId` int NULL, `size` int NULL,`createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `fileTypeName` varchar(55) NULL, `bucketName` varchar(63) NOT NULL, `createdById` int NULL, INDEX `IDX_dbd64f45bf3535f311a03207d3` (`groupId`), PRIMARY KEY (`id`)) ENGINE=InnoDB');
        await queryRunner.query('ALTER TABLE `files` ADD CONSTRAINT `FK_f2e4c63f602101bb8c8f0c01e4e` FOREIGN KEY (`fileTypeName`) REFERENCES `fileTypes`(`name`) ON DELETE RESTRICT ON UPDATE CASCADE');
        await queryRunner.query('ALTER TABLE `files` ADD CONSTRAINT `FK_c890bf2b3b7236a9255c79cd961` FOREIGN KEY (`createdById`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE');
        await queryRunner.query('INSERT INTO `fileTypes` (`name`, `public`) VALUES (\'Miscellaneous\', 0), (\'Power Bill\', 0), (\'ID\', 0), (\'Design Image\', 0);');
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query('ALTER TABLE `files` DROP FOREIGN KEY `FK_c890bf2b3b7236a9255c79cd961`');
        await queryRunner.query('ALTER TABLE `files` DROP FOREIGN KEY `FK_f2e4c63f602101bb8c8f0c01e4e`');
        await queryRunner.query('DROP INDEX `IDX_dbd64f45bf3535f311a03207d3` ON `files`');
        await queryRunner.query('DROP TABLE `files`');
        await queryRunner.query('DROP TABLE `fileTypes`');
    }

}
