import {MigrationInterface, QueryRunner} from 'typeorm';

export class statusPrivs1569604102957 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `roles` ADD `leadStatuses` bit(8) NOT NULL DEFAULT 0');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `roles` DROP COLUMN `leadStatuses`');
	}

}
