import {MigrationInterface, QueryRunner} from 'typeorm';

export class loanpalUpldates1571843935244 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `loanProducts` ADD `lenderProductId` varchar(255) NOT NULL', undefined);
		await queryRunner.query('ALTER TABLE `leads` ADD `loanStatus` varchar(255) NULL', undefined);
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `loanStatus`', undefined);
		await queryRunner.query('ALTER TABLE `loanProducts` DROP COLUMN `lenderProductId`', undefined);
	}

}
