import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { AuthService } from 'app/_services';

@Component({
	selector: 'pecms-google-login-button',
	templateUrl: './google-login-button.component.html',
	styleUrls: ['./google-login-button.component.scss']
})
export class GoogleLoginButtonComponent implements OnInit {


	@HostBinding('attr.type')
	@Input()
	public type = 'button';

	@HostBinding('class.disabled')
	@Input()
	public disabled = false;

	constructor(
		public _authSvc: AuthService
	) { }

	ngOnInit() {}

}
