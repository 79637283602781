import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, ManyToOne, JoinColumn, Index, ManyToMany, JoinTable } from 'typeorm';
import { FileType } from './file-type';
import { User } from './user';
import { Lead } from './lead';

@Entity('files')
export class FileMeta {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
		length: 55
	})
	@Index()
	public name: string | undefined;

	@Column('varchar', {
		name: 'savedName',
		nullable: false,
		length: 1000
	})
	@Index({unique: true})
	public savedName: string | undefined;

	@Column('varchar', {
		name: 'bucketName',
		nullable: false,
		length: 63
	})
	public bucketName: string | undefined;

	@ManyToOne(type => FileType, (fileType: FileType) => fileType.files, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({
		name: 'fileTypeName'
	})
	fileType: FileType | undefined;

	@Column('varchar', {
		name: 'mimeType',
		nullable: false,
		length: 255
	})
	public mimeType: string | undefined;

	@Column('varchar', {
		name: 'signature',
		nullable: false,
		length: 1000
	})
	public signedUrl: string | undefined;

	@Column('tinyint', {
		name: 'complete',
		nullable: false,
		default: 0,
	})
	public complete: boolean | undefined;

	@Column('int', {
		name: 'size',
		nullable: false,
		default: 0
	})
	@Index()
	public size: number;

	@Column('int', {
		name: 'groupId',
		nullable: true,
	})
	@Index()
	public groupId: any | undefined;

	@ManyToOne(type => User, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'createdById'})
	createdBy: User | undefined;

	@ManyToMany(type => Lead, lead => lead.files)
	@JoinTable({
		name: 'leadFiles',
		inverseJoinColumn: {
			name: 'leadId',
			referencedColumnName: 'id'
		},
		joinColumn: {
			name: 'fileId',
			referencedColumnName: 'id'
		}
	})
	leads: Lead[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<FileMeta> = {}) {
		this.id = args.id;
		this.name = args.name;
		this.savedName = args.savedName;
		this.fileType = args.fileType ? new FileType(args.fileType) : undefined;
		this.leads = args.leads ? args.leads.map( l => new Lead(l)) : undefined;
		this.mimeType = args.mimeType;
		this.signedUrl = args.signedUrl;
		this.groupId = args.groupId;
		this.size = args.size || 0;
		this.bucketName = args.bucketName;
		this.complete = !!args.complete;
		this.createdBy = args.createdBy ? new User(args.createdBy) : undefined;
		this.createdAt = args.createdAt ? new Date(args.createdAt) : undefined;
		this.updatedAt = args.updatedAt ? new Date(args.updatedAt) : undefined;
	}

	public static fromFile(file: File, type: FileType): FileMeta;
	public static fromFile(file: File, typeName: string): FileMeta;
	public static fromFile(file: File, type: FileType | string): FileMeta {
		const targetType = type instanceof FileType ? type : new FileType(type);
		return new FileMeta({
			name: file.name,
			fileType: targetType,
			mimeType: file.type,
			size: file.size,
		});
	}

	public buildUrl(env: any) {
		const urlComponents = [];
		urlComponents.push(env.API_URL || `//${env.domain}:${env.apiPort}${env.apiUrl}`);
		urlComponents.push('/files/', this.id, '/download');
		return urlComponents.join('');
	}

}
