import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToOne, JoinColumn, Column } from 'typeorm';
import { User } from './user';

@Entity('userSettings')
export class UserSettings {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@OneToOne(type => User, (user: User) => user.settings, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'userId'})
	user: User | undefined;

	@Column('tinyint', {
		name: 'currentBillDefault',
		nullable: false,
		// length: 255
		default: 0
	})
	public showCurrentBill: any | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<UserSettings> = {}) {
		this.id = args.id;
		this.user = args.user ? new User(args.user) : undefined;
		this.showCurrentBill = !!args.showCurrentBill;
		this.createdAt = args.createdAt;
		this.updatedAt = args.updatedAt;

	}
}
