
export interface AlertConfig {
	title: string;
	message?: string;
	time?: number;
	icon?: string;
	closeable?: boolean;
	mode?: 'indeterminate' | 'determinate' | 'buffer' | 'query';
	onClick?: (...args: any[]) => any;
}

export enum AlertType {
	Success,
	Info,
	Warn,
	Danger
}
