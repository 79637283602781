import { Color } from 'pecms-shared';

import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: 'svg[path]'
})
export class SvgDirective {

	@Input()
	set path(val: string) {
		this._path = val;
		this.addPath();
	}
	get path() { return this._path; }
	private _path: string;

	@Input()
	set fillColor(val: string) {
		this._fillColor = val;
		this.addPath();
	}
	get fillColor() { return this._fillColor; }
	private _fillColor: string;

	constructor(private _elem: ElementRef, private _renderer: Renderer2) {}

	private _pathRef: SVGPathElement;

	public addPath() {
		if (!this.path) return;
		if (!this._pathRef) this._pathRef = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		this._pathRef.setAttribute('d', this.path);
		this._pathRef.setAttribute('fill', this.fillColor);
		this._renderer.appendChild(this._elem.nativeElement, this._pathRef);
	}

}
