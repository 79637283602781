import {MigrationInterface, QueryRunner} from "typeorm";

export class statusUpdate1563093424059 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `roleNotifications` DROP FOREIGN KEY `FK_2e39b617c86b3e6d270ac9435ef`");
        await queryRunner.query("DROP INDEX `statusId` ON `roleNotifications`");
        await queryRunner.query("ALTER TABLE `leads` DROP FOREIGN KEY `FK_9320294884f92e1bde96374a479`");
        await queryRunner.query("DROP INDEX `leads_statusId_foreign_idx` ON `leads`");
        await queryRunner.query("ALTER TABLE `leadStatuses` CHANGE `id` `order` int NOT NULL");
        await queryRunner.query("ALTER TABLE `leadStatuses` DROP PRIMARY KEY");
        await queryRunner.query("ALTER TABLE `roleNotifications` ADD `statusName` varchar(255) NOT NULL");
        await queryRunner.query("ALTER TABLE `leads` ADD `statusName` varchar(255) NOT NULL");
        await queryRunner.query("UPDATE `roleNotifications` rn SET `statusName` = (SELECT `name` from `leadStatuses` ls WHERE ls.`order` = rn.`statusId` LIMIT 1)");
        await queryRunner.query("UPDATE `leads` l SET `statusName` = (SELECT `name` from `leadStatuses` ls WHERE ls.`order` = l.`statusId` LIMIT 1)");
        await queryRunner.query("ALTER TABLE `roleNotifications` DROP COLUMN `statusId`");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `statusId`");
        await queryRunner.query("ALTER TABLE `leadStatuses` ADD PRIMARY KEY (`name`)");
        await queryRunner.query("ALTER TABLE `leadStatuses` ADD UNIQUE INDEX `IDX_4cd7f3b61d4c8b54d252a988e2` (`order`)");
        await queryRunner.query("CREATE INDEX `statusName` ON `roleNotifications` (`statusName`)");
        await queryRunner.query("CREATE INDEX `leads_statusName_foreign_idx` ON `leads` (`statusName`)");
        await queryRunner.query("ALTER TABLE `roleNotifications` ADD CONSTRAINT `FK_b9a4bd638315c348625476fcd04` FOREIGN KEY (`statusName`) REFERENCES `leadStatuses`(`name`) ON DELETE RESTRICT ON UPDATE CASCADE");
        await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_2654a197ebd44a4706bf1532246` FOREIGN KEY (`statusName`) REFERENCES `leadStatuses`(`name`) ON DELETE RESTRICT ON UPDATE CASCADE");
    }
    
    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP FOREIGN KEY `FK_2654a197ebd44a4706bf1532246`");
        await queryRunner.query("DROP INDEX `leads_statusName_foreign_idx` ON `leads`");
        await queryRunner.query("ALTER TABLE `roleNotifications` DROP FOREIGN KEY `FK_b9a4bd638315c348625476fcd04`");
        await queryRunner.query("DROP INDEX `statusName` ON `roleNotifications`");
        await queryRunner.query("DROP INDEX `IDX_4cd7f3b61d4c8b54d252a988e2` ON `leadStatuses`");
        await queryRunner.query("ALTER TABLE `roleNotifications` ADD `statusId` int NOT NULL");
        await queryRunner.query("ALTER TABLE `leads` ADD `statusId` int NOT NULL");
        await queryRunner.query("UPDATE `roleNotifications` rn SET `statusId` = (SELECT `order` from `leadStatuses` ls WHERE ls.`name` = rn.`statusName` LIMIT 1)");
        await queryRunner.query("UPDATE `leads` rn SET `statusId` = (SELECT `order` from `leadStatuses` ls WHERE ls.`name` = rn.`statusName` LIMIT 1)");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `statusName`");
        await queryRunner.query("ALTER TABLE `roleNotifications` DROP COLUMN `statusName`");
        await queryRunner.query("ALTER TABLE `leadStatuses` DROP PRIMARY KEY");
        await queryRunner.query("ALTER TABLE `leadStatuses` ADD PRIMARY KEY (`order`)");
        await queryRunner.query("ALTER TABLE `leadStatuses` CHANGE `order` `id` int NOT NULL AUTO_INCREMENT");
        await queryRunner.query("ALTER TABLE `leadStatuses` AUTO_INCREMENT = 0");
        await queryRunner.query("CREATE INDEX `leads_statusId_foreign_idx` ON `leads` (`statusId`)");
        await queryRunner.query("CREATE INDEX `statusId` ON `roleNotifications` (`statusId`)");
        await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_9320294884f92e1bde96374a479` FOREIGN KEY (`statusId`) REFERENCES `leadStatuses`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
        await queryRunner.query("ALTER TABLE `roleNotifications` ADD CONSTRAINT `FK_2e39b617c86b3e6d270ac9435ef` FOREIGN KEY (`statusId`) REFERENCES `leadStatuses`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
    }

}
