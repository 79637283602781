import { Component, OnInit, Input, Output, EventEmitter, Renderer2, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { MatButton } from '@angular/material';

@Component({
	selector: 'pecms-button-list',
	templateUrl: './button-list.component.html',
	styleUrls: ['./button-list.component.scss']
})
export class ButtonListComponent implements OnInit, AfterViewChecked {

	private _viewInitialized = false;

	@ViewChild('wrapper', {static: false})
	protected wrapper: ElementRef;

	@Input()
	public list: any[] = [];

	@Input()
	public vertical = true;

	@Input()
	public buttonStyle: 'mat-button' | 'mat-raised-button' | 'mat-stroked-button' | 'mat-flat-button' = 'mat-raised-button';

	@Input()
	public color: 'primary' | 'accent' | 'warn' = 'primary';

	@Input()
	public disabled = false;

	private _value: any;

	@Input()
	public set value(val) {
		this.change.emit({value: val, oldValue: this._value});
		this._value = val;
	}
	public get value(): any {
		return this._value;
	}

	@Output()
	public change: EventEmitter<any> = new EventEmitter<any>();

	private _selected: any;

	constructor(
		private renderer: Renderer2
	) { }

	ngOnInit() {}

	ngAfterViewChecked() {
		this._viewInitialized = true;
		this._value !== undefined && this._handleChange(this._getButtonElementByVal(this._value));
	}

	@Input()
	public buttonName: (any) => string = (listItem) => listItem.name

	@Input()
	public buttonValue: (any) => string = (listItem) => listItem.value

	handleClick(button: MatButton) {
		const ne = button._elementRef.nativeElement;
		this.value = ne.value;
	}

	private _handleChange(element: any) {
		if (this._viewInitialized) {
			this._highlight(element);
			this._selected = element;
		}
	}

	private _highlight(element: any) {
		if (this._selected) {
			for (const sc of this._selected.children) {
				if (sc.classList.contains('mat-button-focus-overlay')) {
					this.renderer.removeStyle(sc, 'opacity');
					break;
				}
			}
		}
		for (const ce of element.children) {
			if (ce.classList.contains('mat-button-focus-overlay')) {
				this.renderer.setStyle(ce, 'opacity', '1');
				break;
			}
		}
	}

	private _getButtonElementByVal(val: any) {
		for (const e of this.wrapper.nativeElement.children) {
			if (e.nodeName === 'BUTTON' && e.value === val + '') {
				return e;
			}
		}
	}

}
