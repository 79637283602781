import {MigrationInterface, QueryRunner} from 'typeorm';

export class addFileXref1567459089593 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('CREATE TABLE `leadFiles` (`leadId` int NOT NULL, `fileId` int NOT NULL, INDEX `IDX_ce3e282d216d4dfd6b50503be9` (`leadId`), INDEX `IDX_ddcee35ed65e819a03aaee8570` (`fileId`), PRIMARY KEY (`leadId`, `fileId`)) ENGINE=InnoDB');
		await queryRunner.query('ALTER TABLE `leadFiles` ADD CONSTRAINT `FK_ce3e282d216d4dfd6b50503be96` FOREIGN KEY (`leadId`) REFERENCES `leads`(`id`) ON DELETE CASCADE ON UPDATE NO ACTION');
		await queryRunner.query('ALTER TABLE `leadFiles` ADD CONSTRAINT `FK_ddcee35ed65e819a03aaee85702` FOREIGN KEY (`fileId`) REFERENCES `files`(`id`) ON DELETE CASCADE ON UPDATE NO ACTION');
		await queryRunner.query('INSERT INTO `files` (`name`, `signature`, `groupId`, `size`, `mimeType`, `complete`, `fileTypeName`, `bucketName`, `createdById`, `savedName`) SELECT * FROM (SELECT `pbFile` as `name`, \'\' as `signature`, 0 as `groupId`, 0 as `size`, \'image/png\' as `mimeType`, 1 as `complete`, \'Power Bill\' as `fileTypeName`, \'pecms-userfiles\' as `bucketName`, `lu1`.`userId` as `createdById`, `pbFile` as `savedName` FROM leads l1 JOIN  `leadUsers` lu1 ON `lu1`.`leadId` = `l1`.`id` AND `lu1`.`positionName` = \'Sales Rep\' WHERE `pbFile` IS NOT NULL UNION SELECT `idFile` as `name`, \'\' as `signature`, 0 as `groupId`, 0 as `size`, \'image/png\' as `mimeType`, 1 as `complete`, \'ID\' as `fileTypeName`, \'pecms-userfiles\' as `bucketName`, `lu2`.`userId` as `createdById`, `idFile` as `savedName` FROM leads l2 JOIN  `leadUsers` lu2 ON `lu2`.`leadId` = `l2`.`id` AND `lu2`.`positionName` = \'Sales Rep\' WHERE `idFile` IS NOT NULL) LU');
		await queryRunner.query('INSERT INTO `leadFiles` (`leadId`, `fileId`) SELECT * FROM (SELECT `l1`.`id` as `leadId`, `f1`.`id` as `fileId` FROM `leads` l1 JOIN `files` f1 ON `l1`.`pbFile` = `f1`.`name` WHERE `l1`.`pbFile` IS NOT NULL UNION SELECT `l2`.`id` as `leadId`, `f2`.`id` as `fileId` FROM `leads` l2 JOIN `files` f2 ON `l2`.`idFile` = `f2`.`name` WHERE `l2`.`idFile` IS NOT NULL) LU');
		await queryRunner.query('UPDATE `leads` SET `pbFile` = NULL, `idFile` = NULL');
		await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `pbFile`');
		await queryRunner.query('ALTER TABLE `leads` DROP COLUMN `idFile`');
		await queryRunner.query('INSERT INTO `files` (`name`, `signature`, `groupId`, `size`, `mimeType`, `complete`, `fileTypeName`, `bucketName`, `createdById`, `savedName`) SELECT * FROM  (SELECT `image1` AS `name`, \'\' AS `signature`, 0 AS `groupId`, 0 AS `size`, \'image/png\' AS `mimeType`, 1 AS `complete`, \'Design Image\' AS `fileTypeName`, \'pecms-userfiles\' AS `bucketName`, `lu1`.`userId` AS `createdById`, `image1` AS `savedName` FROM `designs` d1 JOIN `leads` l1 ON `l1`.`designId` = `d1`.`id` JOIN `leadUsers` lu1 ON `lu1`.`leadId` = `l1`.`id` AND `lu1`.`positionName` = \'Sales Rep\' WHERE `image1` IS NOT NULL UNION SELECT `image2` AS `name`, \'\' AS `signature`, 0 AS `groupId`, 0 AS `size`, \'image/png\' AS `mimeType`, 1 AS `complete`, \'Design Image\' AS `fileTypeName`, \'pecms-userfiles\' AS `bucketName`, `lu2`.`userId` AS `createdById`, `image2` AS `savedName` FROM `designs` d2 JOIN `leads` l2 ON `l2`.`designId` = `d2`.`id` JOIN `leadUsers` lu2 ON `lu2`.`leadId` = `l2`.`id` AND `lu2`.`positionName` = \'Sales Rep\' WHERE `image2` IS NOT NULL) LU');
		await queryRunner.query('UPDATE `designs` JOIN `files` f ON f.savedName = image1 SET `image1` = `f`.`id`');
		await queryRunner.query('UPDATE `designs` JOIN `files` f ON f.savedName = image2 SET `image2` = `f`.`id`');
		await queryRunner.query('ALTER TABLE `designs` MODIFY COLUMN `image1` int NULL');
		await queryRunner.query('ALTER TABLE `designs` MODIFY COLUMN `image2` int NULL');
		await queryRunner.query('ALTER TABLE `designs` ADD CONSTRAINT fk_image1_files FOREIGN KEY (image1) REFERENCES files(id);');
		await queryRunner.query('ALTER TABLE `designs` ADD CONSTRAINT fk_image2_files FOREIGN KEY (image2) REFERENCES files(id);');
	}

	public async down(queryRunner: QueryRunner): Promise<any> {
		await queryRunner.query('ALTER TABLE `designs` DROP FOREIGN KEY fk_image1_files');
		await queryRunner.query('ALTER TABLE `designs` DROP FOREIGN KEY fk_image2_files');
		await queryRunner.query('ALTER TABLE `designs` MODIFY COLUMN `image1` varchar(255) NULL');
		await queryRunner.query('ALTER TABLE `designs` MODIFY COLUMN `image2` varchar(255) NULL');
		await queryRunner.query('UPDATE `designs` JOIN `files` f ON f.id = image1 SET `image1` = `f`.`savedName`');
		await queryRunner.query('UPDATE `designs` JOIN `files` f ON f.id = image2 SET `image2` = `f`.`savedName`');
		await queryRunner.query('DELETE FROM `files` WHERE `fileTypeName` = \'Design Image\'');
		await queryRunner.query('ALTER TABLE `leads` ADD COLUMN `idFile` varchar(255) NULL');
		await queryRunner.query('ALTER TABLE `leads` ADD COLUMN `pbFile` varchar(255) NULL');
		await queryRunner.query('UPDATE `leads` l LEFT OUTER JOIN ( SELECT `lf`.`leadId` AS `leadId`, `f`.`name` AS `name` FROM `leadFiles` lf JOIN `files` f ON `f`.`id` = `lf`.`fileId` AND `f`.`fileTypeName` = \'Power Bill\' ) pbj ON `pbj`.`leadId` = `l`.`id` LEFT OUTER JOIN ( SELECT `lf`.`leadId` AS `leadId`, `f`.`name` AS `name` FROM `leadFiles` lf JOIN `files` f ON `f`.`id` = `lf`.`fileId` AND `f`.`fileTypeName` = \'ID\' ) idj ON `idj`.`leadId` = `l`.`id` SET `pbFile` = `pbj`.`name`, `idFile` = `idj`.`name`');
		await queryRunner.query('DELETE from `leadFiles`');
		await queryRunner.query('DELETE from `files` WHERE `fileTypeName` = \'Power Bill\' OR `fileTypeName` = \'ID\'');
		await queryRunner.query('ALTER TABLE `leadFiles` DROP FOREIGN KEY `FK_ddcee35ed65e819a03aaee85702`');
		await queryRunner.query('ALTER TABLE `leadFiles` DROP FOREIGN KEY `FK_ce3e282d216d4dfd6b50503be96`');
		await queryRunner.query('DROP INDEX `IDX_ddcee35ed65e819a03aaee8570` ON `leadFiles`');
		await queryRunner.query('DROP INDEX `IDX_ce3e282d216d4dfd6b50503be9` ON `leadFiles`');
		await queryRunner.query('DROP TABLE `leadFiles`');
	}

}
