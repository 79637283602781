import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryStringBuilder } from 'app/_helpers/query-string-builder';
import { PaginatedResponse } from 'pecms-shared';

@Injectable({
	providedIn: 'root'
})
export abstract class AbstractServiceService<T, P = number> {

	protected get routePrefix() { return `/${this.entityClassName.toLowerCase()}s`; }

	protected abstract entityClass: new(...args: any[]) => T;

	protected abstract entityClassName: string;

	constructor(
		protected http: HttpClient
	) { }

	public getMany<C extends GetConfig<T>>(config?: C): Observable<C extends {size: number} ? PaginatedResponse<T> : T[]> {
		const queryString = new QueryStringBuilder(config);
		return <Observable<C extends {size: number} ? PaginatedResponse<T> : T[]>>this.http.get(this.routePrefix + queryString).pipe(map(es => {
			if (isPaginatedResult(es)) {
				return <PaginationResult<T>>Object.assign(es, {records: es.records.map(a => new this.entityClass(a))});
			}
			return (<T[]>es).map(a => new this.entityClass(a));
		}));
	}

	public get(primary: P);
	public get(config: GetConfig<T>);
	public get(primary: P, config: GetOneConfig<T>);
	public get(primaryOrGetConfig: P | GetConfig<T>, config?: GetOneConfig<T>): Observable<T> {
		if (!isGetConfig(primaryOrGetConfig)) {
			const queryString = new QueryStringBuilder(config);
			return this.http.get<T>(`${this.routePrefix}/${primaryOrGetConfig}${queryString}`).pipe(map(a => new this.entityClass(a)));
		} else {
			return this.getMany(primaryOrGetConfig).pipe(map(entities => entities[0]));
		}
	}

	public create(entity: T, expand?: ExpandType<T>) {
		return this.http.post<T>(`${this.routePrefix}${new QueryStringBuilder({expand})}`, entity).pipe(map(e => new this.entityClass(e)));
	}

	public createMany(entities: T[], expand?: ExpandType<T>) {
		return this.http.post<T[]>(`${this.routePrefix}${new QueryStringBuilder({expand})}`, entities).pipe(map(es => es.map(a => new this.entityClass(a))));
	}

	public update(patch: Partial<T>, id: P, expand?: ExpandType<T>): Observable<T> {
		return this.http.patch<T>(`${this.routePrefix}/${id}${new QueryStringBuilder({expand})}`, patch).pipe(map(e => new this.entityClass(e)));
	}

	public updateMany(patch: Partial<T>[], expand?: ExpandType<T>): Observable<T[]> {
		return this.http.patch<T[]>(`${this.routePrefix}${new QueryStringBuilder({expand})}`, patch).pipe(map(es => {
			return es.map(e => new this.entityClass(e));
		}));
	}

	public delete(primary: P) {
		return this.http.delete(`${this.routePrefix}/${primary}`);
	}

	public deleteMany(entities: T[], primaryKey = 'id') {
		const ids = entities.map(e => e[primaryKey]).join(',');
		return this.http.delete(`${this.routePrefix}`, {params: {[primaryKey]: ids}});
	}
}

export interface PaginationResult<E> {
	page: number;
	totalPages: number;
	records: E[];
	totalRecords: number;
}

export interface GetOneConfig<T> {
	expand?: ExpandType<T>;
}

export interface GetConfig<T> extends GetOneConfig<T> {
	where?: WhereType<T>;
	orderBy?: OrderByType<T>;
	page?: number;
	size?: number;
}

export type ExpandType<T> = {
	[K in keyof Unarray<T>]?: boolean | ExpandType<Unarray<Unarray<T>[K]>>
};

export type WhereType<T> = {
	[K in keyof Unarray<T>]?: WhereExpression | WhereType<Unarray<Unarray<T>[K]>>
};

export type OrderByType<T> = [keyof T, 'ASC' | 'DESC' | undefined][];

const whereOperators = ['=', '<', '<=', '>', '>=', '!=', 'like', 'between', 'in'];
export type WhereOperator = typeof whereOperators[number];

export interface WhereExpression extends Array<string | number | Date> {
	0: WhereOperator;
}

type Unarray<T> = T extends Array<infer U> ? U : T;

function isGetConfig<T>(obj: any, dynamicType?: new (...args: any[]) => T): obj is GetConfig<T> {
	if (typeof obj !== 'object' || Array.isArray(obj)) return false;
	if (obj.where && !isWhereType(obj.where, dynamicType)) return false;
	if (obj.expand && !isExpandType(obj.expand, dynamicType)) return false;
	if (obj.orderBy && !isOrderByType(obj.orderBy)) return false;
	if (obj.page && isNaN(+obj.page)) return false;
	if (obj.size && isNaN(+obj.size)) return false;
	return true;
}

function isGetOneConfig<T>(obj: any, dynamicType?: new (...args: any[]) => T): obj is GetOneConfig<T> {
	if (typeof obj !== 'object' || Array.isArray(obj)) return false;
	if (obj.expand) return isExpandType(obj.expand, dynamicType);
	return true;
}

function isExpandType<T = any>(obj: any, dynamicType?: new (...args: any[]) => T): obj is ExpandType<T> {
	if (typeof obj !== 'object' || Array.isArray(obj)) return false;
	return Object.entries(obj).every(([k, v]) => (!dynamicType || k in dynamicType.prototype) && (typeof v === 'boolean' || isExpandType(v)));
}

function isWhereType<T = any>(obj: any, dynamicType?: new (...args: any[]) => T): obj is WhereType<T> {
	if (typeof obj !== 'object' || Array.isArray(obj)) return false;
	return Object.entries(obj).every(([k, v]) => (!dynamicType || k in dynamicType.prototype) && (isWhereType(v) || isWhereExpression(v)));
}

function isWhereExpression(obj: any, ): obj is WhereExpression {
	if (!Array.isArray(obj) || obj.length < 2) return false;
	if (!whereOperators.includes(obj[0])) return false;
	return obj.slice(1).every(o => ['number' , 'string'].includes(typeof o) || o instanceof Date);
}

function isOrderByType<T>(obj: any, dynamicType?: new (...args: any[]) => T): obj is OrderByType<T> {
	if (!Array.isArray(obj) || !obj.length) return false;
	if (dynamicType && !(obj[0] in dynamicType.prototype)) return false;
	return !obj[1] || ['ASC', 'DESC'].includes(obj[1]);
}

export function isPaginatedResult<T = any>(obj: any): obj is PaginationResult<T>  {
	const keys = Object.keys(obj);
	if (!keys.includes('page') || typeof obj.page !== 'number') return false;
	if (!keys.includes('totalPages') || typeof obj.totalPages !== 'number') return false;
	if (!keys.includes('totalRecords') || typeof obj.totalRecords !== 'number') return false;
	return keys.includes('records') && Array.isArray(obj.records);
}
