import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, OneToMany } from 'typeorm';
import { Design } from './design';

/*
[ ] Add to db.index.ts
*/

@Entity('inverters')
export class Inverter {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'manufacturer',
		nullable: false,
		// length: 255
	})
	public manufacturer: string | undefined;

	@Column('varchar', {
		name: 'model',
		nullable: false,
		// length: 255
	})
	public model: string | undefined;

	@Column('decimal', {
		name: 'rating',
		nullable: false,
		precision: 8,
		scale: 3
	})
	public rating: number | undefined;

	@OneToMany(type => Design, (design: Design) => design.inverter, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	designs: Design[] | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<Inverter> = {}) {
		this.id = args.id;
		this.manufacturer = args.manufacturer;
		this.model = args.model;
		this.rating = args.rating;
		this.designs = args.designs ? args.designs.map(d => new Design(d)) : undefined;
	}
}
