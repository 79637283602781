import {Index, Entity, PrimaryColumn, PrimaryGeneratedColumn, Column, OneToOne, OneToMany, ManyToOne, ManyToMany, JoinColumn, JoinTable, RelationId, CreateDateColumn, UpdateDateColumn, AfterLoad} from 'typeorm';
import { User } from './user';
import {Team} from './team';
import {QuickSale} from './quick-sale';
import { Owner } from './utilities';
import { Scopes } from './rbac-scope.enum';

@Entity('employees')
export class Employee {

	get privilegeType() { return <'employee'>'employee'; }

	@PrimaryGeneratedColumn()
	id: number | undefined;

	@OneToOne(type => User, (user: User) => user.employee, { nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'loginId' })
	@Owner()
	user: User | undefined;

	// @Column()
	// get teamId() { return this.team && this.team.id ? this.team.id : 0; }
	// set teamId(val) {
	// 	if (!this.team) this.team = new Team();
	// 	this.team.id = val;
	// }

	@ManyToOne(type => Team, (team: Team) => team.employees, { nullable: false, onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({ name: 'teamId' })
	@Owner({scope: Scopes.TEAM})
	team: Team | undefined;

	@Column('datetime', {
		nullable: true,
		default: () => 'CURRENT_TIMESTAMP',
		name: 'dateStarted'
	})
	dateStarted: Date | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'ssFile'
	})
	ssFile: string | undefined;

	@Column('varchar', {
		nullable: true,
		length: 255,
		name: 'checkFile'
	})
	checkFile: string | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	@OneToMany(type => QuickSale, (quickSale: QuickSale) => quickSale.employee, { onDelete: 'SET NULL', onUpdate: 'CASCADE' })
	quickSales: QuickSale[] | undefined;


	constructor(args: {[T in keyof Employee]?: Employee[T]} = {}) {
		this.id = args.id || undefined;
		this.user = args.user ? new User(args.user) : undefined;
		this.team = args.team || undefined;
		this.dateStarted = args.dateStarted || undefined;
		this.ssFile = args.ssFile || undefined;
		this.checkFile = args.checkFile || undefined;
		this.createdAt = args.createdAt || undefined;
		this.updatedAt = args.updatedAt || undefined;
		this.quickSales = args.quickSales || undefined;
		// if (args.teamId) this.teamId = args.teamId;
		if (args.user) this.user = new User(args.user);
		this.afterLoad();
	}

	@AfterLoad()
	protected afterLoad() {
		if (this.user && !this.user.employee) this.user.employee = this;
	}

	toJSON() {
		const retObj = Object.assign({}, this, {user: this.user ? Object.assign({}, this.user) : undefined});
		if ((retObj.user && retObj.user.employee)) delete retObj.user!.employee;
		return retObj;
	}

}
