import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, from, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { User } from 'pecms-shared';
import { AlertService } from 'app/_services/alert.service';
import { AlertType } from 'app/_models';
import { DynamicScriptLoaderService } from 'app/_services/dynamic-script-loader.service';
import { UserService } from 'app/_services/user.service';
import { Router } from '@angular/router';
import { WebsocketService } from 'app/_services/websocket.service';

@Injectable()
export class AuthService {
	private _jwt: JwtHelperService = new JwtHelperService();
	private _userPhotoDataUrl = new BehaviorSubject(undefined);
	public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!this.jwtIsValid);
	public isImpersonating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!localStorage.getItem('impersonator'));
	public isGoogleAuthorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public currentUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(this._immediateUser || null);
	private _gAuth: gapi.auth2.GoogleAuth;
	private _googleLoggedIn = new Subject<string>();

	constructor(
		private _router: Router,
		private _ngZone: NgZone,
		private _alertSvc: AlertService,
		private _userSvc: UserService,
		private _websocketSvc: WebsocketService,
		dslSvc: DynamicScriptLoaderService
	) {
		this._websocketSvc.authStatus.subscribe(tokenValid => {
			if (!tokenValid) this.sessionExpired();
		});
		if (dslSvc.isLoaded('gapi')) {
			this._initGauth();
		} else {
			dslSvc.load('gapi').subscribe(statuses => {
				statuses.gapi.loaded && this._initGauth();
			});
		}
	}

	public login(type: 'google', token?: string): Observable<string>;
	public login(type: 'password', username: string, password: string): Observable<string>;
	public login(type: 'password' | 'google', identifier: string, password?: string): Observable<string> {
		switch (type) {
			case 'password':
				return this._userSvc.passwordAuth(identifier, <string>password).pipe(map(this._loginHandler.bind(this)));
			case 'google':
				if (!this._gAuth.isSignedIn.get()) {
					this._gAuth && this._gAuth.signIn({prompt: 'select_account'});
					return this._googleLoggedIn;
				}
				return <Observable<string>>this._userSvc.googleAuth(
					this._gAuth && this._gAuth.currentUser.get().getAuthResponse().id_token
				).pipe(map(this._googleLoginHandler.bind(this)), catchError<string, any>(this._googleErrorHandler.bind(this)));
			default:
				return of('');
				break;
		}
	}

	public claimGoogleAccount() {
		if (this._gAuth.isSignedIn.get()) {
			return this._userSvc.claimGoogleAccount(
				this._gAuth && this._gAuth.currentUser.get().getAuthResponse().id_token
			).pipe(map(this._googleLoginHandler.bind(this)), catchError<string, any>(this._googleErrorHandler.bind(this)));
		}
		return of(undefined);
	}

	public impersonate(userId: number) {
		this._userSvc.impersonateUser(userId).subscribe((newToken: string) => {
			localStorage.setItem('impersonator', localStorage.getItem('currentUser') || '');
			this.updateUser(newToken);
			this.isImpersonating.next(true);
			location.reload();
		});
	}

	revertImpersonation() {
		const token = localStorage.getItem('impersonator');
		localStorage.removeItem('impersonator');
		localStorage.setItem('currentUser', <string>token);
		this.updateUser(token);
		this.isImpersonating.next(false);
		location.reload();
	}

	sessionExpired() {
		this.logout('Session expired.  Please log in again', false);
	}

	private _loginHandler(token: string) {
		if (token) {
			localStorage.setItem('currentUser', token);
			this.isLoggedIn.next(true);
			this.currentUser.next(this._immediateUser);
			this._alertSvc.success({title: 'Logged in successfully.', time: 1});
		}
		return token;
	}

	private _googleLoginHandler(token: string | {username: string}): string | undefined {
		if (typeof token === 'string') {
			const tkn = this._ngZone.run(() => this._loginHandler(token));
			this._googleLoggedIn.next(tkn);
			return tkn;
		} else if (token.username) {
			this._ngZone.run(() => this._router.navigate(['login', 'claim'], {state: {service: 'google', username: token.username}}));
			return;
		}
	}

	private _googleErrorHandler(e: Error & {details: {reason: string}}, caught: Observable<string>) {
		if (e.details && e.details.reason) {
			switch (e.details.reason) {
				case 'NO_OAUTH_MATCH':
					this._ngZone.run(() => this._router.navigate(['login', 'no-access'], {state: {service: 'google'}}));
					break;
				case 'VERIFY_OAUTH_EMAIL':
					this._ngZone.run(() => this._router.navigate(['login', 'no-access'], {state: {
						service: 'google',
						help: 'You may need to verify your email with this account.'
					}}));
					break;
				default:
					this._gAuth && this._gAuth.signOut();
					break;
			}
		}
		return of(null);
	}

	logout(message: string = 'You have been logged out.', intentional: boolean = true) {
		localStorage.removeItem('currentUser');
		this.currentUser.next(null);
		this.isLoggedIn.next(false);
		this._gAuth && this._gAuth.isSignedIn.get() && this._gAuth.signOut();
		this._alertSvc.alert(intentional ? AlertType.Success : AlertType.Danger, {title: message, time: 1});
	}

	public get userSnapshot(): User {
		return <User>this._immediateUser;
	}

	public get userPhotoDataUrl() { return this._userPhotoDataUrl.asObservable(); }

	public get jwtIsValid() {
		try {
			return this._jwt.decodeToken(<string>localStorage.getItem('currentUser'));
		} catch (e) {
			return false;
		}
	}

	private get _immediateUser() {
		const userObj = this.jwtIsValid;
		if (!userObj) return null;
		return new User(userObj);
	}

	public get authenticatedUser() {
		return this.currentUser;
	}

	public updateUser(token) {
		localStorage.setItem('currentUser', token);
		this.currentUser.next(this._immediateUser);
	}

	private _initGauth() {
		this._gAuth = gapi.auth2.getAuthInstance();
		this._gAuth.isSignedIn.get() && !this.isLoggedIn.value && this.login('google').subscribe();
		this._gAuth.isSignedIn.listen(isAuth => {
			this.isGoogleAuthorized.next(isAuth);
			(isAuth && !this.isLoggedIn.value) && this.login('google').subscribe();
		});
	}

}
