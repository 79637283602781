import { ControlValueAccessor, NgControl, Validator, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Input, OnInit, Self, Optional, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { takeUntil, skip, distinctUntilChanged, tap } from 'rxjs/operators';

export abstract class AbstractValueAccessor<M = any, V = M> implements ControlValueAccessor, Validator, OnInit, OnDestroy {

	public disabled: boolean;
	public required: boolean;
	public readonly: boolean;
	@Output()
	public change = new EventEmitter<M>();

	protected unsubscribe = new Subject();
	protected control: {valueChanges: Observable<M>} & FormControl;

	constructor(@Optional() @Self() private _dir: NgControl) {
		if (_dir) _dir.valueAccessor = this;
	}

	public validate(control?: AbstractControl): ValidationErrors {
		if (this.required && control && !control.value) return {required: 'required'};
	}

	@Input('disabled')
	private set _disabled(val) {
		this.disabled = coerceBooleanProperty(val);
	}
	private get _disabled() {
		return this.disabled;
	}

	@Input('required')
	protected set _required(val) {
		this.required = coerceBooleanProperty(val);
	}
	protected get _required() {
		return this.required;
	}

	@Input('readonly')
	private set _readonly(val) {
		this.readonly = coerceBooleanProperty(val);
	}
	private get _readonly() {
		return this.readonly;
	}

	public updateModel(val: M) {
		this.onTouched();
		if (val !== this.control.value) {
			this.control.setValue(val, {emitEvent: false});
			this.onChange(val);
		}
	}
	abstract updateView(val: V | M): void;

	// Model to view change
	writeValue(value: V) { this.updateView(value); }

	ngOnInit() {
		this.control = this._dir && this._dir.control ? <FormControl>this._dir.control : new FormControl();
		const validators = this.control.validator ? [this.control.validator, this.validate.bind(this)] : this.validate.bind(this);
		this.control.setValidators(validators);
		this.control.updateValueAndValidity();
		this.control.valueChanges.pipe(
			distinctUntilChanged(),
			takeUntil(this.unsubscribe)
		).subscribe(this.change.emit.bind(this.change));
	}

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	onChange = (_) => {};
	onTouched = () => {};
	onValidatorChange = () => {};
	registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
	registerOnTouched(fn: () => void): void { this.onTouched = fn; }
	registerOnValidatorChange?(fn: () => void): void { this.onValidatorChange = fn; }
	setDisabledState ? (isDisabled: boolean): void { this._disabled = isDisabled; }

}
