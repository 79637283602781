import { Column } from 'typeorm';
import { Employee } from './employee';
import { Team } from './team';
import { User } from './user';
import { Design } from './design';
import { Lead } from './lead';
import { QuickSale } from './quick-sale';
import { Role } from './role';
import { Protects } from './utilities/protected.utilities';
import { LeadStatus } from './lead-status';

export class Privileges {

	constructor(args: Partial<Privileges> = {}) {
		this.employees = args.employees || 0;
		this.teams = args.teams || 0;
		this.users = args.users || 0;
		this.contractors = args.contractors || 0;
		this.designs = args.designs || 0;
		this.leads = args.leads || 0;
		this.sales = args.sales || 0;
		this.roles = args.roles || 0;
		this.statuses = args.statuses || 0;
	}

	public static full = new Privileges({
		employees: 255,
		teams: 255,
		users: 255,
		contractors: 255,
		designs: 255,
		leads: 255,
		sales: 255,
		roles: 255,
		statuses: 255
	});
	@Column('bit', <any>{
		width: 8,
		nullable: false,
		default: 0b0,
		name: 'employees'
	})
	@Protects((type: any) => Employee)
	public employees: number;

	@Column('bit', <any>{
		width: 8,
		nullable: true,
		default: 0b0,
		name: 'teams'
	})
	@Protects((type: any) => Team)
	public teams: number;

	@Column('bit', <any>{
		width: 8,
		nullable: false,
		default: 0b0,
		name: 'users'
	})
	@Protects((type: any) => User)
	public users: number;

	@Column('bit', <any>{
		width: 8,
		nullable: false,
		default: 0b0,
		name: 'contractors'
	})
	public contractors: number;

	@Column('bit', <any>{
		width: 8,
		nullable: false,
		default: 0b0,
		name: 'designs'
	})
	@Protects((type: any) => Design)
	public designs: number;

	@Column('bit', <any>{
		width: 8,
		nullable: false,
		default: 0b0,
		name: 'leads'
	})
	@Protects((type: any) => Lead)
	public leads: number;

	@Column('bit', <any>{
		width: 8,
		nullable: true,
		default: 0b0,
		name: 'sales'
	})
	@Protects((type: any) => QuickSale)
	public sales: number;

	@Column('bit', <any>{
		width: 8,
		nullable: false,
		default: 0b0,
		name: 'roles'
	})
	@Protects((type: any) => Role)
	public roles: number;

	@Column('bit', <any>{
		width: 8,
		nullable: false,
		default: 0b0,
		name: 'leadStatuses'
	})
	@Protects((type: any) => LeadStatus)
	public statuses: number;
}

// type PrivilegeTypes = {
//     public [key in keyof Privileges]: number;
// }
