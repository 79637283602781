import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, Column, ManyToOne, JoinColumn } from 'typeorm';
import { Lead } from './lead';

/*
[ ] Add to db.index.ts
*/

@Entity('additionalCharges')
export class AdditionalCharge {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@Column('varchar', {
		name: 'name',
		nullable: false,
		length: 55
	})
	public name: string | undefined;

	@Column('decimal', {
		name: 'amount',
		nullable: false,
		precision: 8,
		scale: 3
	})
	public amount: number | undefined;

	@ManyToOne(type => Lead, (lead: Lead) => lead.additionalCharges, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	lead: Lead | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<AdditionalCharge> = {}) {
		this.id = args.id;
		this.name = args.name;
		this.amount = args.amount;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.createdAt = new Date(<any>args.createdAt) || undefined;
		this.updatedAt = new Date(<any>args.updatedAt) || undefined;
	}
}
