import { NgModule } from '@angular/core';

import { CountUpDirective } from './count-up.directive';
import { IfEnvDirective } from './if-env.directive';
import { ImageModalDirective } from './image-modal.directive';
import { LongPressDirective } from './long-press.directive';
import { MatchFontDirective } from './match-font.directive';
import { PaletteDirective } from './palette.directive';
import { ResizeDirective } from './resize.directive';

@NgModule({
	declarations: [
		PaletteDirective,
		ImageModalDirective,
		MatchFontDirective,
		CountUpDirective,
		LongPressDirective,
		ResizeDirective,
		IfEnvDirective
	],
	exports: [
		PaletteDirective,
		ImageModalDirective,
		MatchFontDirective,
		CountUpDirective,
		LongPressDirective,
		ResizeDirective,
		IfEnvDirective
	]
})
export class CustomDirectives { }
