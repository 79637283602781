import {MigrationInterface, QueryRunner} from "typeorm";

export class utilityRateAndInverters1554225323709 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `inverters` (`id` int NOT NULL AUTO_INCREMENT, `manufacturer` varchar(255) NOT NULL, `model` varchar(255) NOT NULL, `rating` decimal(8,3) NOT NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `currentBill`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `inverterType`");
        await queryRunner.query("ALTER TABLE `designs` ADD `inverterId` int NULL");
        await queryRunner.query("ALTER TABLE `utilities` ADD `rate` decimal(8,6) NOT NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD CONSTRAINT `FK_988e4248ebb1c3adeb37773b9d1` FOREIGN KEY (`inverterId`) REFERENCES `inverters`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `designs` DROP FOREIGN KEY `FK_988e4248ebb1c3adeb37773b9d1`");
        await queryRunner.query("ALTER TABLE `utilities` DROP COLUMN `rate`");
        await queryRunner.query("ALTER TABLE `designs` DROP COLUMN `inverterId`");
        await queryRunner.query("ALTER TABLE `designs` ADD `inverterType` varchar(255) NULL");
        await queryRunner.query("ALTER TABLE `designs` ADD `currentBill` decimal(10,2) NULL");
        await queryRunner.query("DROP TABLE `inverters`");
    }

}
