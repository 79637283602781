import {MigrationInterface, QueryRunner} from "typeorm";

export class switchCommentScheme1561101097365 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("INSERT INTO `comments` (`content`, `leadId`, `createdById`) SELECT `notes`, `id`, `salesRepId` from `leads` WHERE `notes` IS NOT NULL");
        await queryRunner.query("INSERT INTO `comment_commentTags` (`commentId`, `commentTagName`) SELECT `id`, 'CustomerQuestions' from `comments`");
        await queryRunner.query("UPDATE `leads` l SET l.notes = NULL");
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `notes`");
    }
    
    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` ADD COLUMN `notes` varchar(1000) NULL AFTER `idFile`");
        await queryRunner.query("UPDATE `leads` l INNER JOIN `comments` c ON l.id = c.leadId INNER JOIN `comment_commentTags` cct ON c.id = cct.`commentId` SET l.notes = c.content WHERE cct.commentTagName = 'CustomerQuestions'");
        await queryRunner.query("DELETE FROM `comment_commentTags`");
        await queryRunner.query("DELETE FROM `comments`");
    }

}
