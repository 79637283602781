import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { AuthService } from 'app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(
		private router: Router,
		private authSvc: AuthService
	) { }

	canActivate = this._validateUser;
	canActivateChild = this._validateUser;
	canLoad = this._validateUser;

	private _validateUser(route, state?) {
		if (this.authSvc.jwtIsValid) {
			return true;
		}
		if (state) {
			this.router.navigate(['/login'], state.url && state.url !== '/' ? {queryParams: {returnUrl: state.url}} : {});
		} else {
			this.router.navigate(['/login']);
		}
		return false;
	}
}
