export const environment = {
	name: 'live-staging',
	version: '1.0.8-beta',
	production: false,
	apiUrl: '/api',
	apiPort: 443,
	domain: 'stg.pure.solar',
	s3Url: 'https://s3-us-west-2.amazonaws.com/',
	s3UserBucket: 'pecms-userfiles',
	s3PublicBucket: 'pecms-public',
	s3RestrictedBucket: 'pecms-restricted',
	gapiConfig: {
		'apiKey': 'AIzaSyBRjNyctfqERc9Bx9zUzrtAZ3_H0lbVoSw',
		'clientId': '16525586206-4jvka9oou2k7kdurfv2mv2pjqds5248m.apps.googleusercontent.com',
		'scope': 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/admin.directory.resource.calendar https://mail.google.com/ https://www.googleapis.com/auth/admin.directory.user.readonly',
		'discoveryDocs': [
			'https://www.googleapis.com/discovery/v1/apis/oauth2/v2/rest',
			'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
			'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest',
			'https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest'
		]
	}
};
