import { Injectable, OnDestroy } from '@angular/core';
import { WebsocketService } from 'app/_services/websocket.service';
import { environment } from 'environments/environment';
import { AlertService } from 'app/_services/alert.service';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { AlertComponent } from 'app/shared';

@Injectable({
	providedIn: 'root'
})
export class VersionCheckService implements OnDestroy {

	public static readonly headerName = 'X-API-Version';

	constructor(
		private _webSocketSvc: WebsocketService,
		private _alertSvc: AlertService,
		private _http: HttpClient
	) {}

	private _subscription: Subscription;
	private _mismatchWarning: MatDialogRef<AlertComponent>;

	public init() {
		this._subscription = this._webSocketSvc.apiVersion().subscribe(this.checkVersion.bind(this));
	}

	private _handleMismatch() {
		if (this._mismatchWarning) return;
		this._mismatchWarning = this._alertSvc.success({
			title: 'Static has been updated',
			closeable: false,
			onClick: () => {
				this._http.get('/', {responseType: 'arraybuffer', headers: {
					'Pragma': 'no-cache',
					'Expires': '-1',
					'Cache-Control': 'no-cache'
				}}).subscribe(() => {
					location.reload();
				});
			},
			icon: 'new_releases',
			message: 'Click here to refresh when ready',
			time: -1
		});
		this._mismatchWarning.afterClosed().subscribe(() => this._mismatchWarning = undefined);
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

	public checkVersion(version: string | HttpResponse<any>) {
		if (version instanceof HttpResponse) version = version.headers.get(VersionCheckService.headerName);
		if (version && version !== environment.version) this._handleMismatch();
		if (version && version === environment.version && this._mismatchWarning) this._mismatchWarning.close();
	}
}
