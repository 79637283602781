import { Entity, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, ManyToOne, JoinColumn, Column, BeforeUpdate, BeforeInsert, RelationId } from 'typeorm';
import { Document } from './document';
import { Lead } from './lead';

@Entity('documentEntries')
export class DocumentEntry {
	@PrimaryGeneratedColumn()
	public id: number | undefined;

	@ManyToOne(type => Document, (document: Document) => document.entries, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'documentId'})
	document: Document | undefined;

	@ManyToOne(type => Lead, { onDelete: 'RESTRICT', onUpdate: 'CASCADE' })
	@JoinColumn({name: 'leadId'})
	lead: Lead | undefined;

	@Column('varchar', {
		name: 'values',
		nullable: false,
		length: 2000
	})
	private set _values(val: string | undefined ) { this.values = val ? JSON.parse(val) : undefined; }
	private get _values(): string | undefined { return this.values ? JSON.stringify(this.values) : undefined; }

	public values: {[key: string]: any} | undefined;

	@CreateDateColumn()
	createdAt: Date | undefined;

	@UpdateDateColumn()
	updatedAt: Date | undefined;

	constructor(args: Partial<DocumentEntry> = {}) {
		this.id = args.id;
		this.lead = args.lead ? new Lead(args.lead) : undefined;
		this.document = args.document ? new Document(args.document) : undefined;
		this.values = args.values;
	}
}
