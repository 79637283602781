import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { AlertComponent } from 'app/shared';
import { AlertType, AlertConfig } from 'app/_models';
import { WebsocketService } from 'app/_services/websocket.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AlertService implements OnDestroy {
	private _openAlerts: MatDialogRef<AlertComponent>[] = [];
	private _queuedAlerts: {type: AlertType, config: AlertConfig}[] = [];
	private _opening = false;
	private _subscription: Subscription;

	constructor(
		private dialog: MatDialog,
		private _websocketSvc: WebsocketService,
		private _http: HttpClient
	) {}

	public success: (config: AlertConfig) => MatDialogRef<AlertComponent> = this.alert.bind(this, AlertType.Success);
	public error: (config: AlertConfig) => MatDialogRef<AlertComponent> = this.alert.bind(this, AlertType.Danger);
	public info: (config: AlertConfig) => MatDialogRef<AlertComponent> = this.alert.bind(this, AlertType.Info);
	public warn: (config: AlertConfig) => MatDialogRef<AlertComponent> = this.alert.bind(this, AlertType.Warn);

	init() {
		this._subscription = this._websocketSvc.alerts().subscribe(s => {
			this.alert(s.type, s.config);
		});
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	public broadcast(type: AlertType, config: AlertConfig) {
		return this._http.post(`/alerts`, {type, config});
	}

	public send(userId: number, type: AlertType, config: AlertConfig) {
		return this._http.post(`/alerts/user/${userId}`, {type, config});
	}

	private getTop() {
		return (this._openAlerts.length ? this._openAlerts[this._openAlerts.length - 1].componentInstance.height : 0) + 10;
	}

	private checkQueue() {
		if (this._queuedAlerts.length) {
			const alert = this._queuedAlerts.shift();
			this.alert(alert.type, alert.config);
		}
	}

	alert(type: AlertType, config: AlertConfig) {
		if (!config.title) return;
		if (!type) type = AlertType.Success;
		if (this._opening) {
			this._queuedAlerts.push({type, config});
			return;
		}
		this._opening = true;

		const alert = this.dialog.open(AlertComponent, {
			maxWidth: '50vw',
			hasBackdrop: false,
			data: {type, config},
			panelClass: 'pecms-alert',
			autoFocus: false,
			position: {
				top: this.getTop() + 'px',
				right: '10px'
			}
		});
		alert.afterClosed().subscribe(this.handleRemoval(alert));
		alert.afterOpened().subscribe(this.handleOpen);
		this._openAlerts.push(alert);
		return alert;
	}

	handleRemoval(alert: MatDialogRef<AlertComponent>) {
		return () => {
			const removedIndex = this._openAlerts.findIndex( a => a.id === alert.id);
			let top = (removedIndex ? this._openAlerts[removedIndex - 1].componentInstance.height : 0) + 10;
			for (let i = removedIndex + 1; i < this._openAlerts.length; i++) {
				this._openAlerts[i].updatePosition({top: `${top}px`, right: '10px'});
				top = this._openAlerts[i].componentInstance.height + 10;
			}
			this._openAlerts.splice(removedIndex, 1);
		};
	}

	handleOpen = () => {
		this._opening = false;
		this.checkQueue();
	}

	clear() {
		this.dialog.closeAll();
	}
}
