import {MigrationInterface, QueryRunner} from "typeorm";

export class addSalesRepLead1549415482121 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` ADD `salesRepId` int NULL");
        await queryRunner.query("ALTER TABLE `leads` ADD CONSTRAINT `FK_43df2f9c5b545f12bf66626046a` FOREIGN KEY (`salesRepId`) REFERENCES `users`(`id`) ON DELETE RESTRICT ON UPDATE CASCADE");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `leads` DROP COLUMN `salesRepId`");
    }

}
