export interface ApiResponse {
	status: 'success' | 'error' | 'fail';
	data: ApiResponseData;
}

export interface SuccessResponse extends ApiResponse {
	status: 'success';
}

export interface ErrorResponse extends ApiResponse {
	status: 'error';
}

export interface FailResponse extends ApiResponse {
	status: 'fail';
}

export interface ApiResponseData {
	message: string;
	details?: any;
}

export interface JoiFailResponse extends FailResponse {
	data: JoiFailData;
}

export interface JoiFailData extends ApiResponseData {
	details?: JoiFailDetails[];
}

export interface JoiFailDetails {
	message: string;
	path: string[];
	type: string;
	context: JoiFailContext;
}

export interface JoiFailContext {
	pattern?: any;
	value: string;
	key: string;
	label: string;
}

export function isSuccessResponse(res: ApiResponse): res is SuccessResponse {
	return res && res.status === 'success';
}

export function isErrorResponse(res: ApiResponse): res is ErrorResponse {
	return res && res.status === 'error';
}

export function isFailResponse(res: ApiResponse): res is FailResponse {
	return res && res.status === 'fail';
}

export function isJoiFailResponse(res: ApiResponse): res is JoiFailResponse {
	return res && res.status === 'fail' && isJoiFailData(res.data);
}

export function isJoiFailData(data: any): data is JoiFailData {
	return (
		data.details &&
		Array.isArray(data.details) &&
		data.details.every( d => isJoiFailDetail(d))
	);
}

export function isJoiFailDetail(details: any): details is JoiFailDetails {
	return (
		details &&
		typeof details.message === 'string' &&
		Array.isArray(details.path) &&
		(!details.path.length || details.path.every(p => typeof p === 'string')) &&
		typeof details.type === 'string' &&
		isJoiFailContext(details.context)
	);
}
export function isJoiFailContext(context: any): context is JoiFailContext {
	return (
		typeof context.value === 'string' &&
		typeof context.key === 'string' &&
		typeof context.label === 'string'
	);
}
