import {MigrationInterface, QueryRunner} from "typeorm";

export class AddedEmail1554435609999 implements MigrationInterface {

    public async up(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("CREATE TABLE `emails` (`id` int NOT NULL AUTO_INCREMENT, `subject` varchar(255) NOT NULL, `template` varchar(255) NOT NULL, `replacements` json NULL, `fromAddress` varchar(255) NULL, `toAddress` varchar(255) NULL, `status` varchar(255) NOT NULL DEFAULT 'new', `sendAt` datetime NULL, `lastAttempt` datetime NULL, `sentAt` datetime NULL, `openedAt` datetime NULL, `openedIP` varchar(255) NULL, `failCount` int NULL DEFAULT 0, `failReason` longtext NULL, `createdAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `updatedAt` datetime(6) NOT NULL DEFAULT CURRENT_TIMESTAMP(6), `fromUserId` int NULL, `toUserId` int NULL, PRIMARY KEY (`id`)) ENGINE=InnoDB");
        await queryRunner.query("ALTER TABLE `utilities` CHANGE `rebate` `rebate` decimal(9,3) NOT NULL DEFAULT 0");
        await queryRunner.query("ALTER TABLE `emails` ADD CONSTRAINT `FK_abedfb86ce0a8e389e4bb108058` FOREIGN KEY (`fromUserId`) REFERENCES `users`(`id`) ON DELETE RESTRICT");
        await queryRunner.query("ALTER TABLE `emails` ADD CONSTRAINT `FK_f917c168010f4f53d0014038170` FOREIGN KEY (`toUserId`) REFERENCES `users`(`id`) ON DELETE RESTRICT");
    }

    public async down(queryRunner: QueryRunner): Promise<any> {
        await queryRunner.query("ALTER TABLE `emails` DROP FOREIGN KEY `FK_f917c168010f4f53d0014038170`");
        await queryRunner.query("ALTER TABLE `emails` DROP FOREIGN KEY `FK_abedfb86ce0a8e389e4bb108058`");
        await queryRunner.query("ALTER TABLE `utilities` CHANGE `rebate` `rebate` decimal(9,3) NOT NULL DEFAULT '3000.000'");
        await queryRunner.query("DROP TABLE `emails`");
    }

}
